import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {Link} from 'react-router-dom';
import {
    Col,
    Input,
    Button,
    ModalBody, FormGroup, ModalHeader, Modal, Form, Label, ModalFooter
} from 'reactstrap';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import 'moment-timezone';
import SingleDatePicker from 'react-bootstrap-daterangepicker';

import TemplateCreate from "./TemplateCreate";
import LoadDisplay from "./LoadDisplay";
moment.tz.setDefault("America/Halifax");

class LoadsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            load_id: false,
            loading: true,
            loading_segments: true,
            equipment_id: null,
            segments: [{
                segment_num: 0,
                origin: null,
                destination: null,
                start: null,
                distance_miles: "",
                wait_hrs: 0,
                duration_hrs: "",
                distance_miles_computed: null,
                duration_hrs_computed: null,
                show_dimensions: false,
                tolls: 0,
                description: "",
                billable: 1,
            }],
            clone_modal: false,
            clone: {
                description: "",
                equipment_id: null,
                start: null
            },
        };
        this.clone_modal_toggle = this.clone_modal_toggle.bind(this);
    }

    componentDidMount() {
        if (typeof this.props.match.params.load_id != "undefined") {
            document.title = "Load # " + this.props.match.params.load_id + " | Jeremie Cormier Trucking Ltd";
        } else {
            document.title = "Create Load | Jeremie Cormier Trucking Ltd";
        }
        var self = this;
        if (typeof self.props.match.params.load_id != "undefined") {
            self.setState({
                load_id: self.props.match.params.load_id,
            });
        }
    }

    new_load_callback = (load_id) => {
        this.setState({
            load_id: load_id
        });
    }

    getSegments = () => {
        if (this.state.load_id != false && this.state.load_id > 0) {
            var self = this;
            var current_segments = this.state.segments;
            axios.defaults.withCredentials = true;
            axios.get(API_ROOT + '/loads/' + self.state.load_id + "/segments")
                .then(function (response) {
                    var linestring = {
                        "type": "Feature",
                        "properties": {},
                        "geometry": {
                            "type": "MultiLineString",
                            "coordinates": []
                        }
                    };
                    for (const segment of response.data) {
                        if (segment.route != null) {
                            var route = JSON.parse(segment.route)
                            linestring.geometry.coordinates.push(route.coordinates);
                        }
                        if (typeof current_segments[segment.segment_num] != "undefined") {
                            response.data[segment.segment_num].show_dimensions = current_segments[segment.segment_num].show_dimensions;
                        }
                    }
                    var clone = self.state.clone;
                    clone.start = (typeof response.data[0].start == "undefined" ? null : response.data[0].start);
                    self.setState({
                            segments: response.data,
                            loading_segments: false,
                            linestring: linestring,
                            clone: clone
                        }
                    );
                })
                .catch(function (error) {
                    if (error.response.status === 401) {
                        self.props.userSignOut()
                    } else {
                        Swal("Error", error.response.data.Message, "error");
                    }
                });
        }
    }

    // Start Select specific
    formatGroupLabel = (data) => (
        <div className="justify-content border-bottom">
            <span className="text-muted">{data.name}</span>
            <div className="small float-right"><span className="badge badge-secondary" style={{padding: "2px"}}>{data.options.length}</span></div>
        </div>
    );

    clone_modal_toggle = () => {
        var self = this;
        if (!this.state.clone_modal) {
            axios.get(API_ROOT + '/loads/' + self.props.match.params.load_id)
                .then(function (response) {
                    self.setState({
                        loading: false,
                        load_id: self.props.match.params.load_id,
                        description: response.data.description,
                        equipment_id: response.data.equipment_id,
                        clone: {
                            description: response.data.description,
                            equipment_id: response.data.equipment_id,
                        },
                    }, function () {
                        self.getSegments();
                    });
                })
                .catch(function (error) {
                    if (error.response.status === 401) {
                        self.props.userSignOut()
                    } else {
                        Swal("Error1", error.response.data.Message, "error");
                    }
                });
        }
        this.setState({
            clone_modal: !this.state.clone_modal
        });
    };

    cloneStartSaved = (event, picker) => {
        if (event.type == "apply") {
            var clone = this.state.clone;
            clone.start = picker.startDate
            this.setState({
                clone: clone,
            });
        }
    }

    updateCloneField = (name, value) => {
        if (value === true) {
            value = 1
        } else if (value === false) {
            value = 0
        } else if (typeof value != "string") {
            value = value.target.value;
        }
        var clone = this.state.clone;
        clone[name] = value
        this.setState({
            clone: clone
        });
    };

    createClone = () => {
        if (this.state.load_id > 0 || this.state.segments[0].start != null) {
            var self = this;
            axios.defaults.withCredentials = true;
            axios.put(API_ROOT + '/loads/' + this.state.load_id + '/clone', this.state.clone)
                .then(function (response) {
                    self.props.history.push("/loads/" + response.data.load_id);
                    toast.success("Load Cloned", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                    self.setState({
                        clone_modal: false,
                        load_id: response.data.load_id
                    });
                })
                .catch(function (error) {
                    if (typeof error.response != "undefined") {
                        if (error.response.status === 401) {
                            self.props.userSignOut()
                        } else {
                            Swal("Error7", error.response.data.Message, "error");
                        }
                    }
                });
        }
    }

    render() {
        return (
            <ContentWrapper>
                <div className="content-heading pr-2 mb-1">
                    <div className="row w-100 pr-0">
                        <div className="col-6">
                            <Link to="../loads">Loads</Link>&nbsp;/&nbsp; {(this.state.load_id == false ? "Create Load" : "Load # " + this.state.load_id)}
                        </div>
                        <div className={(this.state.load_id == false && this.state.loading == false ? "d-none" : "col-6 text-right pr-0")}>
                            <div className="btn btn-xs btn-light" title="Duplicate this load" onClick={() => {
                                this.clone_modal_toggle()
                            }}>
                                <i className="far fa-copy"></i> Clone
                            </div>
                            <span className={(this.state.load_id > 0 || this.state.segments[0].start != null ? "" : "d-none")}>
                                <TemplateCreate {...this.state} load_id={this.state.load_id}/>
                            </span>
                        </div>
                    </div>
                </div>
                <LoadDisplay {...this.state} props={this.props} load_id={this.state.load_id} new_load_callback={this.new_load_callback}/>

                <Modal isOpen={this.state.clone_modal} fade={false} toggle={() => {
                    this.clone_modal_toggle(false, false)
                }} size="lg">
                    <ModalHeader>
                        <div className="h3 mb-0">Clone Load</div>
                    </ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup row>
                                <Label sm={4}>Description:</Label>
                                <Col sm={8}>
                                    <Input type="textarea" size="sm" name="description" value={this.state.clone.description}
                                           onChange={(event) => this.updateCloneField("description", event)}/>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={4}>1st Trip Start:</Label>
                                <Col sm={8}>
                                    <SingleDatePicker
                                        timePicker={true}
                                        onEvent={this.cloneStartSaved}
                                        autoUpdateInput={true}
                                        singleDatePicker={true}
                                        timePickerIncrement={5}
                                        timePicker24Hour={true}
                                        selected={(this.state.clone.start == null ? moment() : moment(this.state.clone.start))}
                                        date={(this.state.clone.startt == null ? moment() : moment(this.state.clone.start))}
                                        startDate={(this.state.clone.start == null ? moment() : moment(this.state.clone.start))}
                                        endDate={(this.state.clone.start == null ? moment() : moment(this.state.clone.start))}
                                        drops='down'
                                    >
                                        <div className="btn btn-light btn-sm">
                                            {(this.state.clone.start == null ? '(select)' : moment(this.state.clone.start).format('MMM. D/YY HH:mm'))}
                                        </div>
                                    </SingleDatePicker> </Col>
                            </FormGroup>
                        </Form>
                        <div className="alert alert-info p-2">
                            Full details can be edited on the next screen
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.createClone}><i className={(this.state.processing ? "fa fa-spinner fa-spin mr-2" : "d-none")}></i>Create New
                            Load</Button>
                    </ModalFooter>
                </Modal>
                <ToastContainer style={{zIndex: '9999 !important'}} stacked={true} containerStyle={{zIndex: '9999 !important'}}
                                toastOptions={{style: {zIndex: '9999 !important'}}}/>
            </ContentWrapper>
        );
    }
}

export default LoadsPage;
