import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {
    Button,
    Modal,
    Input,
    Col,
    Label,
    FormGroup,
    Form,
    ModalBody,
    ModalFooter, ModalHeader, ButtonGroup, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, InputGroup, InputGroupAddon
} from 'reactstrap';
import {getProductsParent} from '../Products/ProductFunctions.js';

import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import 'bootstrap/dist/css/bootstrap.css';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {getEquipment} from "../Equipment/EquipmentFunctions";

class ProductsOverview extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            products: {},
            products_sort: [],
            loading: true,
            // default product selection
            product: {
                name: "",
                description: "",
            },
            equipment: {},
            equipment_sort:[],
            equipment_id:null,
            // default lists
            search_term:"",
            product_parent_id: null,
            modal: false,
            mode: "",
            total:0
        };
        this.handleChange = this.handleChange.bind(this);
        this.updateValue = this.updateValue.bind(this);
        this.getProductParentsChild = this.getProductParentsChild.bind(this);
    }

    componentDidMount() {
        document.title = "Inventory List | Jeremie Cormier Trucking Ltd";
        var self = this;
        getEquipment(0, function (equipment) {
            var equipment_sort = [];
            for (const equipment_id of Object.keys(equipment)) {
                var obj = equipment[equipment_id];
                equipment_sort.push(obj)
            }
            self.setState({
                equipment: equipment,
                equipment_sort: equipment_sort
            });
        });
        this.getProductParents();
    }

    getProductParentsChild() {
        this.getProductParents();
    }

    getProductParents() {
        var self = this;
        getProductsParent(function (products) {
            var products_sort = []
            Object.keys(products).map(function (product_parent_id, key) {
                products[product_parent_id].visible = true;
                products_sort.push({id: product_parent_id, name: products[product_parent_id].name})
            });
            products_sort = products_sort.sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                }
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1;
                }
                return 0;
            });
            self.setState({
                products_sort: products_sort,
                products: products,
                total:0,
                loading: false
            });
        });
    }

    toggleProductParent(key) {
        var products = this.state.products;
        products[key].visible = !products[key].visible;
        this.setState({
            products: products
        })
    }

    handleChange(event) {
        var product = this.state.product;
        product[event.target.id] = event.target.value
        this.setState({
            product: product
        });
    }

    validate() {
        var error = "";
        if (this.state.product.name == "") {
            error = "Please enter a name";
        } else if (this.state.product.description == "") {
            error = "Please enter a description";
        }
        if (error == "") {
            return true;
        } else {
            Swal("Error", error, "error");
            return false;
        }
    }

    saveProduct = () => {
        if (this.validate()) {
            var self = this;
            axios.defaults.withCredentials = true;
            if (this.state.mode == "Add") {
                axios.post(API_ROOT + '/products/', self.state.product)
                    .then(function (response) {
                        self.getProductParents();
                        self.setState({
                            modal: !self.state.modal
                        });
                        Swal.fire({
                            title: 'Category Added',
                            text: "Would you like to add inventory items now?",
                            showCancelButton: true,
                            confirmButtonText: 'Yes, add items Now',
                            cancelButtonText: 'Later',
                        }).then((result) => {
                            if (result.value) {
                                self.props.history.push("/product/" + response.data.id);
                            }
                        })
                    })
                    .catch(function (error) {
                        Swal("Error", error.response.data.Message, "error");
                    });
            } else {
                axios.put(API_ROOT + '/products/' + this.state.product_parent_id, self.state.product)
                    .then(function (response) {
                        self.getProductParents();
                        self.setState({
                            modal: !self.state.modal
                        });
                        toast.success("Changes Saved", {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                    })
                    .catch(function (error) {
                        Swal("Error", error.response.data.Message, "error");
                    });
            }
        }
    };

    deleteProduct = () => {
        var self = this;
        axios.defaults.withCredentials = true;
        Swal.fire({
            title: 'Are you sure?',
            text: "The product and all SKUs will be deleted. This will not affect sales history of products already sold.",
            showCancelButton: true,
            confirmButtonText: 'Yes, delete now',
            cancelButtonText: 'No',
        }).then((result) => {
            axios.delete(API_ROOT + '/products/' + self.state.product_parent_id)
                .then(function (response) {
                    self.getProductParents();
                    self.setState({
                        modal: !self.state.modal
                    });
                    toast.success("Changes Saved", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                })
                .catch(function (error) {
                    Swal("Error", error.response.data.Message, "error");
                });
        });
    };

    openEdit = (key, e) => {
        e.stopPropagation();
        this.toggleModal();
        this.setState({
            product: {
                name: this.state.products[key].name,
                description: this.state.products[key].description,
            },
            product_parent_id: this.state.products[key].product_parent_id,
            mode: "Edit"
        });
    };

    addSKU = (key, e) => {
        e.stopPropagation();
        this.props.history.push("/product/" + key);
    };

    openAdd = () => {
        this.toggleModal();
        this.setState({
            product: {
                name: null,
                description: null,
                type_id: null,
            },
            product_parent_id: null,
            mode: "Add"
        });
    };

    toggleModal = () => {
        this.setState({
            modal: !this.state.modal
        });
    };

    updateValue(event) {
        if (event.target) {
            event.preventDefault();
            event.stopPropagation();
        }
        var value = event.target.value;
        this.setState({
            search_term: value
        });
    }

    render() {
        var total = 0;
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>
                        Inventory List
                    </div>
                    <div className="ml-auto">
                        <Button color="primary" onClick={this.openAdd}><i className="fa fa-plus"></i> Add Category</Button>
                        <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
                            <div className="modal-header h4">{this.state.mode} Category</div>
                            <ModalBody className="pt-0">
                                <Form>
                                    <FormGroup row>
                                        <Label for="name" sm={2}>Name</Label>
                                        <Col sm={10}>
                                            <Input type="text" name="name" id="name" value={this.state.product.name}
                                                   onChange={this.handleChange}/>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="description" sm={2}>Description</Label>
                                        <Col sm={10}>
                                            <Input type="textarea" name="description" id="description"
                                                   value={this.state.product.description} onChange={this.handleChange}/>
                                        </Col>
                                    </FormGroup>
                                </Form>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="danger" onClick={this.deleteProduct} className="pull-left">Delete</Button>
                                <Button color="primary" onClick={this.saveProduct}>{this.state.mode}</Button>
                                <Button color="secondary" onClick={this.toggleModal}>Cancel</Button>
                            </ModalFooter>
                        </Modal>
                    </div>
                </div>
                <div>
                    <input type="text" className="form-control mb-1" id="location_search" name="search_term" value={this.state.search_term}
                           placeholder="Search Inventory Item" onChange={this.updateValue}/>
                </div>
                <div className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                    <div className={(this.state.loading ? "invisible" : "")}>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className={(!this.state.loading ? "table table-sm table-hover table-pointer text-left" : "d-none")}>
                                    <tbody>
                                    {this.state.products_sort.map(function (product, key) {
                                        var product_parent_id = product.id;
                                        var self = this;
                                        Object.keys(self.state.products[product_parent_id].products).map(function (product_id) {
                                            var product = self.state.products[product_parent_id].products[product_id];
                                            total = total + (product.quantity * product.price);
                                        });
                                        return (
                                            <React.Fragment key={key}>
                                                <tr onClick={self.toggleProductParent.bind(self, product_parent_id)}>
                                                    <td className="d-lg-table-cell bg-dark text-light"><b>{self.state.products[product_parent_id].name}</b></td>
                                                    <td className="d-md-table-cell bg-dark text-right" colspan="5">
                                                        <div className="btn btn-xs btn-primary" title="Edit/Delete Item" onClick={self.openEdit.bind(self, product_parent_id)}><i
                                                            className="far fa-edit"></i> Edit Name
                                                        </div>
                                                        <div className="btn btn-xs btn-primary ml-1" title="Add SKU" onClick={self.addSKU.bind(self, product_parent_id)}><i
                                                            className="fas fa-plus"></i> New Item
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr className={(self.state.products[product_parent_id].visible ? "" : "d-none")}>
                                                    <td colSpan={6} className="border-top-0 p-0 ml-1 mr-1">
                                                        <ProductRows products={self.state.products[product_parent_id].products}
                                                                     search_term={self.state.search_term}
                                                                     products_sort={self.state.products_sort}
                                                                     parent_id={self.state.products[product_parent_id].product_parent_id} history={self.props.history}
                                                                     getProductParentsChild={self.getProductParentsChild}
                                                                     category_name={self.state.products[product_parent_id].name}
                                                                     equipment={self.state.equipment}
                                                                     equipment_sort={self.state.equipment_sort}
                                                        />
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    }, this)}
                                    </tbody>
                                    <thead>
                                        <tr>
                                            <th className="h4 font-weight-bold" colSpan="5">Total Inventory Value:</th>
                                            <th className="h3 font-weight-bold text-right">${total.toFixed(2).toLocaleString()}</th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer/>
            </ContentWrapper>
        );
    }
}

export default (ProductsOverview);


class ProductRows extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            product_id:false
        }
        this.inventory_adjust_toggle = this.inventory_adjust_toggle.bind(this);
    }

    goToProduct = (product_parent_id, product_id) => {
        this.props.history.push("/product/" + this.props.parent_id + '/' + product_id);
    };

    inventory_adjust_toggle(product_id) {
        this.setState({
            product_id: product_id
        })
    }

    render() {
        var self = this;
        var total = 0;
        return (
            <>
                <table className="table table-sm table-hover mb-3">
                    <thead>
                    <tr>
                        <th className="p-0 bg-light">Item</th>
                        <th className="p-0 bg-light d-md-table-cell">SKU</th>
                        <th className="p-0 bg-light d-md-table-cell text-right">Value/Unit</th>
                        <th className="p-0 bg-light text-right">Quantity</th>
                        <th className="p-0 bg-light text-right">Total</th>
                        <th className="p-0 bg-light"></th>
                    </tr>
                    </thead>
                    <tbody>
                    {Object.keys(this.props.products).map(function (product_id, key) {
                        if (self.props.search_term == "" || (self.props.search_term != "" && self.props.products[product_id].name.toUpperCase().includes(self.props.search_term.toUpperCase()))) {
                            var amount = (self.props.products[product_id].quantity * self.props.products[product_id].price);
                            total = total + amount;
                            return (
                                <tr key={key} onClick={self.goToProduct.bind(self, self.props.products[product_id].product_parent_id, product_id)} title="View / Edit Item">
                                    <td>{self.props.products[product_id].name}</td>
                                    <td className="d-md-table-cell">{self.props.products[product_id].sku}</td>
                                    <td className="d-md-table-cell text-right">${self.props.products[product_id].price.toFixed(2)}</td>
                                    <td className="text-right">{self.props.products[product_id].quantity}</td>
                                    <td className="text-right">${(self.props.products[product_id].quantity * self.props.products[product_id].price).toFixed(2).toLocaleString()}</td>
                                    <td className="text-right"><Button color="primary" size="xs" className="mb-2" onClick={(e) => {
                                        e.stopPropagation();
                                        self.inventory_adjust_toggle(product_id)
                                    }}><i className="fas fa-exchange-alt"></i> Adjust</Button></td>
                                </tr>
                            )
                        }
                    })}
                    </tbody>
                    <thead>
                    <tr>
                        <th colspan="4">Total {this.props.category_name} Inventory Value:</th>
                        <th className="text-right">${total.toFixed(2).toLocaleString()}</th>
                        <th></th>
                    </tr>
                    </thead>
                </table>
                <EditInventory products_sort={self.props.products_sort} products={self.props.products} product_id={self.state.product_id} equipment={self.props.equipment} equipment_sort={self.props.equipment_sort}
                               inventory_adjust_toggle={self.inventory_adjust_toggle} getProductParentsChild={self.props.getProductParentsChild}/>
            </>
        );
    }
}

class EditInventory extends React.Component {
    // input: product_id, products, inventory_adjust, inventory_adjust_modal
    constructor(props) {
        super(props);
        this.state = {
            action_type: false,
            category: false,
            notes: "",
            processing: false,
            can_submit:true,
            equipment_id:null,
            selected_products:{}
        };
    }
    changeActionType = (action_type) => {
        var self = this;
        this.setState({
            action_type: action_type,
            category:false,
            selected_products:[{"product_id":self.props.product_id,"quantity":0,"name":self.props.products[self.props.product_id].name}]
        }, () => {
            this.validate();
        });

    };

    changeCategory = (category) => {
        this.setState({
            category: category
        }, () => {
            this.validate();
        });
    };

    changeProduct = (product_num, product_id) => {
        var selected_products =  this.state.selected_products;
        selected_products[product_num].name = this.props.products[product_id].name;
        selected_products[product_num].product_id = product_id;
        this.setState({ selected_products:selected_products}, () => {
            this.validate();
        });
    };

    incrementItem = (product_num, amount) => {
        var selected_products =  this.state.selected_products;
        selected_products[product_num].quantity = (parseInt(selected_products[product_num].quantity)+parseInt(amount));
        this.setState({ selected_products: selected_products }, () => {
            this.validate();
        });
    };

    changeQuantity = (product_num, event) => {
        if(!isNaN(event.target.value)) {
            var selected_products =  this.state.selected_products;
            selected_products[product_num].quantity = event.target.value;
            this.setState({ selected_products: selected_products }, () => {
                this.validate();
            });
        }
    };

    addProduct = () => {
        var selected_products = this.state.selected_products;
        selected_products.push({"product_id":null,"quantity":0,"name":"(select product)"});
        this.setState({ selected_products: JSON.parse(JSON.stringify(selected_products)) });
    };

    validate() {
        var can_submit = false;
        for (var product_num in this.state.selected_products){
            if (this.state.selected_products[product_num].quantity>0 && this.state.selected_products[product_num].product_id>0){
                can_submit = true;
            }
        }
        this.setState({ can_submit: can_submit});
    }

    noteTyping = (event) => {
        this.setState({notes: event.target.value});
    };
    updateNotes = (event) => {
        if (event.target.value!="") { // if it changed, and is not null vs blank
            this.setState({notes: event.target.value});
        }
    };

    handleFocus= function(event) {
        if (event.target.value=="0"){
            event.target.value="";
        }
        event.target.select();
    };

    changeDropdown = (value) => {
        this.setState({
            equipment_id: value,
        });
    };

    submitForm = async event => {
        event.preventDefault();
        this.setState({
            processing: true
        });
        var self = this;
        axios.defaults.withCredentials = true;
        axios.post(API_ROOT + '/inventory/adjustment', {
            equipment_id: this.state.equipment_id,
            notes: this.state.notes,
            selected_products: this.state.selected_products,
            action_type: this.state.action_type,
            category: this.state.category
        })
            .then(function (response) {
                self.changeActionType(null);
                Swal("Done!", "Inventory Change Successfully Processed", "success");
                self.props.getProductParentsChild()
                self.setState({
                    processing: false
                });
                self.props.inventory_adjust_toggle(false);
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                    self.setState({
                        processing: false
                    });
                }
            });
    }

    render() {
        var self = this;
        return (
            <>
                <Modal isOpen={this.props.product_id!==false} fade={false} toggle={() => {this.props.inventory_adjust_toggle(false)}}>
                    <ModalHeader>
                        <div className="h3 mborder-0">Adjust Inventory Count</div>
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup row>
                            <Label sm={4} lg={3}>Type:</Label>
                            <Col>
                                <ButtonGroup className="pl-3">
                                    <Button color={(this.state.action_type==2?"info":"secondary")} onClick={() => this.changeActionType(2)}>Reduce</Button>
                                    <Button color={(this.state.action_type==1?"info":"secondary")} onClick={() => this.changeActionType(1)}>Add</Button>
                                </ButtonGroup>
                            </Col>
                        </FormGroup>
                        <FormGroup row className={(this.state.action_type==false?"d-none":"")}>
                            <Label sm={4} lg={3}>Category:</Label>
                            <Col>
                                <ButtonGroup className="pl-3">
                                    <Button className={(this.state.action_type!=1?"d-none":"")} color={(this.state.category==1?"info":"secondary")} onClick={() => this.changeCategory(1)}>New Inventory</Button>
                                    <Button className={(this.state.action_type!=2?"d-none":"")} color={(this.state.category==3?"info":"secondary")} onClick={() => this.changeCategory(3)}>Used</Button>
                                    <Button className={(this.state.action_type!=2?"d-none":"")} color={(this.state.category==2?"info":"secondary")} onClick={() => this.changeCategory(2)}>Damaged</Button>
                                    <Button color={(this.state.category==4?"info":"secondary")} onClick={() => this.changeCategory(4)}>Reconciliation</Button>
                                </ButtonGroup>
                            </Col>
                        </FormGroup>
                        <FormGroup row className={(this.state.action_type==2 && this.state.category==3?"":"d-none")}>
                            <Label sm={4} lg={3}>Equipment:</Label>
                            <Col>
                                <UncontrolledButtonDropdown className="pl-3">
                                    <DropdownToggle color="secondary" caret style={{fontSize: '13px', width: 'auto'}}>
                                        {(this.state.equipment_id==null?"Select":this.props.equipment[this.state.equipment_id].name)}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {typeof self.props.equipment_sort!="undefined" && Object.entries(self.props.equipment_sort).map(([key, equipment]) => {
                                            return (<DropdownItem key={key}
                                                                      onClick={() => self.changeDropdown(equipment.equipment_id)}>{equipment.name}</DropdownItem>)
                                        })}
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </Col>
                        </FormGroup>
                        <FormGroup row className={((this.state.action_type!=false && this.state.category!=false)?"":"d-none")}>
                            <Label sm={4} lg={3}>Quantity:</Label>
                            <Col>
                                {Object.keys(this.state.selected_products).map((product_num) =>
                                    <Form inline className="pb-2" key={product_num}>
                                        <InputGroup className="pl-2">
                                            <InputGroupAddon addonType="prepend" style={{cursor:"pointer"}} disabled={(this.state.selected_products[product_num].quantity<=0)} onClick={this.incrementItem.bind(this, product_num, -1)}>-</InputGroupAddon>
                                            <Input type="tel" className="form-control text-right" value={this.state.selected_products[product_num].quantity} onFocus={this.handleFocus} onChange={this.changeQuantity.bind(this, product_num)} style={{maxWidth:'60px'}}/>
                                            <InputGroupAddon addonType="append" style={{cursor:"pointer"}} onClick={this.incrementItem.bind(this, product_num, 1)}>+</InputGroupAddon>
                                        </InputGroup>
                                    </Form>
                                )}
                            </Col>
                        </FormGroup>
                        <FormGroup row className={((this.state.action_type!=false && this.state.category!=false)?"":"d-none")}>
                            <Label sm={4} lg={3}>Notes:</Label>
                            <Col>
                                <Input type="textarea" style={{height:'100px'}} onChange={this.noteTyping.bind(this)} onBlur={this.updateNotes.bind(this)} value={this.state.notes}></Input>
                            </Col>
                        </FormGroup>
                        <FormGroup row className={((this.state.action_type!=false && this.state.category!=false)?"":"d-none")}>
                            <Col sm={4} lg={3}></Col>
                            <Col>
                                <Button color="primary" onClick={this.submitForm} disabled={this.state.processing}><i className={(this.state.processing?"fa fa-spinner fa-spin mr-2":"d-none")}></i>Submit</Button>
                            </Col>
                        </FormGroup>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}