import React, {Component} from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {Card, CardBody} from 'reactstrap';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import moment from 'moment';
import 'moment-timezone';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import {getProducts} from '../Products/ProductFunctions.js';

class InventoryAdjust extends Component {
    constructor(props) {
        super(props);

        this.updateDate = this.updateDate.bind(this);

        this.state = {
            product_array: [],
            overview: [],
            effective_date: moment().add(1, 'days'),
            loading: true,
        };
    }

    componentDidMount() {
        document.title = "Inventory Overview | Jeremie Cormier Trucking Ltd";
        this.updateCalc()
    }

    updateCalc = () => {
        var self = this;

        function dynamicSort(property) {
            var sortOrder = 1;
            if (property[0] === "-") {
                sortOrder = -1;
                property = property.substr(1);
            }
            return function (a, b) {
                /* next line works with strings and numbers,
                 * and you may want to customize it to your needs
                 */
                var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
                return result * sortOrder;
            }
        }

        getProducts(function (products) {
            var product_array = [];
            for (var i in products) {
                if (products[i].t_inv == 1 && products[i].i_rollup == null) {
                    product_array.push(products[i]);
                }
            }
            var sorted = product_array.sort(dynamicSort("name"))
            axios.defaults.withCredentials = true;
            axios.get(API_ROOT + '/inventory/overview/' + moment.unix(self.state.effective_date / 1000).format("YYYY-MM-DD"))
                .then(function (response) {
                    self.setState({
                        overview: response.data,
                        loading: false,
                        product_array: sorted
                    });
                })
                .catch(function (error) {
                    if (error.response.status === 401) {
                        self.props.userSignOut()
                    } else {
                        Swal("Error", error.response.data.Message, "error");
                    }
                });
        });
    }

    // On update Est Delivery Date
    updateDate(event, picker) {
        event.preventDefault();
        if (event.type == "apply") {
            this.setState({
                effective_date: picker.startDate,
            });
        }
        this.updateCalc()
    }

    render() {
        var self = this;
        return (
            <ContentWrapper>
                <div className="content-heading">
                    Inventory Overview
                </div>
                <Card className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                    <CardBody>
                        <div className="mb-3 form-inline">
                            <DateRangePicker
                                singleDatePicker={true}
                                date={(this.state.effective_date == null ? moment() : this.state.effective_date)}
                                onEvent={this.updateDate}
                                autoUpdateInput={true}
                            >
                                <div className="btn btn-secondary">
                                    {(this.state.effective_date == null ? 'As of Now' : this.state.effective_date.format('MMM. D, YYYY'))}
                                    <span className="fas fa-caret-down fa-sm"></span>
                                </div>
                            </DateRangePicker>
                        </div>

                        <div className="table-responsive">
                            <table className="table table-bordered table-striped">
                                <thead>
                                <tr>
                                    <th style={{minWidth: "200px"}}>Product</th>
                                    <th>Total</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.product_array.map(function (key, i) {
                                    var total = 0;
                                    var product_id = this.state.product_array[i].product_id;
                                    var inventory = this.state.overview[product_id].total;
                                    if (this.state.product_array[i].is_deleted == 0) {
                                        return (<tr key={key} style={{height: "65px"}}>
                                            <td style={{left: 15, position: "absolute", top: "auto", width: "200px", height: "66px", backgroundColor: "#fff"}}
                                                className="font-bold">{this.state.product_array[i].name}</td>
                                            <td>
                                                {(Number.isInteger(inventory) || inventory == 0 ? inventory : parseFloat(inventory).toFixed(2))}
                                            </td>
                                            <td className="text-bold">{total.toFixed(2)}</td>
                                        </tr>)
                                    }
                                }, this)}
                                </tbody>
                            </table>
                        </div>
                    </CardBody>
                </Card>
            </ContentWrapper>
        );
    }
}

export default InventoryAdjust;
