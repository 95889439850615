import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {
    Button,
    InputGroup,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    UncontrolledButtonDropdown,
    Modal,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Input,
    InputGroupAddon,
    Col,
    Row,
    Table,
    Label,
    Card,
    CardBody,
    Badge
} from 'reactstrap';
import {getProductsParent} from '../Products/ProductFunctions.js';

import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'

import {Link} from 'react-router-dom';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import 'bootstrap/dist/css/bootstrap.css';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import 'bootstrap-daterangepicker/daterangepicker.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import 'moment-timezone';

moment.tz.setDefault("America/Halifax");

class ProductOverview extends React.Component {
    constructor(props) {
        super(props);
        window.ProductComponent = this;

        this.state = {
            products: {},
            loading: true,
            parent_name: "",
            display_photo: false,
            photo_hash: 0,
            // default product selection
            product: {
                name: "",
                product_parent_id: null,
                sku: "",
                notes: "",
                account_id: null,
            },
            // display
            dropdown_print: {
                category: "",
                parent: "",
            },
            // default lists
            list: {
                type: [],
                parent: [],
                category: [],
            },
            accounts: [],
            modal: false,
            qbo_map: {0: "< General product SKU, auto-created as needed >",},
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        document.title = "Product | Jeremie Cormier Trucking Ltd";
        var self = this;
        self.getProductInfo();
        this.checkPhoto();
    }

    getProductInfo() {
        var self = this;
        getProductsParent(function (parents) {
            var list = self.state.list;
            list.parent = parents;
            self.setState({list: list, loading: false});
            var dropdown_print = self.state.dropdown_print;
            var product = self.state.product;
            Object.keys(parents).map(function (product_parent_id, key) {
                var parent = parents[product_parent_id];
                if (product_parent_id == self.props.match.params.product_parent_id) {
                    self.setState({parent_name: parent.name});
                    Object.keys(parent.products).forEach(function (product_id) {
                        if (product_id == self.props.match.params.product_id) {
                            product = parent.products[product_id];
                            document.title = product.name + " | Bevvy";
                            dropdown_print.parent = list.parent[product_parent_id].name;
                        }
                    });
                }
            });
            // set the parent if there is no product specified
            if (isNaN(self.props.match.params.product_id)) {
                dropdown_print.parent = list.parent[self.props.match.params.product_parent_id].name;
                product.product_parent_id = self.props.match.params.product_parent_id;
                product.name = list.parent[self.props.match.params.product_parent_id].name;
            }
            console.log(product)
            self.setState({product: product, dropdown_print: dropdown_print});
        });
    }

    checkPhoto() {
        var imageExists = require('image-exists');
        var self = this;
        var hash = Math.random().toString(36).substring(7);
        this.setState({photo_hash: hash}, () => {
            var src = `https://cormier.customtms.ca/img/products/${self.props.match.params.product_id}.jpg?${hash}`;
                imageExists(src, function (exists) {
                    if (exists) {
                        self.setState({display_photo: true});
                    }
                });
            }
        );
    }

    handleChange(event) {
        var product = this.state.product;
        product[event.target.name] = event.target.value;
        this.setState({
            product: product
        });
    }

    changeDropdown = (value, key) => {
        var dropdown_print = this.state.dropdown_print;
        if (value == null) {
            dropdown_print[key] = ""
        } else {
            dropdown_print[key] = this.state.list[key][value].name;
        }
        var product = this.state.product;
        product["product_" + key + "_id"] = value;
        this.setState({
            product: product,
            dropdown_print: dropdown_print
        });
    };

    saveChanges = event => {
        event.persist();
        event.preventDefault();
        var self = this;
        axios.defaults.withCredentials = true;
        if (event.target.id == "add") {
            axios.post(API_ROOT + '/product/', {data: this.state.product})
                .then(function (response) {
                    self.getProductInfo();
                    self.props.history.push("/product/" + self.props.match.params.product_parent_id + "/" + response.data.id);
                    toast.success("Product Added", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                })
                .catch(function (error) {
                    if (error.response.status === 401) {
                        self.props.userSignOut()
                    } else {
                        Swal("Error", error.response.data.Message, "error");
                    }
                });
        } else {
            axios.put(API_ROOT + '/product/' + this.props.match.params.product_id, {data: this.state.product, mode: event.target.id})
                .then(function (response) {
                    console.log('OK');
                    toast.success("Changes Saved", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                })
                .catch(function (error) {
                    if (error.response.status === 401) {
                        self.props.userSignOut()
                    } else {
                        Swal("Error", error.response.data.Message, "error");
                    }
                });
        }
        localStorage.removeItem('products');
    };

    toggleModal = () => {
        this.setState({
            modal: !this.state.modal
        });
    };

    deleteProduct = () => {
        var self = this;
        axios.defaults.withCredentials = true;
        Swal.fire({
            title: 'Are you sure?',
            text: "The product will be deleted..",
            showCancelButton: true,
            confirmButtonText: 'Yes, delete now',
            cancelButtonText: 'No',
        }).then((result) => {
            axios.delete(API_ROOT + '/product/' + self.props.match.params.product_id)
                .then(function (response) {
                    self.getProductInfo();
                    toast.success("Changes Saved", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                    self.props.history.push("/products/");
                })
                .catch(function (error) {
                    Swal("Error", error.response.data.Message, "error");
                });
        });
    };

    render() {

        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>
                        <span>
                            <Link to="../../products">Inventory</Link> / {this.state.parent_name} / {this.state.product.name}&nbsp;
                        </span>
                    </div>
                </div>
                <Row>
                    <Col lg={6}>
                        <Card className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                            <CardBody>
                                <p className="lead bb">Item Info</p>
                                <form className="form-horizontal" id={(isNaN(this.props.match.params.product_id) ? "add" : "info")} onSubmit={this.saveChanges}>
                                    <Row className={(isNaN(this.props.match.params.product_id) ? "d-none" : "mb-1")}>
                                        <label className="col-xl-3">Item #</label>
                                        <div className="col-xl-9">
                                            {this.props.match.params.product_id}
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label">Name</label>
                                        <div className="col-xl-9">
                                            <Input type="text" name="name" value={this.state.product.name} onChange={this.handleChange} required/>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label">Category</label>
                                        <div className="col-xl-9">
                                            <UncontrolledButtonDropdown>
                                                <DropdownToggle color="secondary" caret size="sm" className="text-left">
                                                    {this.state.dropdown_print.parent}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {Object.keys(this.state.list.parent).map(function (parent_id, key) {
                                                        return (
                                                            <DropdownItem key={parent_id}
                                                                          onClick={() => this.changeDropdown(parent_id, "parent")}>{this.state.list.parent[parent_id].name}</DropdownItem>
                                                        )
                                                    }, this)}
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                            <Link to="../../lists/owner" className="ml-2 text-muted" title="Add/Edit List"><i class="fas fa-edit"></i></Link>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label">SKU</label>
                                        <div className="col-xl-9">
                                            <Input type="text" name="sku" value={this.state.product.sku} onChange={this.handleChange}/>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label">Value</label>
                                        <div className="col-xl-9">
                                            <InputGroup className="pl-2">
                                                <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                                                <Input type="text" name="price" value={this.state.product.price} onChange={this.handleChange}/>
                                            </InputGroup>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label">Notes</label>
                                        <div className="col-xl-9">
                                            <Input type="textarea" name="notes" value={this.state.product.notes} onChange={this.handleChange}/>
                                        </div>
                                    </Row>
                                    <Row className="">
                                        <div className="offset-xl-3 col-xl-9">
                                            <button className="btn btn-primary" type="submit"
                                                    name="test">{(isNaN(this.props.match.params.product_id) ? "Add" : "Save Changes")}</button>
                                        </div>
                                    </Row>
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={6} className={(isNaN(this.props.match.params.product_id) ? "d-none" : "")}>
                        <Card className="card card-default">
                            <CardBody>
                                <p className="lead bb">Item Photo</p>
                                <div style={{textAlign: "center"}} className={(!this.state.display_photo ? "d-none" : "pb-2")}>
                                    <img src={"https://cormier.customtms.ca/img/products/" + this.props.match.params.product_id + ".jpg?" + this.state.photo_hash}
                                         style={{maxWidth: "100%", maxHeight: "250px"}} alt="Loading Photo..."/>
                                </div>
                                <div>
                                    <Upload product_id={this.state.product.product_id}/>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Button color="danger" size="xs" onClick={this.deleteProduct}>Delete Item</Button>
                <ToastContainer/>
            </ContentWrapper>
        );
    }
}


const Upload = (props) => {
    const getUploadParams = ({file, meta}) => {
        const url = API_ROOT + '/photos/' + props.product_id;
        const body = new FormData();
        body.append('image', file);
        return {url, body}
    };

    const handleChangeStatus = ({meta, remove}, status) => {
        if (status === 'headers_received') {
            window.ProductComponent.checkPhoto();
            remove()
        } else if (status === 'aborted') {
            Swal("Error", "Upload Failed", "error");
        }
    };

    return (
        <React.Fragment>
            <Dropzone
                getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                maxFiles={1}
                multiple={false}
                canCancel={false}
                accept="image/*"
                withCredentials={true}
                inputContent={(files, extra) => (extra.reject ? 'JPG or PNG files only' : "Upload an Image (drag n' drop or click)")}
                styles={{
                    inputLabel: {fontSize: "14px", fontWeight: "normal"},
                    dropzone: {height: 100, minHeight: 75, border: "1px dashed #ccc",},
                    dropzoneActive: {borderColor: 'green'},
                }}
            />
        </React.Fragment>
    )
};

export default (ProductOverview);

