import React, {Component} from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {Link} from 'react-router-dom';
import {
    Card,
    CardBody,
    Row,
    Col,
    InputGroup,
    Input,
    InputGroupAddon,
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import 'lity/dist/lity.min.js';
import 'lity/dist/lity.min.css';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import 'moment-timezone';

moment.tz.setDefault("America/Halifax");

class CustomerPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customer_id: null,
            details: {
                loading: true,
                name: "",
                legal_name: "",
                address1: "",
                address2: "",
                province: "",
                city: "",
                postal_code: "",
                country: "",
                notes: "",
                ap_email: "",
                rate_fixed: 0,
                rate_miles: 0,
                rate_hr: 0,
                rate_wait: 0,
                rate_fixed_fsc: 0,
                rate_other: 0,
                rate_drops: 0,
                tax_fixed: 0,
                tax_miles: 0,
                tax_fixed_fsc: 0,
                tax_fsc: 0,
                tax_other: 0,
                tax_drops: 0,
                tax_wait: 0,
                tax_hr: 0,
                fsc_percent:100
            },
            invoices: {
                loading: true,
                list: []
            },
            province_list: ['NB', 'NS', 'PE', 'NL', 'ON', 'QC', 'SK', 'MB', 'AB', 'BC','AL',
                'AK',
                'AZ',
                'AR',
                'CA',
                'CO',
                'CT',
                'DE',
                'FL',
                'GA',
                'HI',
                'ID',
                'IL',
                'IN',
                'IA',
                'KS',
                'KY',
                'LA',
                'ME',
                'MD',
                'MA',
                'MI',
                'MN',
                'MS',
                'MO',
                'MT',
                'NE',
                'NV',
                'NH',
                'NJ',
                'NM',
                'NY',
                'NC',
                'ND',
                'OH',
                'OK',
                'OR',
                'PA',
                'RI',
                'SC',
                'SD',
                'TN',
                'TX',
                'UT',
                'VT',
                'VA',
                'WA',
                'WV',
                'WI',
                'WY'],
        };
        this.updateValue = this.updateValue.bind(this);
    }

    componentDidMount() {
        document.title = "Customer # " + this.props.match.params.customer_id + " | Jeremie Cormier Trucking Ltd";
        this.setState({customer_id: this.props.match.params.customer_id})
        var self = this;
        axios.defaults.withCredentials = true;
        axios.get(API_ROOT + '/customer/' + self.props.match.params.customer_id)
            .then(function (response) {
                response.data.loading = false;
                self.setState({
                    details: response.data,
                });
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
            });

        axios.defaults.withCredentials = true;
        axios.get(API_ROOT + '/invoices/?customer_id=' + this.props.match.params.customer_id + "&startDate=" + moment().subtract(30, 'd').unix() * 1000 + "&endDate=" + moment().add(30, 'd').unix() * 1000)
            .then(function (response) {
                self.setState({
                        invoices: {
                            list: response.data,
                            loading: false
                        },
                    }
                );
                self.onSort(null, self.state.sort)
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
            });
    }

    saveDetails = async event => {
        if (event) {
            event.preventDefault();
        }
        var self = this;
        axios.defaults.withCredentials = true;
        axios.put(API_ROOT + '/customer/' + this.props.match.params.customer_id, {data: this.state.details})
            .then(function (response) {
                toast.success("Changes Saved", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
            });
    };

    updateValue(event, name, value) {
        var self = this;
        if (event.target) {
            event.preventDefault();
            event.stopPropagation();
        }
        if (typeof name == "undefined") {
            name = event.target.name
        }
        if (typeof value == "undefined") {
            value = event.target.value
        }
        var details = this.state.details;
        details[name] = value;
        this.setState({
            details: details
        }, () => {
            self.saveDetails();
        });
    }

    changeProvince = (province) => {
        var self = this;
        var details = this.state.details;
        details['province'] = province;
        this.setState({
            details: details,
        }, () => {
            self.saveDetails();
        });
    }

    goToOrder = (order_id) => {
        this.props.history.push("/order/" + order_id);
    };


    render() {
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>
                        <Link to="../customers">Customers</Link>&nbsp;/&nbsp; {this.state.details.name}
                    </div>
                </div>
                <Row>
                    <Col lg={6}>
                        <Card className={(this.state.details.loading ? "card card-default whirl traditional" : "card card-default")}>
                            <CardBody>
                                <p className="lead bb">Details</p>
                                <form className="form-horizontal">
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">Name</label>
                                        <div className="col-xl-9">
                                            <Input size="sm" type="text" name="name" value={this.state.details.name} required onChange={this.updateValue}/>
                                        </div>
                                    </Row>
                                    <div className="border m-0 p-1 mb-1 bg-light rounded">
                                        <div className="row pb-1">
                                            <label className="col-xl-3 col-form-label pt-0"></label>
                                            <div className="col-xl-9 small text-bold">BILLING ADDRESS:</div>
                                        </div>
                                        <Row className="mb-1">
                                            <label className="col-xl-3 col-form-label pt-0">Legal Name</label>
                                            <div className="col-xl-9">
                                                <Input size="sm" type="text" name="name_legal" value={this.state.details.name_legal} required onChange={this.updateValue}/>
                                            </div>
                                        </Row>
                                        <Row className="mb-1">
                                            <label className="col-xl-3 col-form-label pt-0">Address</label>
                                            <div className="col-xl-9">
                                                <Input size="sm" type="text" name="address1" value={this.state.details.address1} required onChange={this.updateValue}/>
                                            </div>
                                        </Row>
                                        <Row className="mb-1">
                                            <label className="col-xl-3 col-form-label pt-0">Suite/Unit</label>
                                            <div className="col-xl-9">
                                                <Input size="sm" type="text" name="address2" value={this.state.details.address2} onChange={this.updateValue}/>
                                            </div>
                                        </Row>
                                        <Row className="mb-1">
                                            <label className="col-xl-3 col-form-label pt-0">City</label>
                                            <div className="col-xl-9">
                                                <Input size="sm" type="text" name="city" value={this.state.details.city} required onChange={this.updateValue}/>
                                            </div>
                                        </Row>
                                        <Row className="mb-1">
                                            <label className="col-xl-3 col-form-label pt-0">Province</label>
                                            <div className="col-xl-9">
                                                <UncontrolledButtonDropdown>
                                                    <DropdownToggle color="secondary" size="sm" caret style={{fontSize: '13px', width: 'auto'}}>
                                                        {this.state.details.province}
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        {this.state.province_list.map((province, i) =>
                                                            <DropdownItem key={province} onClick={() => this.changeProvince(province)}>{province}</DropdownItem>
                                                        )}
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                            </div>
                                        </Row>
                                        <Row className="mb-1">
                                            <label className="col-xl-3 col-form-label pt-0">Postal</label>
                                            <div className="col-xl-9">
                                                <Input size="sm" type="text" name="postal_code" value={this.state.details.postal_code} onChange={this.updateValue}/>
                                            </div>
                                        </Row>
                                    </div>

                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">AP Email</label>
                                        <div className="col-xl-9">
                                            <Input size="sm" type="text" name="ap_email" value={this.state.details.ap_email} onChange={this.updateValue}/>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">Notes</label>
                                        <div className="col-xl-9">
                                            <Input type="textarea" style={{minHeight: "50px"}} name="notes" value={this.state.details.notes} onChange={this.updateValue}/>
                                        </div>
                                    </Row>
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={6}>
                        <Card className="card card-default">
                            <CardBody>
                                <p className="lead bb">Billing Defaults <small className={"text-muted"}>(applied when load is created)</small></p>
                                <Row>
                                    <label className="pt-0 col-md-4 col-xl-4 col-form-label"></label>
                                    <div className="col-md-5 col-xl-5 text-bold text-right">
                                        Rate
                                    </div>
                                    <div className="col-md-3 col-xl-3 text-bold text-right">
                                        Tax
                                    </div>
                                </Row>
                                <Row>
                                    <label className="pt-0 col-md-4 col-form-label">Fixed Rate</label>
                                    <div className="col-md-5">
                                        <InputGroup size="sm">
                                            <InputGroupAddon addonType="append">$</InputGroupAddon>
                                            <Input type="text" name="rate_fixed" className="text-right" value={this.state.details.rate_fixed} onChange={this.updateValue}/>
                                        </InputGroup>
                                    </div>
                                    <div className="col-md-3">
                                        <InputGroup size="sm">
                                            <Input type="text" name="tax_fixed" className="text-right" value={this.state.details.tax_fixed} onChange={this.updateValue}/>
                                            <InputGroupAddon addonType="append">%</InputGroupAddon>
                                        </InputGroup>
                                    </div>
                                </Row>
                                <Row className="pt-1">
                                    <label className="pt-0 col-md-4 col-form-label">Rate per Mile</label>
                                    <div className="col-md-5">
                                        <InputGroup size="sm">
                                            <InputGroupAddon addonType="append">$</InputGroupAddon>
                                            <Input type="text" name="rate_miles" className="text-right" value={this.state.details.rate_miles} onChange={this.updateValue}/>
                                        </InputGroup>
                                    </div>
                                    <div className="col-md-3">
                                        <InputGroup size="sm">
                                            <Input type="text" name="tax_miles" className="text-right" value={this.state.details.tax_miles} onChange={this.updateValue}/>
                                            <InputGroupAddon addonType="append">%</InputGroupAddon>
                                        </InputGroup>
                                    </div>
                                </Row>
                                <Row className="pt-1">
                                    <label className="pt-0 col-md-4 col-form-label">Variable FSC</label>
                                    <div className="col-md-5">
                                        <InputGroup size="sm">
                                            <Input type="text" name="fsc_percent" className="text-right" value={this.state.details.fsc_percent} onChange={this.updateValue}/>
                                            <InputGroupAddon addonType="append">% of TL Rate</InputGroupAddon>
                                        </InputGroup>
                                    </div>
                                    <div className="col-md-3">
                                        <InputGroup size="sm">
                                            <Input type="text" name="tax_fsc" className="text-right" value={this.state.details.tax_fsc} onChange={this.updateValue}/>
                                            <InputGroupAddon addonType="append">%</InputGroupAddon>
                                        </InputGroup>
                                    </div>
                                </Row>
                                <Row className="pt-1">
                                    <label className="pt-0 col-md-4 col-form-label">Fixed FSC</label>
                                    <div className="col-md-5">
                                        <InputGroup size="sm">
                                            <InputGroupAddon addonType="append">$</InputGroupAddon>
                                            <Input type="text" name="rate_fixed_fsc" className="text-right" value={this.state.details.rate_fixed_fsc} onChange={this.updateValue}/>
                                        </InputGroup>
                                    </div>
                                    <div className="col-md-3">
                                        <InputGroup size="sm">
                                            <Input type="text" name="tax_fixed_fsc" className="text-right" value={this.state.details.tax_fixed_fsc} onChange={this.updateValue}/>
                                            <InputGroupAddon addonType="append">%</InputGroupAddon>
                                        </InputGroup>
                                    </div>
                                </Row>
                                <Row className="pt-1">
                                    <label className="pt-0 col-md-4 col-form-label">Hourly Rate</label>
                                    <div className="col-md-5">
                                        <InputGroup size="sm">
                                            <InputGroupAddon addonType="append">$</InputGroupAddon>
                                            <Input type="text" name="rate_hr" className="text-right" value={this.state.details.rate_hr} onChange={this.updateValue}/>
                                            <InputGroupAddon addonType="append">/hr</InputGroupAddon>
                                        </InputGroup>
                                    </div>
                                    <div className="col-md-3">
                                        <InputGroup size="sm">
                                            <Input type="text" name="tax_hr" className="text-right" value={this.state.details.tax_hr} onChange={this.updateValue}/>
                                            <InputGroupAddon addonType="append">%</InputGroupAddon>
                                        </InputGroup>
                                    </div>
                                </Row>
                                <Row className="pt-1">
                                    <label className="pt-0 col-md-4 col-form-label">Wait Time</label>
                                    <div className="col-md-5">
                                        <InputGroup size="sm">
                                            <InputGroupAddon addonType="append">$</InputGroupAddon>
                                            <Input type="text" name="rate_wait" className="text-right" value={this.state.details.rate_wait} onChange={this.updateValue}/>
                                            <InputGroupAddon addonType="append">/hr</InputGroupAddon>
                                        </InputGroup>
                                    </div>
                                    <div className="col-md-3">
                                        <InputGroup size="sm">
                                            <Input type="text" name="tax_wait" className="text-right" value={this.state.details.tax_wait} onChange={this.updateValue}/>
                                            <InputGroupAddon addonType="append">%</InputGroupAddon>
                                        </InputGroup>
                                    </div>
                                </Row>
                                <Row className="pt-1">
                                    <label className="pt-0 col-md-4 col-form-label">Drop Fee</label>
                                    <div className="col-md-5">
                                        <InputGroup size="sm">
                                            <InputGroupAddon addonType="append">$</InputGroupAddon>
                                            <Input type="text" name="rate_drops" className="text-right" value={this.state.details.rate_drops} onChange={this.updateValue}/>
                                        </InputGroup>
                                    </div>
                                    <div className="col-md-3">
                                        <InputGroup size="sm">
                                            <Input type="text" name="tax_drops" className="text-right" value={this.state.details.tax_drops} onChange={this.updateValue}/>
                                            <InputGroupAddon addonType="append">%</InputGroupAddon>
                                        </InputGroup>
                                    </div>
                                </Row>
                                <Row className="pt-1">
                                    <label className="pt-0 col-md-4 col-form-label">Other</label>
                                    <div className="col-md-5">
                                        <InputGroup size="sm">
                                            <InputGroupAddon addonType="append">$</InputGroupAddon>
                                            <Input type="text" name="rate_other" className="text-right" value={this.state.details.rate_other} onChange={this.updateValue}/>
                                        </InputGroup>
                                    </div>
                                    <div className="col-md-3">
                                        <InputGroup size="sm">
                                            <Input type="text" name="tax_other" className="text-right" value={this.state.details.tax_other} onChange={this.updateValue}/>
                                            <InputGroupAddon addonType="append">%</InputGroupAddon>
                                        </InputGroup>
                                    </div>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <ToastContainer/>
            </ContentWrapper>
        );
    }

}

export default CustomerPage;
