import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import 'moment-timezone';
import getLocations from '../Locations/LocationFunctions';
import {getEquipment} from "../Equipment/EquipmentFunctions";
import {Link} from "react-router-dom";
import {Input, Label} from "reactstrap";

moment.tz.setDefault("America/Halifax");

class LocationsList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            equipment: [],
            vehicles: {},
            vehicles_sort: [],
            loading: true,
            is_stop: 1,
            locations: {locations: {}, locations_sort: []},
            pings: [],
        };
    }

    componentDidMount() {
        document.title = "Locations | Jeremie Cormier Trucking Ltd";
        var self = this;
        getEquipment(0, function (equipment) {
            getLocations(function (locations) {
                self.setState({
                    locations: locations,
                    equipment: equipment
                });
                axios.get(API_ROOT + '/equipment/pings?id=' + moment().unix())
                    .then(function (response) {
                        self.setState({
                                pings: response.data,
                                loading: false,
                                last_update: moment()
                            },
                        );
                    })
            });
        });
    }

    updateValue(event, name, value) {
        if (event.target) {
            event.preventDefault();
            event.stopPropagation();
        }
        if (typeof name == "undefined") {
            name = event.target.name
        }
        if (typeof value == "undefined") {
            value = event.target.value
        }
        this.setState({
            [name]: [value]
        });
    }

    render() {
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>Locations</div>
                    <div className="ml-auto">

                    </div>
                </div>
                <div className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                    <div>
                        <div className="card-header">
                            <div className="card-title">{this.state.locations.locations_sort.length} Locations</div>
                        </div>
                        <div className="card-body">
                            <div>
                                <Label className="mb-0 pl-4 text-bold">
                                    <Input type="checkbox" name="status" value="1"
                                           checked={(this.state.is_stop == 1)}
                                           onChange={(e) => {
                                               this.updateValue(e, 'is_stop', (this.state.is_stop == 1 ? 0 : 1))
                                           }}/>
                                    Show "Stop" Locations
                                </Label>
                            </div>
                            <table className={(this.state.locations.locations_sort.length > 0 ? "table table-pointer text-right" : "d-none")}>
                                <thead>
                                <tr>
                                    <th className="text-left" onClick={e => this.onSort(e, 'name')}><i className="fas fa-sort"></i> Name</th>
                                    <th className="text-left" onClick={e => this.onSort(e, 'address')}><i className="fas fa-sort"></i> Address</th>
                                    <th className="text-left">On Site</th>
                                </tr>
                                </thead>
                                <tbody>
                                {Object.entries(this.state.locations.locations_sort).map(([key, location]) => {
                                    if (this.state.is_stop == this.state.locations.locations[location.location_id].is_stop) {
                                        return (
                                            <tr key={key}>
                                                <td className="text-left"><Link to={"../location/" + location.location_id}>{location.name}</Link></td>
                                                <td className="text-left">{location.address}, {location.city}</td>
                                                <td className="text-left text-wrap">
                                                    {Object.entries(this.state.equipment).map(([equipment_id]) => {
                                                        if (typeof this.state.pings[equipment_id] != "undefined" && typeof this.state.pings[equipment_id][0] != "undefined" && this.state.pings[equipment_id][0].desc == location.name) {
                                                            if (this.state.equipment[equipment_id].is_vehicle == 1) {
                                                                return (
                                                                    <span className="pr-1 text-wrap">
                                                                    <Link to={"../vehicles/" + equipment_id}>{this.state.equipment[equipment_id].name}</Link>,
                                                                </span>
                                                                )
                                                            } else {
                                                                return (
                                                                    <span className="pr-1 text-wrap">
                                                                    <Link to={"../equipment/" + equipment_id}>{this.state.equipment[equipment_id].name}</Link>,
                                                                </span>
                                                                )
                                                            }
                                                        }
                                                    })}
                                                </td>
                                            </tr>
                                        )
                                    }
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export default (LocationsList);