import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {
    Button,
    Modal,
    Col,
    Label,
    FormGroup,
    ModalBody,
    ModalHeader,DropdownToggle, DropdownMenu, DropdownItem, UncontrolledButtonDropdown,
} from 'reactstrap';
import {getEquipment} from "../Equipment/EquipmentFunctions";

import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import 'bootstrap/dist/css/bootstrap.css';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment/moment";

class Trackers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            trackers: [],
            name: "",
            key: false,
            loading: true,
            tracker_id: false,
            equipment: {},
            equipment_sort:[]
        };
        this.trackers_adjust_toggle = this.trackers_adjust_toggle.bind(this);
        this.updateTrackers = this.updateTrackers.bind(this);
    }

    componentDidMount() {
        document.title = "Trackers | Jeremie Cormier Trucking Ltd";
        this.updateTrackers();
    }

    updateTrackers(){
        var self = this;
        axios.defaults.withCredentials = true;
        axios.get(`${API_ROOT}/trackers/`, {withCredentials: true})
            .then(function (trackers) {
                getEquipment(0, function (equipment) {
                    var equipment_sort = [];
                    for (const equipment_id of Object.keys(equipment)) {
                        if (equipment[equipment_id].is_vehicle===0) {
                            var obj = equipment[equipment_id];
                            equipment_sort.push(obj)
                        }
                    }
                    self.setState({
                        loading:false,
                        trackers:trackers.data,
                        equipment: equipment,
                        equipment_sort: equipment_sort
                    });
                });
            })
    }

    trackers_adjust_toggle(key) {
        var self = this;
        if (key===false){
            this.setState({
                tracker_id: false
            })
        } else {
            console.log(key)
            this.setState({
                tracker_id: self.state.trackers[key].tracker_id,
                name: self.state.trackers[key].name,
                key: key,
            })
        }
    }

    render() {
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>
                        Trackers
                    </div>
                </div>
                <div className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                    <div className={(this.state.loading ? "invisible" : "")}>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className={(!this.state.loading ? "table table-sm table-hover table-pointer text-left" : "d-none")}>
                                    <thead>
                                        <tr>
                                            <th>Number</th>
                                            <th>Battery</th>
                                            <th>Last Ping</th>
                                            <th>Assigned To</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.trackers.map(function (tracker, key) {
                                        return (
                                            <React.Fragment key={key}>
                                                <tr>
                                                    <td><b>{tracker.name}</b></td>
                                                    <td>100%</td>
                                                    <td>{(tracker.last_seen==null?"":moment.duration(moment().unix() - tracker.last_seen, 'seconds').humanize()+" ago")}</td>
                                                    <td>{(tracker.equipment_id==null?"Unassigned":this.state.equipment[tracker.equipment_id].name)}</td>
                                                    <td><Button color="primary" size="xs" onClick={() => this.trackers_adjust_toggle(key)}>Assign</Button></td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    }, this)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <EditTracker equipment_sort={this.state.equipment_sort} equipment={this.state.equipment} tracker_id={this.state.tracker_id} name={this.state.name}  key={this.state.key}
                             trackers_adjust_toggle={this.trackers_adjust_toggle} updateTrackers={this.updateTrackers} trackers={this.state.trackers}/>
                <ToastContainer/>
            </ContentWrapper>
        );
    }
}

export default (Trackers);


class EditTracker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            equipment_id: false,
        };
    }
    changeDropdown = (equipment_id) => {
        this.setState({
            equipment_id: equipment_id,
        });
    };

    submitForm = async event => {
        event.preventDefault();
        this.setState({
            processing: true
        });
        var self = this;
        axios.defaults.withCredentials = true;
        axios.post(API_ROOT + '/trackers', {tracker_id: self.props.tracker_id, equipment_id: self.state.equipment_id})
            .then(function (response) {
                Swal("Done!", "Tracker Updated", "success");
                self.props.updateTrackers()
                self.setState({
                    processing: false
                });
                self.props.trackers_adjust_toggle(false);
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                    self.setState({
                        processing: false
                    });
                }
            });
    }

    render() {
        var self = this;
        var equip = (self.props.key==false || typeof self.props.trackers[self.props.key]=="undefined" || typeof self.props.equipment[self.props.trackers[self.props.key].equipment_id]=="undefined"?"Unassigned":self.props.equipment[self.props.trackers[self.props.key].equipment_id].name);
        if (this.state.equipment_id!=false){
            equip = self.props.equipment[this.state.equipment_id].name;
        }
        return (
            <>
                <Modal isOpen={this.props.tracker_id!==false} fade={false} toggle={() => {this.props.trackers_adjust_toggle(false)}}>
                    <ModalHeader>
                        <div className="h3 mborder-0">Assign Tracker #{ self.props.name}</div>
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup row>
                            <Label sm={4} lg={3}>Equipment:</Label>
                            <Col>
                                <UncontrolledButtonDropdown className="mr-1">
                                    <DropdownToggle color="secondary" caret style={{fontSize: '13px', width: 'auto'}}>
                                        {equip}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={() => self.changeDropdown(false)}>Unassigned</DropdownItem>
                                        <DropdownItem divider/>
                                        {Object.entries(this.props.equipment_sort).map(([key, equipment]) => {
                                            if (equipment.is_vehicle == 0 && equipment.customer_id == 0) {
                                                return (<DropdownItem key={key}
                                                                      onClick={() => self.changeDropdown(equipment.equipment_id)}>{equipment.name}</DropdownItem>)
                                            }
                                        })}
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col sm={4} lg={3}></Col>
                            <Col>
                                <Button color="primary" onClick={this.submitForm} disabled={(this.state.equipment_id===false)}><i className={(this.state.processing?"fa fa-spinner fa-spin mr-2":"d-none")}></i>Save</Button>
                            </Col>
                        </FormGroup>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}