import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import {Label, Input} from 'reactstrap';
import {API_ROOT} from '../../api-config';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2'

class Login extends Component {

    constructor(props) {
        super(props);

        this.state = {
            email: "",
            password: "",
            remember_me: false,
            hash: "",
            processing:false
        };
    }

    componentDidMount() {
        if (typeof Cookies.get('hash') != "undefined") {
            var self = this;
            this.setState({
                email: Cookies.get('email'),
                hash: Cookies.get('hash'),
                remember_me: true
            }, () => {
                console.log('handleSubmit')
                self.handleSubmit();
            });
        }
    }

    validateForm() {
        return this.state.email.length > 0 && this.state.password.length > 0;
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    toggleRemember = () => {
        this.setState({
            remember_me: !this.state.remember_me
        });
    }

    handleSubmit = async event => {
        if (typeof event != "undefined") {
            event.preventDefault();
        }
        this.setState({
            processing:true
        });
        var self = this;
        axios.defaults.withCredentials = true;
        axios.post(`${API_ROOT}/login`, {withCredentials: true, email: this.state.email, password: this.state.password, hash: this.state.hash})
            .then(function (response) {
                localStorage.setItem('staff_id', response.data.staff_id);
                localStorage.setItem('first_name', response.data.first_name);
                localStorage.setItem('last_name', response.data.last_name);
                localStorage.setItem('permissions', response.data.permissions);
                localStorage.setItem('role_id', JSON.stringify(response.data.role_id));
                if (self.state.remember_me) {
                    Cookies.set('email', self.state.email, {expires: 1000})
                    Cookies.set('hash', response.data.hash, {expires: 1000})
                }
                self.props.refresh_from_local();
                self.props.userHasAuthenticated(true);
                self.props.history.push("/dashboard");
                self.setState({
                    processing:false
                });
            })
            .catch(function (error) {
                self.setState({
                    hash: "",
                    processing:false
                });
                Cookies.remove('hash');
                Swal("Error", error.response.data.Message, "error");
            });
    }

    render() {
        return (
            <div className="block-center mt-4 wd-xl">
                <div className="card card-flat">
                    <div className="card-header text-center bg-dark">
                        <a href="">
                            <img className="block-center rounded" src="img/logo.svg" alt="Logo"/>
                        </a>
                    </div>
                    <div className="card-body">
                        <p className="text-center py-2">STAFF PORTAL</p>
                        <form className="mb-3" id="loginForm" onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                <div className="input-group with-focus">
                                    <input className="form-control border-right-0" id="email" type="email" placeholder="Enter email" autoComplete="off" onChange={this.handleChange}
                                           required/>
                                    <div className="input-group-append">
                                        <span className="input-group-text fa fa-envelope text-muted bg-transparent border-left-0"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="input-group with-focus">
                                    <input className="form-control border-right-0" id="password" type="password" placeholder="Password" onChange={this.handleChange} required/>
                                    <div className="input-group-append">
                                        <span className="input-group-text text-muted bg-transparent border-left-0"><i className="fas fa-lock"></i></span>
                                    </div>
                                </div>
                            </div>
                            <Label className="ml-4">
                                <Input type="checkbox" name="status" value="1"
                                       checked={this.state.remember_me}
                                       onChange={(event) => this.toggleRemember()}/>
                                Remember Me
                            </Label>
                            <button className="btn btn-block btn-primary mt-3" type="submit" disabled={!this.validateForm() || this.state.processing}>
                                <span className={(this.state.processing ? "d-none" : "")}>Sign In</span>
                                <i className={(this.state.processing ? "fa fa-spinner fa-spin" : "d-none")}></i>
                            </button>
                        </form>
                    </div>
                </div>
                <div className="p-3 text-center">
                    <span className="mr-2">&copy;</span>
                    <span>2024</span>
                    <span className="mx-2">-</span>
                    <span>Custom TMS</span>

                </div>
            </div>
        );
    }
}

export default Login;
