import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {
    Button,
    ButtonGroup,
    InputGroup,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    UncontrolledButtonDropdown,
    ModalHeader,
    ModalBody,
    Form,
    FormGroup,
    Label,
    Col,
    Input,
    ModalFooter, Modal
} from 'reactstrap';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import 'moment-timezone';
import TemplateCreate from "./TemplateCreate";
import SingleDatePicker from "react-bootstrap-daterangepicker";
import Select from "react-select";
import {toast} from "react-toastify";


moment.tz.setDefault("America/Halifax");

const days_of_week = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

class TemplatesList extends React.Component {
    constructor(props) {
        super(props);

        this.updateDates = this.updateDates.bind(this);
        this.schedule_modal_toggle = this.schedule_modal_toggle.bind(this);
        this.onCheckboxBtnClick = this.onCheckboxBtnClick.bind(this);

        this.state = {
            loading: true,
            templates: {},
            templates_sort: [],
            schedule_modal: false,
            schedule: {
                schedule_id: false,
                template_id: null,
                startDate: moment(),
                endDate: moment("01 Jan 2100"),
                days: "Mon,Tue,Wed,Thu,Fri,",
                time: "06:00",
                add_days: 0,
            },
            add_days_options: ["Same Day", "Next Day", "In 2 Days", "In 3 Days", "In 4 Days", "In 5 Days", "In 6 Days", "In 1 Week"],
            time_options: [
                "00:00",
                "01:00",
                "02:00",
                "03:00",
                "04:00",
                "05:00",
                "06:00",
                "07:00",
                "08:00",
                "09:00",
                "10:00",
                "11:00",
                "12:00",
                "13:00",
                "14:00",
                "15:00",
                "16:00",
                "17:00",
                "18:00",
                "19:00",
                "20:00",
                "21:00",
                "22:00",
                "23:00",
            ]
        };
    }

    componentDidMount() {
        document.title = "Templates | Jeremie Cormier Trucking Ltd";
        this.updateTemplates();
        console.log(this.state.schedule.add_days)
    }

    updateTemplates = () => {
        var self = this;
        axios.get(API_ROOT + '/loads/templates').then(function (response) {
            var templates = response.data;
            var templates_sort = [];
            for (const template_id of Object.keys(templates)) {
                templates_sort.push({template_id: templates[template_id].template_id, name: templates[template_id].name});
            }
            templates_sort = templates_sort.sort(function (a, b) {
                if (a.name < b.name) {
                    return -1
                } else {
                    return 1
                }
            });
            self.setState({
                templates: templates,
                templates_sort: templates_sort,
                loading: false
            });
        })
    }


    // scheduling

    schedule_modal_toggle = (template_id, schedule) => {
        if (schedule == false) {
            var schedule = {
                schedule_id: false,
                template_id: template_id,
                startDate: moment(),
                endDate: moment("01 Jan 2100"),
                days: "Mon,Tue,Wed,Thu,Fri,",
                add_days: 0,
                time: "06:00"
            }
        } else {
            schedule['startDate'] = moment(schedule['start_date']);
            schedule['endDate'] = moment(schedule['end_date']);
        }
        this.setState({
            schedule: schedule,
            schedule_modal: !this.state.schedule_modal
        }, function () {
        });
    };

    updateDates(event, picker) {
        if (event.type == "apply") {
            var schedule = this.state.schedule;
            schedule['startDate'] = picker.startDate;
            schedule['endDate'] = picker.endDate;
            this.setState({
                schedule: schedule
            });
        }
    }

    updateField = (field, add_days) => {
        var schedule = this.state.schedule;
        schedule[field] = add_days;
        this.setState({
            schedule: schedule
        });
    }

    onCheckboxBtnClick = (selected) => {
        var days = this.state.schedule.days;
        if (days.indexOf(selected) < 0) {
            days = days + "," + selected;
        } else {
            days = days.replace(selected, ',');
        }
        var new_days = "";
        for (var i in days_of_week) {
            var day = days_of_week[i]
            if (days.indexOf(day) >= 0) {
                new_days += day + ",";
            }
        }
        new_days = new_days.slice(0, -1)
        var schedule = this.state.schedule;
        schedule['days'] = new_days;
        this.setState({
            schedule: schedule
        });
    };

    createSchedule = () => {
        var self = this;
        axios.defaults.withCredentials = true;
        axios.post(API_ROOT + '/loads/template/' + this.state.schedule.template_id + '/schedule', this.state.schedule)
            .then(function (response) {
                toast.success("Schedule Saved", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                self.schedule_modal_toggle(null, false);
                self.updateTemplates();
            })
            .catch(function (error) {
                if (typeof error.response != "undefined") {
                    if (error.response.status === 401) {
                        self.props.userSignOut()
                    } else {
                        Swal("Error", error.response.data.Message, "error");
                    }
                }
            });
    }

    editSchedule = () => {
        var self = this;
        axios.defaults.withCredentials = true;
        axios.put(API_ROOT + '/loads/template/' + this.state.schedule.template_id + "/schedule/" + this.state.schedule.schedule_id, this.state.schedule)
            .then(function (response) {
                toast.success("Schedule Saved", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                self.schedule_modal_toggle(null, false);
                self.updateTemplates();
            })
            .catch(function (error) {
                if (typeof error.response != "undefined") {
                    if (error.response.status === 401) {
                        self.props.userSignOut()
                    } else {
                        Swal("Error", error.response.data.Message, "error");
                    }
                }
            });
    }

    deleteSchedule = () => {
        var self = this;
        axios.defaults.withCredentials = true;
        axios.delete(API_ROOT + '/loads/template/' + this.state.schedule.template_id + "/schedule/" + this.state.schedule.schedule_id)
            .then(function (response) {
                self.schedule_modal_toggle(null, false);
                self.updateTemplates();
            })
            .catch(function (error) {
                if (typeof error.response != "undefined") {
                    if (error.response.status === 401) {
                        self.props.userSignOut()
                    } else {
                        Swal("Error", error.response.data.Message, "error");
                    }
                }
            });
    }


    render() {
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>Templates</div>
                </div>
                <div className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                    <div>
                        <div className="card-header">
                            <div className="card-title">{this.state.templates_sort.length} Templates</div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className={(this.state.templates_sort.length > 0 ? "table table-pointer text-right" : "d-none")}>
                                    <thead>
                                    <tr>
                                        <th className="text-left">Name</th>
                                        <th className="text-left">Auto Schedule</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {Object.entries(this.state.templates_sort).map(([key, template]) => {
                                        return (
                                            <tr key={key}>
                                                <td className="text-left"><TemplateCreate {...this.state} template_id={template.template_id}
                                                                                          template={JSON.parse(this.state.templates[template.template_id].params)}
                                                                                          updateTemplates={this.updateTemplates}/></td>
                                                <td className="text-left">
                                                    {this.state.templates[template.template_id].schedule.map((schedule, i) => {
                                                        return (<div className="btn btn-info btn-sm mr-1"
                                                                     onClick={() => this.schedule_modal_toggle(template.template_id, schedule)}>{schedule.days} @ {schedule.time}</div>)
                                                    })}
                                                    <div className="btn btn-light btn-sm" onClick={() => this.schedule_modal_toggle(template.template_id, false)}>+ Add</div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal isOpen={this.state.schedule_modal} fade={false} toggle={() => {
                    this.schedule_modal_toggle(null, false)
                }} size="lg">
                    <ModalHeader>
                        <div className={(this.state.schedule.schedule_id == false ? "h3 mb-0" : "d-none")}>Add Auto Schedule</div>
                        <div className={(this.state.schedule.schedule_id != false ? "h3 mb-0" : "d-none")}>Edit Auto Schedule</div>
                    </ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup row>
                                <Label sm={4}>Template:</Label>
                                <Col sm={8}>
                                    {(this.state.schedule.template_id == null ? "" : this.state.templates[this.state.schedule.template_id].name)}
                                </Col>
                            </FormGroup>
                            <FormGroup className="border-top mt-2 pt-2" row>
                                <Label sm={4}>During This Period:</Label>
                                <Col sm={8}>
                                    <DateRangePicker
                                        startDate={this.state.schedule.startDate}
                                        endDate={this.state.schedule.endDate}
                                        minDate={moment()}
                                        onEvent={this.updateDates}
                                        autoUpdateInput={true}
                                    >
                                        <button type="button" className="btn btn-light selected-date-range-btn mr-1">
                                            {moment(this.state.schedule.startDate).format('MMM. D/YY') + " - " + moment(this.state.schedule.endDate).format('MMM. D/YY')} <span
                                            className="fas fa-caret-down fa-sm"></span>
                                        </button>
                                    </DateRangePicker>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={4}>On These Days:</Label>
                                <Col sm={8}>
                                    <ButtonGroup>
                                        {days_of_week.map((day) => {
                                            return (<Button
                                                color="dark"
                                                outline
                                                onClick={() => this.onCheckboxBtnClick(day)}
                                                active={this.state.schedule.days.includes(day)}
                                            >
                                                {day}
                                            </Button>)
                                        })}
                                    </ButtonGroup>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={4}>At This Time:</Label>
                                <Col sm={8}>
                                    <UncontrolledButtonDropdown className="mr-1">
                                        <DropdownToggle color="light" caret style={{fontSize: '13px', width: 'auto'}}>
                                            {this.state.schedule.time}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            {this.state.time_options.map((key, i) => {
                                                return (<DropdownItem key={i} onClick={() => this.updateField('time', key)}>{key}</DropdownItem>)
                                            })}
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </Col>
                            </FormGroup>
                            <FormGroup className="border-top mt-2 pt-2" row>
                                <Label sm={4}>Create Load For:</Label>
                                <Col sm={8}>
                                    <UncontrolledButtonDropdown className="mr-1">
                                        <DropdownToggle color="light" caret style={{fontSize: '13px', width: 'auto'}}>
                                            {this.state.add_days_options[this.state.schedule.add_days]}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            {this.state.add_days_options.map((key, i) => {
                                                return (<DropdownItem key={i} onClick={() => this.updateField('add_days', i)}>{key}</DropdownItem>)
                                            })}
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </Col>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" className={(this.state.schedule.schedule_id == false ? "d-none" : "mr-auto")} onClick={this.deleteSchedule}><i
                            className={(this.state.processing ? "fa fa-spinner fa-spin mr-2" : "d-none")}></i>Delete</Button>
                        <Button color="primary" className={(this.state.schedule.schedule_id == false ? "d-none" : "")} onClick={this.editSchedule}><i
                            className={(this.state.processing ? "fa fa-spinner fa-spin mr-2" : "d-none")}></i>Save</Button>

                        <Button color="primary" className={(this.state.schedule.schedule_id == false ? "" : "d-none")} onClick={this.createSchedule}><i
                            className={(this.state.processing ? "fa fa-spinner fa-spin mr-2" : "d-none")}></i>Create Auto Schedule</Button>
                    </ModalFooter>
                </Modal>
            </ContentWrapper>
        );
    }
}

export default (TemplatesList);