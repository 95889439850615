import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import ToggleState from '../Common/ToggleState';
import {Link} from 'react-router-dom';
import HeaderRun from './Header.run'
import axios from 'axios';
import {API_ROOT} from "../../api-config";
import EquipmentStatus from "../Equipment/EquipmentFunctions";
import DriverStatus from "../Drivers/DriverFunctions";
import LoadStatus from "../Loads/LoadFunctions"
import {UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, ListGroup, ListGroupItem} from 'reactstrap';

import moment from 'moment';
import 'moment-timezone';

moment.tz.setDefault("America/Halifax");

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search_term: "",
            search_loading: false,
            search_results: {equipment: [], vehicles: [], loads: [], drivers: [], locations: [], term: ""},
            typingTimeout: 0,
            open_messages: false,
        }
    }

    componentDidMount() {
        HeaderRun();
    }

    toggleMessageBox = () => {
        this.setState({
            open_messages: !this.state.open_messages
        });
    }


    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    resize() {
        var evt = document.createEvent('UIEvents');
        evt.initUIEvent('resize', true, false, window, 0);
        window.dispatchEvent(evt);
    }

    enterSearch = (event) => {
        const self = this;

        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout);
        }

        self.setState({
            search_term: event.target.value,
            search_loading: true,
            typingTimeout: setTimeout(function () {
                console.log('searching: ' + self.state.search_term)
                if (self.state.search_term != "") {
                    axios.get(API_ROOT + '/search/' + self.state.search_term)
                        .then(function (response) {
                            if (response.data.term.trim() == self.state.search_term.trim()) { // make sure not delayed response
                                self.setState({
                                    search_results: response.data,
                                    search_loading: false,
                                });
                            }
                        })
                        .catch(function (error) {
                            if (error.response.status === 401) {
                                self.props.userSignOut()
                            } else {
                                self.setState({
                                    search_results: {equipment: [], vehicles: [], loads: [], drivers: [], term: ""},
                                    loading: false
                                });
                            }
                        });
                }
            }, 200)
        });
    };

    goTo = (dir, id) => {
        this.setState({
            search_term: "",
            search_loading: false,
            search_results: {equipment: [], vehicles: [], loads: [], drivers: [], locations: [], term: ""},
        });
        this.props.history.push("/" + dir + "/" + id);
    };

    force_refresh_site = () => {
        window.location.reload(true);
    }

    render() {
        return (
            <header className="topnavbar-wrapper">
                { /* START Top Navbar */}
                <nav className="navbar topnavbar">
                    { /* START navbar header */}
                    <div className="navbar-header">
                        <a className="navbar-brand" href="/dashboard" onClick={() => this.force_refresh_site()} title="Reload application">
                            <div className="brand-logo">
                                <img className="img-fluid" src="img/logo.svg" alt="Custom TMS"/>
                            </div>
                            <div className="brand-logo-collapsed">
                                <img className="img-fluid" src="img/logo-single.svg" alt="Custom TMS"/>
                            </div>
                        </a>
                    </div>
                    { /* END navbar header */}

                    { /* START Left navbar */}
                    <ul className="navbar-nav mr-auto flex-row">
                        <li className="nav-item">
                            { /* Button to show/hide the sidebar on mobile. Visible on mobile only. */}
                            <ToggleState state="aside-toggled" nopersist={true}>
                                <a href="" className="nav-link sidebar-toggle d-md-none">
                                    <em className="fas fa-bars"></em>
                                </a>
                            </ToggleState>
                        </li>
                    </ul>

                    <ul className="navbar-nav flex-row">

                        { /* START Alert menu */}
                        <UncontrolledDropdown nav inNavbar className={(this.props.num_unread == 0 ? "d-none" : "dropdown-list mr-2")}>
                            <DropdownToggle nav className="dropdown-toggle-nocaret" onClick={(() => this.toggleMessageBox())}>
                                <em className="far fa-bell fa-lg"></em>
                                <span className="badge badge-danger fa-bounce">{this.props.num_unread}</span>
                            </DropdownToggle>
                        </UncontrolledDropdown>
                        { /* END Alert menu */}

                        <li className={(this.props.num_unread == 0 ? "nav-item d-block d-md-none" : "d-none")}>
                            <Link to={"/dispatch"} className="nav-link" title="Go to Dispatch">
                                <em className="fas fa-broadcast-tower"></em>
                            </Link>
                        </li>

                        <li className="nav-item d-block d-md-none">
                            <Link to={"/map"} className="nav-link" title="Go to Fleet Map">
                                <em className="fas fa-map-marker-alt"></em>
                            </Link>
                        </li>

                        <li className="nav-item">
                            <a className="nav-link" href="" data-search-open="">
                                <em className="fas fa-search fa-lg"></em>
                            </a>
                        </li>
                        { /* START Search form */}
                        <form className="navbar-form" role="search" action="search.html" autoComplete="off">
                            <div className="form-group">
                                <input id="search_term" className="form-control font-weight-bold search_term" type="text" name="search_term"
                                       placeholder="Search Load, Vehicle, Equipment, or Driver"
                                       style={{"fontSize": "1rem"}} onChange={(event) => this.enterSearch(event)} value={this.state.search_term}/>
                                <div className="fa fa-times navbar-form-close" data-search-dismiss=""></div>
                            </div>
                            <button className="d-none" type="submit">Submit</button>
                        </form>
                        { /* END Search form */}
                    </ul>
                    { /* END Left navbar */}
                </nav>
                { /* END Top Navbar */}
                <div className={(this.state.open_messages ? "p-2" : "d-none")}
                     style={{
                         "background": "#fff",
                         "position": "absolute",
                         "zIndex": "9000",
                         "top": "0px",
                         "marginTop": "55px",
                         "right": "0px",
                         "maxWidth": "250px",
                         "maxHeight": "calc(100vh - 70px)",
                         "transition": "transition: all .3s",
                         "boxShadow": "7px 6px 20px -1px rgba(0,0,0,0.24)",
                         "overflowY": "auto",
                         "borderRadius": "0px 0px 10px 10px"
                     }}>
                    {this.props.messages.list.map((thread, item) => {
                        if (thread.num_unread > 0) {
                            return (
                                <Link to={"/dispatch/" + thread.driver_id} onClick={(() => this.toggleMessageBox())} className="list-group-item-action list-group-item">
                                    <div className="media">
                                        <div className="align-self-start mr-2 mt-1">
                                            <em className="fas fa-envelope fa-2x text-info"></em>
                                        </div>
                                        <div className="media-body">
                                            <p className="m-0">{(thread.name == null ? "Unknown Contact" : thread.name)}</p>
                                            <p className="m-0 text-muted text-sm">Dispatch Message</p>
                                        </div>
                                    </div>
                                </Link>
                            )
                        }
                    })}
                    {this.props.messages_maint.list.map((thread, item) => {
                        if (thread.num_unread > 0) {
                            return (
                                <Link to={"/maint_mssg/" + thread.driver_id} onClick={(() => this.toggleMessageBox())} className="list-group-item-action list-group-item">
                                    <div className="media">
                                        <div className="align-self-start mr-2 mt-1">
                                            <em className="fas fa-envelope fa-2x text-info"></em>
                                        </div>
                                        <div className="media-body">
                                            <p className="m-0">{(thread.name == null ? "Unknown Contact" : thread.name)}</p>
                                            <p className="m-0 text-muted text-sm">Maintenance Message</p>
                                        </div>
                                    </div>
                                </Link>
                            )
                        }
                    })}
                    <Link to={"/dispatch"} onClick={(() => this.toggleMessageBox())} className="list-group-item-action list-group-item">View Dispatch Messages</Link>
                </div>
                <div style={{
                    "background": "#fff",
                    "position": "absolute",
                    "zIndex": "9000",
                    "top": "-1000px",
                    "marginTop": "55px",
                    "left": "0px",
                    "maxWidth": "450px",
                    "maxHeight": "calc(100vh - 70px)",
                    "transition": "transition: all .3s",
                    "boxShadow": "7px 6px 20px -1px rgba(0,0,0,0.24)",
                    "overflowY": "auto",
                    "borderRadius": "0px 0px 10px 10px"
                }} className="w-100 search_results">
                    <div className={(this.state.search_loading && this.state.search_term != "" ? "text-center p-2" : "d-none")}>
                        <i className="fa fa-spinner fa-spin fa-2x"></i>
                    </div>
                    <div
                        className={(!this.state.search_loading && this.state.search_term != "" && this.state.search_results.equipment.length == 0 && this.state.search_results.vehicles.length == 0 && this.state.search_results.loads.length == 0 && this.state.search_results.drivers.length == 0 ? "text-center p-2" : "d-none")}>
                        No matches found
                    </div>
                    <div className={(!this.state.search_loading && this.state.search_results.drivers.length > 0 ? "" : "d-none")}>
                        <div className="text-bold bg-dark text-light p-1 pl-2">DRIVERS</div>
                        {this.state.search_results.drivers.map(function (item, key) {
                            var reg = new RegExp('(' + this.state.search_term + ')', 'gi');
                            const name = item.name.toString().replace(reg, '<b class="text-dark">$1</b>');
                            return (
                                <div key={key} onClick={this.goTo.bind(this, "drivers", item.driver_id)} className="p-1 pl-2 border-top search_row">
                                    <div>
                                        <span dangerouslySetInnerHTML={{__html: name}}></span>
                                        <span className="float-right mr-2">
                                            <DriverStatus status={item.status}/>
                                        </span>
                                    </div>
                                    <div className="small">
                                        {(item.city ? item.city + ", " : "") + ", " + item.province}
                                    </div>
                                </div>
                            )
                        }, this)}
                    </div>
                    <div className={(!this.state.search_loading && this.state.search_results.vehicles.length > 0 ? "" : "d-none")}>
                        <div className="text-bold bg-dark text-light p-1 pl-2">VEHICLES</div>
                        {this.state.search_results.vehicles.map(function (item, key) {
                            var reg = new RegExp('(' + this.state.search_term + ')', 'gi');
                            const name = item.name.toString().replace(reg, '<b class="text-dark">$1</b>');
                            return (
                                <div key={key} onClick={this.goTo.bind(this, "vehicles", item.equipment_id)} className="p-1 pl-2 border-top search_row">
                                    <div>
                                        <span dangerouslySetInnerHTML={{__html: name}}></span>
                                        <span className="float-right mr-2">
                                            <EquipmentStatus status={item.status}/>
                                        </span>
                                    </div>
                                    <div className="small row">
                                        <div className="col">
                                            {item.type}
                                        </div>
                                        <div className="col-7">
                                            {(item.location != "" ? "@ " + item.location : "")}
                                        </div>
                                    </div>
                                </div>
                            )
                        }, this)}
                    </div>
                    <div className={(!this.state.search_loading && this.state.search_results.equipment.length > 0 ? "" : "d-none")}>
                        <div className="text-bold bg-dark text-light p-1 pl-2">EQUIPMENT</div>
                        {this.state.search_results.equipment.map(function (item, key) {
                            var reg = new RegExp('(' + this.state.search_term + ')', 'gi');
                            const name = item.name.toString().replace(reg, '<b class="text-dark">$1</b>');
                            return (
                                <div key={key} onClick={this.goTo.bind(this, "equipment", item.equipment_id)} className="p-1 pl-2 border-top search_row">
                                    <div>
                                        <span dangerouslySetInnerHTML={{__html: name}}></span>
                                        <span className="float-right mr-2">
                                            <EquipmentStatus status={item.status}/>
                                        </span>
                                    </div>
                                    <div className="small row">
                                        <div className="col">
                                            {item.type}
                                        </div>
                                        <div className="col-7">
                                            {(item.location != "" ? "@ " + item.location : "")}
                                        </div>
                                    </div>
                                </div>
                            )
                        }, this)}
                    </div>
                    <div className={(!this.state.search_loading && this.state.search_results.locations.length > 0 ? "" : "d-none")}>
                        <div className="text-bold bg-dark text-light p-1 pl-2">LOCATIONS</div>
                        {this.state.search_results.locations.map(function (item, key) {
                            var reg = new RegExp('(' + this.state.search_term + ')', 'gi');
                            const name = item.name.toString().replace(reg, '<b class="text-dark">$1</b>');
                            return (
                                <div key={key} onClick={this.goTo.bind(this, "location", item.location_id)} className="p-1 pl-2 border-top search_row">
                                    <div>
                                        <span dangerouslySetInnerHTML={{__html: name}}></span>
                                    </div>
                                    <div className="small">
                                        {item.address}, {item.city}, {item.province}
                                    </div>
                                </div>
                            )
                        }, this)}
                    </div>
                    <div className={(!this.state.search_loading && this.state.search_results.loads.length > 0 ? "" : "d-none")}>
                        <div className="text-bold bg-dark text-light p-1 pl-2">LOADS</div>
                        {this.state.search_results.loads.map(function (item, key) {
                            var reg = new RegExp('(' + this.state.search_term + ')', 'gi');
                            const load_id = item.load_id.toString().replace(reg, '<b class="text-dark">$1</b>');
                            return (
                                <div key={key} onClick={this.goTo.bind(this, "loads", item.load_id)} className="p-1 pl-2 border-top search_row">
                                    <div>
                                        #<span dangerouslySetInnerHTML={{__html: load_id}}></span>
                                        <span className="float-right mr-2">
                                            <LoadStatus status={item.status}/>
                                        </span>
                                    </div>
                                    <div className="small row">
                                        <div className="col">
                                            {item.customer}
                                        </div>
                                        <div className="col-7">
                                            {moment(item.date_start).format("MMM Do/YY, H:mm")}
                                        </div>
                                    </div>
                                </div>
                            )
                        }, this)}
                    </div>
                </div>
            </header>
        );
    }

}

export default withRouter(Header);
