import React, {Component} from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {Link} from 'react-router-dom';
import {
    Card,
    CardBody,
    Col,
    Row,
    Input,
    Button,
    DropdownMenu,
    DropdownToggle,
    DropdownItem,
    FormGroup, InputGroupAddon, InputGroup, UncontrolledDropdown
} from 'reactstrap';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import moment from 'moment';
import SingleDatePicker from 'react-bootstrap-daterangepicker';
import 'moment-timezone';

import getCustomers from '../Customers/CustomerFunctions';
import {toast, ToastContainer} from "react-toastify";

moment.tz.setDefault("America/Halifax");

var money = require("money-math");

class InvoiceEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loading_segments: true,
            toggle_all: 1,
            po: null,
            invoice_date: moment(),
            net_days: 30,
            notes_internal: "",
            notes_external: "",
            loads: {},
            manual_items: [{description: "", amount: 0, tax: 0}],
            customers_sort: [],
            customers: {},
            customer_id: null,
            customer_subtotal: 0,
            customer_tax: 0,
            adjustment_allocation_method: 1
        };
        this.updateValue = this.updateValue.bind(this);
    }

    componentDidMount() {
        document.title = "Invoice #" + this.props.match.params.invoice_id + " | Custom TMS";
        var self = this;
        axios.defaults.withCredentials = true;
        getCustomers(function (customers) {
            axios.get(API_ROOT + '/invoice/' + self.props.match.params.invoice_id)
                .then(function (response) {

                    self.setState({
                        loading: false,
                        customers: customers,
                        loads: {},
                        loading_segments: true,
                        po: response.data.po,
                        invoice_date: moment(response.data.invoice_date),
                        net_days: response.data.net_days,
                        notes_internal: response.data.notes_internal,
                        notes_external: response.data.notes_external,
                        manual_items: response.data.manual_items,
                        customer_id: response.data.customer_id,
                        customer_subtotal: response.data.subtotal,
                        customer_tax: response.data.tax
                    });

                    axios.get(API_ROOT + '/loads/?customer_id=' + response.data.customer_id + "&status=3&invoice_id="+self.props.match.params.invoice_id)
                        .then(function (response2) {
                            var loads = response2.data;
                            for (var id in loads) {
                                loads[id].checked = -1;
                                if (loads[id].invoice_id == self.props.match.params.invoice_id) {
                                    loads[id].checked = 1;
                                }
                            }
                            self.setState({
                                loads: loads,
                                loading_segments: false
                            });
                        })

                })
        });
    }

    updateLine = (line_num, event) => {
        var name = event.target.name;
        var value = event.target.value;
        var manual_items = this.state.manual_items;
        manual_items[line_num][name] = value;
        this.setState({
            manual_items: manual_items
        });
    };

    updateTenderDate = (event, picker) => {
        if (event.type == "apply") {
            this.setState({
                invoice_date: moment(picker.startDate),
            });
        }
    }

    toggleCheck = (load_id) => {
        var loads = this.state.loads;
        for (var id in loads) {
            if (load_id == loads[id].load_id) {
                loads[id].checked = loads[id].checked * -1;
                if (loads[id].checked != 1) {
                    loads[id].reverse_billing_adjustment = 0;
                    loads[id].reverse_billing_tax_adjustment = 0;
                }
            }
        }
        this.setState({
            loads: loads,
        });
    }

    toggleCheckAll = () => {
        var loads = this.state.loads;
        for (var id in loads) {
            loads[id].checked = this.state.toggle_all * -1;
            if (loads[id].checked != 1) {
                loads[id].reverse_billing_adjustment = 0;
                loads[id].reverse_billing_tax_adjustment = 0;
            }
        }
        this.setState({
            loads: loads,
            toggle_all: this.state.toggle_all * -1
        });
    }

    updateLoad = (load_id, event) => {
        var loads = this.state.loads;
        if (event.target) {
            event.preventDefault();
            event.stopPropagation();
        }
        var name = event.target.name
        var value = event.target.value
        loads[load_id][name] = value;
        this.setState({
            loads: loads
        });
    }

    updateValue(event, name, value) {
        if (event.target) {
            event.preventDefault();
            event.stopPropagation();
        }
        if (typeof name == "undefined") {
            name = event.target.name
        }
        if (typeof value == "undefined") {
            value = event.target.value
        }
        this.setState({
            [name]: value
        });
    }

    applyAdjustments = () => {
        var total_subtotal = money.floatToAmount(0);
        var total_tax = money.floatToAmount(0);
        var num_checked = 0;
        var loads = this.state.loads;
        for (var id in loads) {
            var load = loads[id];
            if (load.checked == 1) {
                num_checked++;
                total_subtotal = money.add(total_subtotal, money.floatToAmount(load.total_subtotal));
                total_subtotal = money.add(total_subtotal, money.floatToAmount(load.reverse_billing_adj));
                total_tax = money.add(total_tax, money.floatToAmount(load.total_tax));
                total_tax = money.add(total_tax, money.floatToAmount(load.reverse_billing_tax_adj));
            }
        }
        var subtotal_diff = money.subtract(money.floatToAmount(this.state.customer_subtotal), total_subtotal);
        var tax_diff = money.subtract(money.floatToAmount(this.state.customer_tax), total_tax);
        var subtotal_adjustments_applied = money.floatToAmount(0);
        var tax_adjustments_applied = money.floatToAmount(0);
        var last_id = 0;
        for (var id in loads) {
            var load = loads[id];
            if (load.checked == 1) {
                last_id = id;
                if (this.state.adjustment_allocation_method == 1) { //evenly
                    var reverse_billing_adj = money.div(subtotal_diff, money.floatToAmount(num_checked));
                    var reverse_billing_tax_adj = money.div(tax_diff, money.floatToAmount(num_checked));
                } else { // proportional
                    var reverse_billing_adj = money.mul(subtotal_diff, money.div(load.total_subtotal, total_subtotal));
                    var reverse_billing_tax_adj = money.mul(tax_diff, money.div(load.total_tax, total_tax));
                }
                loads[id].reverse_billing_adj = money.add(money.floatToAmount(loads[id].reverse_billing_adj), reverse_billing_adj);
                loads[id].reverse_billing_tax_adj = money.add(money.floatToAmount(loads[id].reverse_billing_tax_adj), reverse_billing_tax_adj);
                subtotal_adjustments_applied = money.add(subtotal_adjustments_applied, reverse_billing_adj);
                tax_adjustments_applied = money.add(tax_adjustments_applied, reverse_billing_tax_adj);
            }
        }
        // if there is a balance remaining, probably a few cents, apply it to the last load
        if (!money.isEqual(subtotal_adjustments_applied, subtotal_diff)) {
            loads[last_id].reverse_billing_adj = money.add(reverse_billing_adj, money.subtract(subtotal_diff, subtotal_adjustments_applied));
        }
        if (!money.isEqual(tax_adjustments_applied, tax_diff)) {
            loads[last_id].reverse_billing_tax_adj = money.add(reverse_billing_tax_adj, money.subtract(tax_diff, tax_adjustments_applied));
        }
        this.setState({
            loads: loads,
        });
    }

    addLine = () => {
        var manual_items = this.state.manual_items;
        manual_items.push({description: "", amount: 0, tax: 0});
        this.setState({
            manual_items: manual_items
        });
    }

    saveInvoice = () => {
        if (this.state.customer_id > 0) {
            var self = this;
            axios.defaults.withCredentials = true;
            axios.post(API_ROOT + '/invoice/'+this.props.match.params.invoice_id, this.state)
                .then(function (response) {
                    self.props.history.push("/invoices/" + self.props.match.params.invoice_id);
                    toast.success("Invoice Saved", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                })
                .catch(function (error) {
                    if (typeof error.response != "undefined") {
                        if (error.response.status === 401) {
                            self.props.userSignOut()
                        } else {
                            Swal("Error", error.response.data.Message, "error");
                        }
                    }
                });
        }
    }

    render() {
        var subtotal_loads = money.floatToAmount(0);
        var tax_loads = money.floatToAmount(0);
        var tax_loads_adj = money.floatToAmount(0);
        var total_loads = money.floatToAmount(0);
        var subtotal_loads_adj = money.floatToAmount(0);
        var subtotal_manual = money.floatToAmount(0);
        var tax_manual = money.floatToAmount(0);
        var total_manual = money.floatToAmount(0);
        var miles = 0;
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>
                        <Link to="../invoices">Invoices</Link>&nbsp;/&nbsp; <Link to={"../"+this.props.match.params.invoice_id}>Invoice # {this.props.match.params.invoice_id}</Link>&nbsp;/&nbsp; Edit
                    </div>
                </div>
                <Card className="card card-default">
                    <CardBody>
                        <form className="form-horizontal">
                            <div className="row">
                                <Col>
                                    <FormGroup row>
                                        <Col lg="4" sm="6" className="text-bold">Invoice Date:</Col>
                                        <Col lg="8" sm="6">
                                            <SingleDatePicker
                                                onEvent={this.updateTenderDate}
                                                date={(this.state.invoice_date == null ? moment() : this.state.invoice_date)}
                                                singleDatePicker={true}
                                                autoUpdateInput={true}
                                            >
                                                <div className="btn btn-light btn-sm">
                                                    {(this.state.invoice_date == null ? '(select)' : this.state.invoice_date.format('MMM. D, YYYY'))}
                                                </div>
                                            </SingleDatePicker>
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup row>
                                        <Col lg="4" sm="6" className="text-bold">Customer:</Col>
                                        <Col lg="8" sm="6">
                                            {(this.state.customer_id == null ? "--" : this.state.customers[this.state.customer_id].name)}
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </div>
                            <div className="row">
                                <Col>
                                    <FormGroup row>
                                        <Col lg="4" sm="6" className="text-bold">Net Days Payable:</Col>
                                        <Col lg="8" sm="6">
                                            <Input type="text" name="net_days" value={this.state.net_days} onChange={this.updateValue}/>
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup row>
                                        <Col lg="4" sm="6" className="text-bold">PO #:</Col>
                                        <Col lg="8" sm="6">
                                            <Input type="text" name="po" value={this.state.po} onChange={this.updateValue}/>
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </div>
                            <div className="row">
                                <Col>
                                    <FormGroup row>
                                        <Col lg="4" sm="6" className="text-bold">Notes to Customer:</Col>
                                        <Col lg="8" sm="6">
                                            <Input type="textarea" name="notes_external" style={{height: '80px'}} onChange={this.updateValue}
                                                   value={this.state.notes_external}></Input>
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup row>
                                        <Col lg="4" sm="6" className="text-bold">Internal Notes:</Col>
                                        <Col lg="8" sm="6">
                                            <Input type="textarea" name="notes_internal" style={{height: '80px'}} onChange={this.updateValue}
                                                   value={this.state.notes_internal}></Input>
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </div>
                            <FormGroup row className="pt-2 pb-2">
                                <Col lg="2" sm="3" className="text-bold">Loads to Submit:</Col>
                                <Col lg="10" sm="9">
                                    <div className={(this.state.customer_id == null ? "alert alert-danger" : "d-none")}>Please select a customer</div>
                                    <div
                                        className={(this.state.customer_id > 0 && this.state.loading_segments == false && Object.keys(this.state.loads).length == 0 ? "alert alert-warning" : "d-none")}>There
                                        are no loads marked as "completed" for this customer
                                    </div>
                                    <table
                                        className={(this.state.customer_id == null || Object.keys(this.state.loads).length == 0 ? "d-none" : (this.state.loading_segments ? "table table-sm whirl traditional" : "table table-sm"))}>
                                        <thead>
                                        <tr className="thead-light">
                                            <th><i className="far fa-check-square" onClick={() => this.toggleCheckAll()}></i></th>
                                            <th>Load #</th>
                                            <th>Date</th>
                                            <th className="text-right">Miles</th>
                                            <th className="text-right border-left">Subtotal</th>
                                            <th className="text-right">Reverse Billing Subtotal Adj.</th>
                                            <th className="text-right border-left">Tax</th>
                                            <th className="text-right">Reverse Billing Tax Adj.</th>
                                            <th className="text-right border-left">Total</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {Object.entries(this.state.loads).map(([key, load]) => {
                                            var load_id = load.load_id;
                                            if (load.checked == 1) {
                                                subtotal_loads = money.add(subtotal_loads, money.floatToAmount(load.total_subtotal));
                                                tax_loads = money.add(tax_loads, money.floatToAmount(load.total_tax));
                                                tax_loads_adj = money.add(tax_loads_adj, money.floatToAmount(load.reverse_billing_tax_adj));
                                                subtotal_loads_adj = money.add(subtotal_loads_adj, money.floatToAmount(load.reverse_billing_adj));
                                                total_loads = money.add(total_loads, money.floatToAmount(load.total_total));
                                                total_loads = money.add(total_loads, money.floatToAmount(load.reverse_billing_tax_adj));
                                                total_loads = money.add(total_loads, money.floatToAmount(load.reverse_billing_adj));
                                                miles = (miles + load.miles);
                                            }
                                            return (
                                                <tr key={key}>
                                                    <td className={(load.checked == 1 ? "bg-light" : "")} onClick={() => this.toggleCheck(load_id)}>
                                                        <Input type="checkbox" className="ml-0" checked={(load.checked == 1)}/>
                                                    </td>
                                                    <td className={(load.checked == 1 ? "bg-light" : "")}>
                                                        <Link to={"../../loads/" + load_id}>
                                                            {load_id}
                                                        </Link>
                                                    </td>
                                                    <td className={(load.checked == 1 ? "bg-light" : "")}>
                                                        {moment(load.start).format('MMM. D/YY')}
                                                    </td>
                                                    <td className={(load.checked == 1 ? "bg-light text-right" : "text-right")}>
                                                        {money.format("USD", money.floatToAmount(load.miles))}
                                                    </td>
                                                    <td className={(load.checked == 1 ? "bg-light text-right border-left" : "text-right border-left")}>
                                                        ${money.format("USD", money.floatToAmount(load.total_subtotal))}
                                                    </td>
                                                    <td className={(load.checked == 1 ? "bg-light text-right" : "text-right")}>
                                                        <InputGroup size="sm">
                                                            <InputGroupAddon addonType="append">$</InputGroupAddon>
                                                            <Input type="text" name="reverse_billing_adj" className="text-right" disabled={load.checked != 1}
                                                                   value={load.reverse_billing_adj}
                                                                   onChange={(e) => this.updateLoad(load_id, e)}/>
                                                        </InputGroup>
                                                    </td>
                                                    <td className={(load.checked == 1 ? "bg-light text-right border-left" : "text-right border-left")}>
                                                        ${money.format("USD", money.floatToAmount(load.total_tax))}
                                                    </td>
                                                    <td className={(load.checked == 1 ? "bg-light text-right" : "text-right")}>
                                                        <InputGroup size="sm">
                                                            <InputGroupAddon addonType="append">$</InputGroupAddon>
                                                            <Input type="text" name="reverse_billing_tax_adj" className="text-right" disabled={load.checked != 1}
                                                                   value={load.reverse_billing_tax_adj}
                                                                   onChange={(e) => this.updateLoad(load_id, e)}/>
                                                        </InputGroup>
                                                    </td>
                                                    <td className={(load.checked == 1 ? "bg-light text-right border-left" : "text-right border-left")}>
                                                        ${money.format("USD", money.floatToAmount(load.total_total))}
                                                    </td>
                                                </tr>)
                                        }, this)}
                                        <tr>
                                            <th colSpan="3" className="text-right">
                                                Selected Total:
                                            </th>
                                            <th className="text-right">
                                                {money.format("USD", money.floatToAmount(miles))}
                                            </th>
                                            <th className="text-right border-left">
                                                ${money.format("USD", subtotal_loads)}
                                            </th>
                                            <th className="text-right">
                                                ${money.format("USD", subtotal_loads_adj)}
                                            </th>
                                            <th className="text-right border-left">
                                                ${money.format("USD", tax_loads)}
                                            </th>
                                            <th className="text-right">
                                                ${money.format("USD", tax_loads_adj)}
                                            </th>
                                            <th className="text-right border-left">
                                                <b>${money.format("USD", total_loads)}</b>
                                            </th>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <div className={(this.state.customer_id == null ? "d-none" : "card bg-light mt-4 border")}>
                                        <div className="p-2">
                                            <h4>Reverse-Billing Reconciliation <small>(optional)</small></h4>
                                            <div className="alert alert-info pt-1 pb-1 mb-2">If this is a reverse-bill invoice, please first select all related loads above
                                                corresponding to the invoice/PO
                                            </div>
                                            <table className="table table-bordered table-sm bg-light">
                                                <thead className="thead-light">
                                                <tr>
                                                    <th></th>
                                                    <th>Subtotal</th>
                                                    <th>Tax</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td>Customer-Provided Total</td>
                                                    <td>
                                                        <InputGroup size="sm">
                                                            <InputGroupAddon addonType="append">$</InputGroupAddon>
                                                            <Input type="text" name="customer_subtotal" className="text-right"
                                                                   value={this.state.customer_subtotal}
                                                                   onChange={this.updateValue}/>
                                                        </InputGroup>
                                                    </td>
                                                    <td>
                                                        <InputGroup size="sm">
                                                            <InputGroupAddon addonType="append">$</InputGroupAddon>
                                                            <Input type="text" name="customer_tax" className="text-right"
                                                                   value={this.state.customer_tax}
                                                                   onChange={this.updateValue}/>
                                                        </InputGroup>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Greenhaven+Adusted Total</td>
                                                    <td className="text-right">${money.format("USD", money.add(subtotal_loads, subtotal_loads_adj))}</td>
                                                    <td className="text-right">${money.format("USD", money.add(tax_loads, tax_loads_adj))}</td>
                                                </tr>
                                                <tr className={(this.state.customer_tax == 0 && this.state.customer_subtotal == 0 ? "d-none" : "")}>
                                                    <td>=Adjustments Remaining to Apply</td>
                                                    <th className="text-right">${money.format("USD", money.subtract(money.floatToAmount(this.state.customer_subtotal), money.add(subtotal_loads, subtotal_loads_adj)))}</th>
                                                    <th className="text-right">${money.format("USD", money.subtract(money.floatToAmount(this.state.customer_tax), money.add(tax_loads, tax_loads_adj)))}</th>
                                                </tr>
                                                </tbody>
                                            </table>
                                            <div className={(this.state.customer_tax == 0 && this.state.customer_subtotal == 0 ? "d-none" : "mt-2")}>
                                                <UncontrolledDropdown group>
                                                    <DropdownToggle color="secondary" caret style={{fontSize: '13px', width: 'auto'}}>
                                                        {(this.state.adjustment_allocation_method == 1 ? "Apply adjustments evenly" : "Apply adjustments proportionately")}
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem key={1} onClick={() => this.updateValue(false, 'adjustment_allocation_method', 1)}>Apply adjustments
                                                            evenly</DropdownItem>
                                                        <DropdownItem key={2} onClick={() => this.updateValue(false, 'adjustment_allocation_method', 2)}>Apply adjustments
                                                            proportionately</DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </div>
                                            <Button color="info" className={(this.state.customer_tax == 0 && this.state.customer_subtotal == 0 ? "d-none" : "mt-2")}
                                                    onClick={() => this.applyAdjustments()}>Apply Adjustments to Loads</Button>
                                        </div>
                                    </div>
                                </Col>
                            </FormGroup>

                            <Row className="pt-2 pb-2">
                                <Col lg="2" sm="3" className="text-bold">Manual Item(s):</Col>
                                <Col lg="10" sm="9">
                                    <table className="table table-sm">
                                        <thead>
                                        <tr className="thead-light">
                                            <th>Description</th>
                                            <th>Subtotal</th>
                                            <th>Tax</th>
                                            <th>Total</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.manual_items.map((line, key) => {
                                            if (typeof line.amount=="undefined"){
                                                line.amount = line.subtotal;
                                            }
                                            var total = money.add(money.floatToAmount(line.amount), money.floatToAmount(line.tax));
                                            subtotal_manual = money.add(subtotal_manual, money.floatToAmount(line.amount));
                                            tax_manual = money.add(tax_manual, money.floatToAmount(line.tax));
                                            total_manual = money.add(total_manual, total);
                                            return (
                                                <tr key={key}>
                                                    <td>
                                                        <Input type="text" size="sm" name="description" value={line.description} onChange={(event) => this.updateLine(key, event)}/>
                                                    </td>
                                                    <td>
                                                        <InputGroup size="sm">
                                                            <InputGroupAddon size="sm" addonType="append">$</InputGroupAddon>
                                                            <Input size="sm" type="text" name="amount" className="text-right" placeholder="0"
                                                                   value={(line.amount == 0 ? "" : line.amount)} onChange={(event) => this.updateLine(key, event)}/>
                                                        </InputGroup>
                                                    </td>
                                                    <td>
                                                        <InputGroup size="sm">
                                                            <InputGroupAddon size="sm" addonType="append">$</InputGroupAddon>
                                                            <Input size="sm" type="text" name="tax" className="text-right" placeholder="0" value={(line.tax == 0 ? "" : line.tax)}
                                                                   onChange={(event) => this.updateLine(key, event)}/>
                                                        </InputGroup>
                                                    </td>
                                                    <td className="text-right">
                                                        ${money.format("USD", total)}
                                                    </td>
                                                </tr>)
                                        }, this)}
                                        </tbody>
                                        <tfoot>
                                        <tr>
                                            <th className="text-right">
                                                <div className="btn btn-xs btn-info float-left" onClick={this.addLine}>
                                                    + Line
                                                </div>
                                                Manual Total:
                                            </th>
                                            <th className="text-right">${money.format("USD", subtotal_manual)}</th>
                                            <th className="text-right">${money.format("USD", tax_manual)}</th>
                                            <th className="text-right">${money.format("USD", total_manual)}</th>
                                        </tr>
                                        </tfoot>
                                    </table>
                                </Col>
                            </Row>
                            <Row className="pt-3 pb-3">
                                <Col lg="2" sm="3" className="text-bold">Invoice Total:</Col>
                                <Col lg="10" sm="9">
                                    <Row><Col sm="4" md="3"
                                              lg="2">Subtotal:</Col><Col>${money.format("USD", money.add(money.add(subtotal_loads, subtotal_loads_adj), subtotal_manual))}</Col></Row>
                                    <Row><Col sm="4" md="3" lg="2">Tax:</Col><Col>${money.format("USD", money.add(money.add(tax_loads, tax_loads_adj), tax_manual))}</Col></Row>
                                    <Row className="text-bold text-success"><Col sm="3" md="2">Total
                                        Due:</Col><Col>${money.format("USD", money.add(total_loads, total_manual))}</Col></Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="2" sm="3"></Col>
                                <Col lg="10" sm="9">
                                    <Button color="primary" className={(this.state.customer_id == null ? "d-none" : "")} onClick={this.saveInvoice}>Save Invoice</Button>
                                </Col>
                            </Row>
                        </form>
                    </CardBody>
                </Card>
                <ToastContainer/>
            </ContentWrapper>
        );
    }
}

export default InvoiceEdit;
