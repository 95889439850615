import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import 'moment-timezone';
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown} from "reactstrap";
import axios from "axios";
import {API_ROOT} from "../../api-config";
import Swal from "sweetalert2";

var money = require("money-math");

moment.tz.setDefault("America/Halifax");

class Ifta extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            year: moment().format('YYYY'),
            quarter: moment().quarter(),
            total_km:0,
            total_litres:0,
            regions:{},
            rates:{}
        };
    }

    componentDidMount() {
        document.title = "IFTA Report | Jeremie Cormier Trucking Ltd"
        this.triggerSearch();
    }

    triggerSearch() {
        var self = this;
        axios.defaults.withCredentials = true;
        axios.get(API_ROOT + "/ifta/" + self.state.year + "/" + self.state.quarter)
            .then(function (response) {
                self.setState({
                    total_km: response.data.total_km,
                    total_litres: response.data.total_litres,
                    regions: response.data.regions,
                    rates: response.data.rates
                });
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
            });
    }

    updateValue(year, quarter) {
        var self = this;
        this.setState({
            year: year,
            quarter: quarter
        }, () => {
            self.triggerSearch();
        });
    }

    export = () => {
        /*var currentdate = new Date();
        var csv = 'Truck,Driver,Date,Location,Idle Time, Idle Cost,Idle Start,Idle End\n';
        for (var i = 0; i < this.state.results.length; i++) {
            var row = this.state.results[i];
            csv += '"' + this.state.vehicles[row.vehicle_id].name + '","' + (row.driver == null ? "" : this.state.drivers[row.driver].first_name + " " + this.state.drivers[row.driver].last_name) + '","' + moment.unix(row.idle_start).format("MMM D") + '","' + row.location + '","' + row.idle_mins + '","$' + money.format("USD", money.floatToAmount(row.idle_mins * 10 / 60)) + '","' + moment.unix(row.idle_start).format("HH:mm") + '","' + moment.unix(row.idle_end).format("HH:mm") + '"\n';
        }
        const blob = new Blob([csv], {type: "text/csv"});
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.download = "Idle Times.csv";
        link.href = url;
        link.click();*/
    }

    render() {
        var quarters = [];
        for (let year = 2024; year<=moment().format('YYYY'); year++) {
            quarters.push({year: year, quarter:1});
            quarters.push({year: year, quarter:2});
            quarters.push({year: year, quarter:3});
            quarters.push({year: year, quarter:4});
        }
        var total_due = 0;

        return (
            <ContentWrapper>
                <div className="content-heading">
                    IFTA Report
                </div>
                <div className="mb-3 form-inline" style={{zIndex: 1000}}>
                    <UncontrolledButtonDropdown className="mr-1">
                        <DropdownToggle color="primary" caret style={{fontSize: '13px', width: 'auto'}}>
                            Q{this.state.quarter} {this.state.year}
                        </DropdownToggle>
                        <DropdownMenu>
                            {quarters.map((i, key) => {
                                return (<DropdownItem key={key}
                                                      onClick={() => this.updateValue(quarters[key].year, quarters[key].quarter)}>Q{quarters[key].quarter} {quarters[key].year}</DropdownItem>)
                            })}
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
                <div className="card card-default">
                    <div>
                        <div className="card-header">
                            <div className="btn btn-secondary btn-xs float-right mr-1" onClick={this.export}>
                                <i className="fas fa-download"></i> Export
                            </div>
                            <h3>Q{this.state.quarter} {this.state.year}</h3>
                        </div>
                        <div className={(typeof this.state.rates['NB']=="undefined"?'alert alert-warning':'d-none')}>IFTA rates for this period are not available yet</div>
                        <div className={(typeof this.state.rates['NB']=="undefined"?'d-none':'card-body pb-0')}>
                            Total KMs travelled in all Jurisdictions: <b>{Math.round(this.state.total_km).toLocaleString()}</b><br/>
                            Total Fuel Consumed in all Jurisdictions: <b>{Math.round(this.state.total_litres).toLocaleString()}</b><br/>
                            KMs per Litre (KPL): <b>{(this.state.total_km/this.state.total_litres).toFixed(2)}</b>
                            <div className="table-responsive">
                                <table className="table table-hover table-pointer text-right">
                                    <thead>
                                        <tr>
                                            <th>Jurisdiction</th>
                                            <th>Tax Rate</th>
                                            <th>Total KMs</th>
                                            <th>Total Taxable KMs</th>
                                            <th>Taxable Litres</th>
                                            <th>Tax Paid Litres Purchased</th>
                                            <th>Net Taxable Litres</th>
                                            <th>Tax Due</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.entries(this.state.regions).map(([province, row]) => {
                                            var rate = (typeof this.state.rates[province]=="undefined"?0:this.state.rates[province]);
                                            var kpl = this.state.total_km/this.state.total_litres;
                                            var taxable_litres = row.km/kpl;
                                            var net_litres =taxable_litres-row.litres;
                                            var due = (net_litres*rate);
                                            total_due = total_due + due;
                                            return (
                                                <tr key={province}>
                                                    <td>{province}</td>
                                                    <td>${rate}</td>
                                                    <td>{Math.round(row.km).toLocaleString()}</td>
                                                    <td>{Math.round(row.km).toLocaleString()}</td>
                                                    <td>{Math.round(taxable_litres).toLocaleString()}</td>
                                                    <td>{(row.litres).toFixed(2)}</td>
                                                    <td>{net_litres.toFixed(2)}</td>
                                                    <td>${due.toFixed(2)}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th className="text-right" colspan="7">Total Tax Due:</th>
                                            <th>${total_due.toFixed(2)}</th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export default (Ifta);
