import React, {Component} from 'react';
import {
    Button,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    DropdownMenu,
    Form,
    FormGroup,
    DropdownItem,
    DropdownToggle,
    UncontrolledButtonDropdown,
    Label,
    Input, InputGroupAddon, InputGroup
} from 'reactstrap';
import {getDrivers} from "../Drivers/DriverFunctions";
import {getEquipment} from "../Equipment/EquipmentFunctions";
import 'react-dropzone-uploader/dist/styles.css'

import axios from 'axios';
import {API_ROOT} from '../../api-config';

import Swal from 'sweetalert2'

var money = require("money-math");

var default_details = {
    fillup_id: null,
    equipment: "(select vehicle)",
    driver: "(select driver)",
    equipment_id: null,
    fill_date: null,
    odometer: "",
    litres: "",
    price_per_litre: "",
    fuel_tax: 0,
    total_fuel: 0,
    def_litres: "",
    def_ppl: "",
    def_tax: "",
    total_def: 0,
    total: 0,
    location: "",
    chain: "(select chain)",
    driver_id: null,
}

class AddFillup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            fillup_id: null,
            details: default_details,
            chains: ["Irving", "Shell", "Petro Canada", "Esso", "Ultramar", "Mobil", "Harnois", "Other"],
            drivers: {},
            drivers_sort: [],
            processing: false,
            equipment: {},
            equipment_sort: []
        };
        this.updateValue = this.updateValue.bind(this);
    }

    componentDidMount() {
        var self = this;
        getEquipment(0, function (equipment) {
            getDrivers(function (drivers) {
                var details = self.state.details;
                details['equipment_id'] = self.props.equipment_id;
                var drivers_sort = [];
                for (const driver_id of Object.keys(drivers)) {
                    var obj = drivers[driver_id];
                    obj['name'] = obj.first_name + ' ' + obj.last_name;
                    drivers_sort.push(obj)
                }
                var drivers_sort = drivers_sort.sort(function (a, b) {
                    if (a.name < b.name) {
                        return -1
                    } else {
                        return 1
                    }
                });
                var equipment_sort = [];
                for (const equipment_id of Object.keys(equipment)) {
                    var obj = equipment[equipment_id];
                    equipment_sort.push(obj)
                }
                var equipment_sort = equipment_sort.sort(function (a, b) {
                    if (a.name < b.name) {
                        return -1
                    } else {
                        return 1
                    }
                });
                self.setState({
                    equipment: equipment,
                    equipment_sort: equipment_sort,
                    details: details,
                    drivers: drivers,
                    drivers_sort: drivers_sort
                })
            });
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (typeof this.props.viewFillup != "undefined" && prevProps.viewFillup !== this.props.viewFillup && this.props.viewFillup.fillup_id != null) {
            var details = this.props.viewFillup;
            details['driver'] = this.state.drivers[details.driver_id].name;
            console.log(details)
            this.setState({
                details: details,
                modal: true
            });
        }
        if (typeof this.props.equipment_id != "undefined" && prevProps.equipment_id !== this.props.equipment_id && this.props.equipment_id != null) {
            var details = this.state.details;
            details['equipment_id'] = this.props.equipment_id;
            this.setState({
                details: details
            });
        }
    }

    toggle = () => {
        this.setState({
            details: default_details,
            modal: !this.state.modal
        });
    };

    calculateTotal = () => {
        var total_fuel = money.mul(money.floatToAmount(this.state.details.litres), money.floatToAmount(this.state.details.price_per_litre));
        var total_tax_fuel = money.floatToAmount(this.state.details.fuel_tax);
        var total_def = money.mul(money.floatToAmount(this.state.details.def_litres), money.floatToAmount(this.state.details.def_ppl));
        var total = money.add(total_fuel, total_def);
        var details = this.state.details;
        details['total_fuel'] = total_fuel;
        details['total_tax_fuel'] = total_tax_fuel;
        details['total_def'] = total_def;
        details['total'] = total;
        this.setState({
            details: details
        });
    };

    updateValue(event, name, value) {
        if (event.target) {
            event.preventDefault();
            event.stopPropagation();
        }
        if (typeof name == "undefined") {
            name = event.target.name
        }
        if (typeof value == "undefined") {
            value = event.target.value
        }
        var details = this.state.details;
        details[name] = value;
        this.setState({
            details: details
        }, function () {
            if (name != "page") {
                this.calculateTotal()
            }
        });
    }

    changeEquipment = (equipment_id) => {
        var details = this.state.details;
        details['equipment'] = this.state.equipment[equipment_id].name;
        details['equipment_id'] = equipment_id;
        this.setState({
            details: details
        });
    }

    changeDriver = (driver_id) => {
        var details = this.state.details;
        details['driver'] = this.state.drivers[driver_id].name;
        details['driver_id'] = driver_id;
        this.setState({
            details: details
        });
    }

    changeChain = (chain) => {
        var details = this.state.details;
        details['chain'] = chain;
        this.setState({
            details: details
        });
    }

    AddFillup = async event => {
        event.preventDefault()
        var error = "";
        if (this.state.details.chain == "(select chain)") {
            error = "Please select the gas station chain";
        } else if (this.state.details.location == "") {
            error = "Please enter the location address";
        } else if (this.state.details.odometer == "" || this.state.details.odometer == 0) {
            error = "Enter the current odometer reading in kilometers";
        } else if (this.state.details.litres == "" || this.state.details.litres == 0) {
            error = "Enter the number of litres of fuel";
        } else if (this.state.details.price_per_litre == "" || this.state.details.price_per_litre == 0) {
            error = "Enter the price per litre of fuel";
        } else if (this.state.details.fuel_tax == "" || this.state.details.fuel_tax == "") {
            error = "Enter the tax that is included in the price per litre. If none is applicable, enter 0.";
        }
        if (error != "") {
            Swal("Error", error, "error");
        } else {
            this.setState({
                processing: true
            });
            var self = this;
            axios.defaults.withCredentials = true;
            axios.post(API_ROOT + '/fillups', this.state.details)
                .then(function (response) {
                    self.setState({
                        modal: false
                    });
                    Swal("Success!", "Fill-up has been added", "success");
                    if (self.props.refreshFillup != null) {
                        self.props.refreshFillup(response.data.fillup_id);
                    }
                })
                .catch(function (error) {
                    if (typeof error.response != "undefined") {
                        if (error.response.status === 401) {
                            self.props.userSignOut()
                        } else {
                            Swal("Error", error.response.data.Message, "error");
                            self.setState({
                                processing: false
                            });
                        }
                    }
                });
        }
    };

    render() {
        return (
            <>
                <Button className={this.props.size == null ? "mb-2" : "mt-2"} color="primary" size={this.props.size == null ? "xs" : this.props.size} onClick={this.toggle}><i
                    className="fas fa-plus"></i> Fill-Up</Button>
                <FormGroup>
                    <Modal isOpen={this.state.modal} toggle={this.toggle} size="lg">
                        <ModalHeader>
                            <div className={(this.state.details.fillup_id > 0 ? "d-none" : "h3 mb-0")}>Record Fill-Up</div>
                            <div className={(this.state.details.fillup_id > 0 ? "h3 mb-0" : "d-none")}>Fill-Up # {this.state.details.fillup_id}</div>
                        </ModalHeader>
                        <ModalBody>
                            <Form>
                                <FormGroup row className={(typeof this.props.equipment_id != "undefined" ? "d-none" : "mb-2")}>
                                    <Label sm={4}>Vehicle:</Label>
                                    <Col sm={8}>
                                        <UncontrolledButtonDropdown className="mr-1">
                                            <DropdownToggle disabled={this.state.details.fillup_id > 0} color="secondary" caret style={{fontSize: '13px', width: 'auto'}}>
                                                {this.state.details.equipment}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {this.state.equipment_sort.map((equipment, i) => {
                                                    return (<DropdownItem key={equipment.equipment_id}
                                                                          onClick={() => this.changeEquipment(equipment.equipment_id)}>{equipment.name}</DropdownItem>)
                                                })}
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="mb-2">
                                    <Label sm={4}>Driver:</Label>
                                    <Col sm={8}>
                                        <UncontrolledButtonDropdown className="mr-1">
                                            <DropdownToggle disabled={this.state.details.fillup_id > 0} color="secondary" caret style={{fontSize: '13px', width: 'auto'}}>
                                                {this.state.details.driver}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {this.state.drivers_sort.map((driver, i) => {
                                                    return (<DropdownItem key={driver.driver_id} onClick={() => this.changeDriver(driver.driver_id)}>{driver.name}</DropdownItem>)
                                                })}
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="mb-2">
                                    <Label sm={4}>Chain:</Label>
                                    <Col sm={8}>
                                        <UncontrolledButtonDropdown className="mr-1">
                                            <DropdownToggle disabled={this.state.details.fillup_id > 0} color="secondary" caret style={{fontSize: '13px', width: 'auto'}}>
                                                {this.state.details.chain}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {this.state.chains.map((chain, i) => {
                                                    return (<DropdownItem key={chain} onClick={() => this.changeChain(chain)}>{chain}</DropdownItem>)
                                                })}
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="mb-2">
                                    <Label sm={4}>Location:{this.state.details.fillup_id > 0}</Label>
                                    <Col sm={8}>
                                        <Input type="text" size="sm" name="location" value={this.state.details.location} onChange={this.updateValue}
                                               disabled={this.state.details.fillup_id > 0}/>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="mb-2">
                                    <Label sm={4}>Odometer:</Label>
                                    <Col sm={8}>
                                        <InputGroup size="sm">
                                            <Input type="text" className="text-right" name="odometer" placeholder="0" value={this.state.details.odometer}
                                                   disabled={this.state.details.fillup_id > 0}
                                                   onChange={this.updateValue}/>
                                            <InputGroupAddon addonType="append">kms</InputGroupAddon>
                                        </InputGroup>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="mb-2 border-top">
                                    <Label sm={4}>Fuel Litres & Price:</Label>
                                    <Col sm={8}>
                                        <table className="table table-borderless table-sm m-0">
                                            <tr>
                                                <td className="p-0" style={{width: "41%"}}>
                                                    <InputGroup size="sm">
                                                        <Input type="text" className="text-right" placeholder="0" name="litres" value={this.state.details.litres}
                                                               disabled={(this.state.details.fillup_id > 0)}
                                                               onChange={this.updateValue}/>
                                                        <InputGroupAddon addonType="append">litres</InputGroupAddon>
                                                    </InputGroup>
                                                </td>
                                                <td className="p-0 text-center align-middle">
                                                    @
                                                </td>
                                                <td className="p-0" style={{width: "41%"}}>
                                                    <InputGroup size="sm">
                                                        <InputGroupAddon addonType="append">$</InputGroupAddon>
                                                        <Input type="text" className="text-right" placeholder="0" name="price_per_litre" value={this.state.details.price_per_litre}
                                                               disabled={this.state.details.fillup_id > 0}
                                                               onChange={this.updateValue}/>
                                                        <InputGroupAddon addonType="append">/L</InputGroupAddon>
                                                    </InputGroup>
                                                </td>
                                                <td className="p-0 text-right align-middle" style={{width: "14%"}}>
                                                    <nobr>= ${money.format('USD', money.floatToAmount(this.state.details.total_fuel))}</nobr>
                                                </td>
                                            </tr>
                                        </table>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="mb-2">
                                    <Label sm={4}>Tax Incl. in Fuel:</Label>
                                    <Col sm={8}>
                                        <table className="table table-borderless table-sm m-0">
                                            <tr>
                                                <td className="p-0" style={{width: "86%"}}>
                                                    <InputGroup size="sm">
                                                        <InputGroupAddon addonType="append">Tax: $</InputGroupAddon>
                                                        <Input type="text" className="text-right" placeholder="0" name="fuel_tax" value={this.state.details.fuel_tax}
                                                               disabled={this.state.details.fillup_id > 0}
                                                               onChange={this.updateValue}/>
                                                    </InputGroup>
                                                </td>
                                                <td className="p-0 text-right align-middle" style={{width: "14%"}}>
                                                    <nobr>= ${money.format('USD', money.floatToAmount(this.state.details.fuel_tax))}</nobr>
                                                </td>
                                            </tr>
                                        </table>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="mb-2 border-top">
                                    <Label sm={4}>DEF Litres & Price:</Label>
                                    <Col sm={8}>
                                        <table className="table table-borderless table-sm m-0">
                                            <tr>
                                                <td className="p-0" style={{width: "41%"}}>
                                                    <InputGroup size="sm">
                                                        <Input type="text" className="text-right" placeholder="0" name="def_litres" value={this.state.details.def_litres}
                                                               disabled={(this.state.details.fillup_id > 0)}
                                                               onChange={this.updateValue}/>
                                                        <InputGroupAddon addonType="append">litres</InputGroupAddon>
                                                    </InputGroup>
                                                </td>
                                                <td className="p-0 text-center align-middle">
                                                    @
                                                </td>
                                                <td className="p-0" style={{width: "41%"}}>
                                                    <InputGroup size="sm">
                                                        <InputGroupAddon addonType="append">$</InputGroupAddon>
                                                        <Input type="text" className="text-right" placeholder="0" name="def_ppl" value={this.state.details.def_ppl}
                                                               disabled={this.state.details.fillup_id > 0}
                                                               onChange={this.updateValue}/>
                                                        <InputGroupAddon addonType="append">/L</InputGroupAddon>
                                                    </InputGroup>
                                                </td>
                                                <td className="p-0 text-right align-middle" style={{width: "14%"}}>
                                                    <nobr>= ${money.format('USD', money.floatToAmount(this.state.details.total_def))}</nobr>
                                                </td>
                                            </tr>
                                        </table>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="mb-2">
                                    <Label sm={4}>Tax Incl. in DEF:</Label>
                                    <Col sm={8}>
                                        <table className="table table-borderless table-sm m-0">
                                            <tr>
                                                <td className="p-0" style={{width: "86%"}}>
                                                    <InputGroup size="sm">
                                                        <InputGroupAddon addonType="append">Tax: $</InputGroupAddon>
                                                        <Input type="text" className="text-right" placeholder="0" name="def_tax" value={this.state.details.def_tax}
                                                               disabled={this.state.details.fillup_id > 0}
                                                               onChange={this.updateValue}/>
                                                    </InputGroup>
                                                </td>
                                                <td className="p-0 text-right align-middle" style={{width: "14%"}}>
                                                    <nobr>= ${money.format('USD', money.floatToAmount(this.state.details.def_tax))}</nobr>
                                                </td>
                                            </tr>
                                        </table>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="mb-2 border-top">
                                    <Label sm={4}>Total Paid:</Label>
                                    <Col sm={8} className="pt-2">
                                        <b>${money.format('USD', money.floatToAmount(this.state.details.total))}</b>
                                    </Col>
                                </FormGroup>
                            </Form>
                        </ModalBody>
                        <ModalFooter className={(this.state.details.fillup_id > 0 ? "d-none" : "")}>
                            <Button color="primary" onClick={this.AddFillup}><i className={(this.state.processing ? "fa fa-spinner fa-spin mr-2" : "d-none")}></i>Record
                                Fill-Up</Button>
                        </ModalFooter>
                    </Modal>
                </FormGroup>
            </>
        );
    };
}

export default (AddFillup);
