import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {Button, DropdownItem, DropdownToggle, DropdownMenu, UncontrolledButtonDropdown} from 'reactstrap';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import {Link} from 'react-router-dom';
import {getProducts} from '../Products/ProductFunctions.js';
import {getStaff} from '../Staff/StaffFunctions.js';
import {getEquipment} from "../Equipment/EquipmentFunctions";

import Swal from 'sweetalert2'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import 'moment-timezone';

moment.tz.setDefault("America/Halifax");

class InventoryTransactions extends React.Component {
    constructor(props) {
        super(props);

        this.updateDates = this.updateDates.bind(this);

        this.state = {
            transactions: [],
            loading: true,
            // default search settings
            action_type: null,
            category: null,
            product_id: null,
            staff_id: null,
            startDate: moment().subtract(29, 'days'),
            endDate: moment().endOf('day'),
            // default search filter names
            category_name: "All",
            product_name: "All",
            staff_name: "All",
            // data
            codes: {1: "New Inventory",3: "Used", 4: "Reconciliation", 2: "Damaged"},
            categories: {
                in: "In (all)",
                in_1: "In, New Inventory",
                in_4: "In, Reconciliation",
                out: "Out (all)",
                out_2: "Out, Damaged",
                out_3: "Out, Used",
                out_4: "Out, Reconciliation",
                out_99: "Out, Used"
            },
            staff: [],
            action_types: {1: "Addition", 2: "Removal"},
            products: [],
            equipment:{},
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment().endOf('day')],
                'Last 30 Days': [moment().subtract(29, 'days'), moment().endOf('day')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                'All Time': [moment("01 Jun 2018"), moment().endOf('day')]
            }
        };
    }

    componentDidMount() {
        document.title = "Inventory Transactions | Jeremie Cormier Trucking Ltd";
        var self = this;
        getStaff(function (staff) {
            getProducts(function (products) {
                getEquipment(0, function (equipment) {
                    self.setState({
                        products: products,
                        staff: staff,
                        equipment: equipment
                    });
                    self.getTransactions();
                });
            });
        })
    }

    // on date range change
    updateDates(event, picker) {
        if (event.type == "apply") {
            this.setState({
                startDate: picker.startDate,
                endDate: picker.endDate
            }, () => {
                this.getTransactions()
            });
        }
    }

    changeCategory = (category) => {
        if (category == null) {
            var category_name = "All"
        } else {
            var category_name = this.state.categories[category]
        }
        this.setState({
            category: category,
            category_name: category_name
        }, () => {
            this.getTransactions()
        });
    };
    changeProduct = (product_id) => {
        if (product_id == null) {
            var product_name = "All"
        } else {
            var product_name = this.state.products[product_id].name
        }
        this.setState({
            product_id: product_id,
            product_name: product_name
        }, () => {
            this.getTransactions()
        });
    };
    changeStaff = (staff_id) => {
        if (staff_id == null) {
            var staff_name = "All"
        } else {
            var staff_name = this.state.staff[staff_id].first_name + " " + this.state.staff[staff_id].last_name.charAt(0) + ".";
        }
        this.setState({
            staff_id: staff_id,
            staff_name: staff_name
        }, () => {
            this.getTransactions()
        });
    };
    refreshSearch = event => {
        this.getTransactions();
    };

    getTransactions() {
        this.setState({
            loading: true
        });
        var self = this;
        axios.defaults.withCredentials = true;
        axios.get(API_ROOT + '/inventory/transactions/?action_type=' + self.state.action_type + "&category=" + self.state.category + "&product_id=" + self.state.product_id + "&startDate=" + self.state.startDate + "&endDate=" + self.state.endDate + "&order_type=" + self.state.order_type + "&staff_id=" + self.state.staff_id)
            .then(function (response) {
                self.setState({
                    transactions: response.data,
                    loading: false
                });
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                    self.setState({
                        orders: [],
                        loading: false
                    });
                }
            });
    }

    render() {
        // date range selector variables
        let start = this.state.startDate.format('MMM. D/YY');
        let end = this.state.endDate.format('MMM. D/YY');
        let label = start + ' - ' + end;
        if (start === end) {
            label = start;
        }
        var total_quantity = parseFloat(0);

        return (
            <ContentWrapper>
                <div className="content-heading">
                    <Link to="../inventory">Inventory</Link>&nbsp;/&nbsp;Transactions
                    <div className="ml-auto">
                        <Button onClick={this.refreshSearch}><i className="fas fa-sync-alt"></i></Button>
                    </div>
                </div>
                <div className="mb-3 form-inline">
                    <DateRangePicker
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        maxDate={moment()}
                        ranges={this.state.ranges}
                        onEvent={this.updateDates}
                        autoUpdateInput={true}
                    >
                        <Button className="selected-date-range-btn mr-1" color="primary">
                            Period: <b>{label}</b> <span className="fas fa-caret-down fa-sm"></span>
                        </Button>
                    </DateRangePicker>
                    <UncontrolledButtonDropdown className="m-1">
                        <DropdownToggle caret color="primary" style={{width: 'auto'}}>
                            Category: <b>{this.state.category_name}</b>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem key="null" onClick={() => this.changeCategory(null)}>All</DropdownItem>
                            <DropdownItem divider/>
                            {Object.keys(this.state.categories).map((key) =>
                                <DropdownItem key={key} onClick={() => this.changeCategory(key)}>{this.state.categories[key]}</DropdownItem>
                            )}
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                    <UncontrolledButtonDropdown className="m-1">
                        <DropdownToggle caret color="primary" style={{width: 'auto'}}>
                            Product: {this.state.product_name}
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem key="null" onClick={() => this.changeProduct(null)}>All</DropdownItem>
                            <DropdownItem divider/>
                            {Object.keys(this.state.products).map((products_id) => {
                                    if (this.state.products[products_id].is_deleted == 0) {
                                        return (<DropdownItem key={products_id} onClick={() => this.changeProduct(products_id)}>{this.state.products[products_id].name}</DropdownItem>)
                                    }
                                }
                            )}
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                    <UncontrolledButtonDropdown className="m-1">
                        <DropdownToggle color="primary" caret style={{width: 'auto'}}>
                            By: {this.state.staff_name}
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem key="null" onClick={() => this.changeStaff(null)}>All</DropdownItem>
                            <DropdownItem divider/>
                            {Object.keys(this.state.staff).map(function (staff_id, key) {
                                return (
                                    <DropdownItem key={staff_id}
                                                  onClick={() => this.changeStaff(staff_id, 1)}>{this.state.staff[staff_id].first_name} {this.state.staff[staff_id].last_name}</DropdownItem>
                                )
                            }, this)}
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
                <div className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                    <div className={(this.state.loading ? "invisible" : "")}>
                        <div className="card-header">
                            <div className="card-title">{this.state.transactions.length} Matching Transactions</div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className={(this.state.transactions.length > 0 && !this.state.loading ? "table table-hover" : "d-none")}>
                                    <thead>
                                    <tr>
                                        <th className="d-none d-xl-table-cell">ID</th>
                                        <th>Date</th>
                                        <th>Product</th>
                                        <th className="text-right">Qty.</th>
                                        <th className="d-none d-sm-table-cell">Type</th>
                                        <th className="d-none d-sm-table-cell">Equipment</th>
                                        <th className="d-none d-sm-table-cell">Notes</th>
                                        <th className="d-none d-xl-table-cell">Date Entered</th>
                                        <th className="d-none d-sm-table-cell">By</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.transactions.map(function (item, key) {
                                        var id_prefix = "ORD-";
                                        var quantity = item.quant;
                                        var notes = "";
                                        var order_text = "";
                                        var url = "";
                                        var category = "Purchase";
                                        if (quantity > 0) {
                                            quantity = "+" + quantity;
                                        }
                                        total_quantity = (parseFloat(quantity) + total_quantity);
                                        var d_ent = item.d_ent;
                                        id_prefix = "";
                                        category = this.state.codes[item.code];
                                        notes = item.notes;
                                        var by = "-";
                                        if (typeof this.state.staff[item.staff] != "undefined") {
                                            by = this.state.staff[item.staff].first_name + " " + this.state.staff[item.staff].last_name.charAt(0) + ".";
                                        }
                                        var equipment = "--";
                                        if (item.equipment_id!=null){
                                            equipment = this.state.equipment[item.equipment_id].name;
                                        }

                                        return (
                                            <tr key={key}>
                                                <td className="d-none d-xl-table-cell">{id_prefix}{item.id}</td>
                                                <td>{item.d_eff}</td>
                                                <td>{(typeof this.state.products[item.prod] == "undefined" ? "?" : this.state.products[item.prod].name)}</td>
                                                <td className={(quantity > 0 ? "text-success text-right" : "text-right text-danger")}>{quantity}</td>
                                                <td>{category}</td>
                                                <td>{equipment}</td>
                                                <td>{notes}<Link to={url}>{order_text}</Link></td>
                                                <td className="d-none d-xl-table-cell">{item.d_ent}</td>
                                                <td className="d-none d-sm-table-cell">{by}</td>
                                            </tr>
                                        )
                                    }, this)}
                                    </tbody>
                                    <tfoot>
                                    <tr>
                                        <th colspan="5" className="text-right">Total:</th>
                                        <th>{total_quantity}</th>
                                        <th colspan="4"></th>
                                    </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export default (InventoryTransactions);
