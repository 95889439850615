import React, {Component} from 'react';

class Footer extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const year = new Date().getFullYear()
        return (
            <footer className={(this.props.location.pathname == "/map" ? "d-none" : "footer-container")}>
                <span>&copy; {year} Jeremie Cormier Trucking Ltd</span>
                <span className="powered-by">powered by <a href="http://www.customtms.ca" target="_blank" rel="noopener noreferrer">Custom TMS</a></span>
            </footer>
        );
    }

}

export default Footer;
