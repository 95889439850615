import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import 'moment-timezone';
import getVehicles from "../Vehicles/VehicleFunctions";
import {getDrivers} from "../Drivers/DriverFunctions";
import {Link} from "react-router-dom";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown} from "reactstrap";
import axios from "axios";
import {API_ROOT} from "../../api-config";
import Swal from "sweetalert2";

import {BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from 'recharts';

var money = require("money-math");


moment.tz.setDefault("America/Halifax");

class Idle extends React.Component {
    constructor(props) {
        super(props);
        this.updateDates = this.updateDates.bind(this);
        this.goToVehicle = this.goToVehicle.bind(this);
        this.goToDriver = this.goToDriver.bind(this);

        this.state = {
            drivers_data: [],
            results: [],
            results_sort: [],
            vehicles: {},
            vehicles_sort: [],
            loading: true,
            driver_id: null,
            vehicle_id: null,
            min_idle_mins: 15,
            min_idle_options: [15, 30, 45, 60, 75, 90, 105, 120, 135, 150],
            drivers: {},
            drivers_sort: [],
            startDate: (localStorage.getItem('acc_startDate') != null ? moment(localStorage.getItem('acc_startDate')) : moment().subtract(6, 'days')),
            endDate: (localStorage.getItem('acc_endDate') != null ? moment(localStorage.getItem('acc_endDate')) : moment().endOf('day')),
            ranges: {
                'Today': [moment(), moment()],
                'Last 7 Days': [moment().subtract(6, 'days'), moment().endOf('day')],
                'Last 30 Days': [moment().subtract(29, 'days'), moment().endOf('day')],
                'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                'All Time': [moment("01 Jun 2023"), moment().endOf('day')]
            },
            sort: (localStorage.getItem('sort') != null ? localStorage.getItem('sort') : "name"),
            sort_order: (localStorage.getItem('sort_order') != null ? localStorage.getItem('sort_order') * -1 : -1),
        };
    }

    componentDidMount() {
        document.title = "Idle Time | Jeremie Cormier Trucking Ltd";
        var self = this;
        getVehicles(function (vehicles) {
            getDrivers(function (drivers) {
                if (Object.keys(vehicles).length > 0) {
                    var vehicles_sort = [];
                    for (const vehicle_id of Object.keys(vehicles)) {
                        var obj = vehicles[vehicle_id];
                        vehicles_sort.push(obj)
                    }
                    vehicles_sort = vehicles_sort.sort(function (a, b) {
                        if (a.name < b.name) {
                            return -1
                        } else {
                            return 1
                        }
                    });
                    var drivers_sort = [];
                    for (const driver_id of Object.keys(drivers)) {
                        var obj = drivers[driver_id];
                        obj['name'] = obj.first_name + ' ' + obj.last_name;
                        drivers_sort.push(obj)
                    }
                    drivers_sort = drivers_sort.sort(function (a, b) {
                        if (a.name < b.name) {
                            return -1
                        } else {
                            return 1
                        }
                    });
                    self.setState({
                        vehicles: vehicles,
                        vehicles_sort: vehicles_sort,
                        drivers: drivers,
                        drivers_sort: drivers_sort,
                    }, () => {
                        self.triggerSearch();
                    });
                    self.onSort(null, self.state.sort)
                }
            });
        });
    }

    triggerSearch() {
        var self = this;
        axios.defaults.withCredentials = true;
        axios.get(API_ROOT + "/idle/?vehicle_id=" + self.state.vehicle_id + "&driver_id=" + self.state.driver_id + "&startDate=" + self.state.startDate + "&endDate=" + self.state.endDate + "&min_idle_mins=" + self.state.min_idle_mins)
            .then(function (response) {
                var drivers_data = [];
                for (var key in response.data) {
                    var driver_exists = false;
                    var driver_name = (response.data[key].driver == null ? "Not Signed In" : self.state.drivers[response.data[key].driver].first_name + " " + self.state.drivers[response.data[key].driver].last_name);
                    for (var key2 in drivers_data) {
                        if (drivers_data[key2].name == driver_name) {
                            console.log(driver_name)
                            driver_exists = true;
                        }
                    }
                    console.log('checking driver')
                    if (!driver_exists) {
                        console.log('adding blank driver ' + driver_name)
                        drivers_data.push({name: driver_name, daycab: 0, sleeper: 0})
                        console.log(drivers_data)
                    }
                    console.log('step 4')
                    for (var key2 in drivers_data) {
                        if (drivers_data[key2].name == driver_name) {
                            console.log('name match')
                            var type = (self.state.vehicles[response.data[key].vehicle_id].equipment_type_sub_id == 2 ? 'sleeper' : 'daycab')
                            console.log(type)
                            console.log(key2)
                            console.log(drivers_data[key2])
                            drivers_data[key2][type] = drivers_data[key2][type] + response.data[key].idle_mins;
                        }
                    }
                    console.log(drivers_data)
                }
                console.log('drivers_data')
                console.log(drivers_data)
                self.setState({
                    drivers_data: drivers_data,
                    results: response.data,
                    loading: false
                });
                //self.onSort(null, self.state.sort)
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
            });
    }


    updateValue(event, name, value) {
        var self = this;
        if (event.target) {
            event.preventDefault();
            event.stopPropagation();
        }
        if (typeof name == "undefined") {
            name = event.target.name
        }
        if (typeof value == "undefined") {
            value = event.target.value
        }
        this.setState({
            [name]: value,
            loading: true
        }, () => {
            self.triggerSearch();
        });
    }


    // on date range change
    updateDates(event, picker) {
        var self = this;
        if (event.type == "apply") {
            localStorage.setItem('acc_startDate', picker.startDate);
            localStorage.setItem('acc_endDate', picker.endDate);
            this.setState({
                startDate: picker.startDate,
                endDate: picker.endDate
            }, () => {
                self.triggerSearch();
            });
        }
    }

    goToVehicle = (vehicle_id) => {
        this.props.history.push("/vehicles/" + vehicle_id);
    };

    goToDriver = (driver_id) => {
        this.props.history.push("/drivers/" + driver_id);
    };

    onSort(event, sortKey) {
        localStorage.setItem('sort', sortKey);
        localStorage.setItem('sort_order', this.state.sort_order);
        var sort_order = this.state.sort_order * -1;
        if (sortKey != this.state.sort) {
            sort_order = 1;
        }

        function format_search_val(val) {
            if (isNaN(val)) {
                return val.toLowerCase();
            } else {
                return parseFloat(val);
            }
        }

        var results_sort = this.state.results.sort((a, b) => {
            if (typeof format_search_val(a[sortKey]) == "string" && typeof format_search_val(b[sortKey]) != "string") {
                return (sort_order == -1 ? -1 : 1);
            } else {
                if (format_search_val(a[sortKey]) > format_search_val(b[sortKey])) {
                    return (sort_order == -1 ? -1 : 1);
                }
                if (format_search_val(a[sortKey]) < format_search_val(b[sortKey])) {
                    return (sort_order == -1 ? 1 : -1);
                }
            }
            return 0;
        });
        this.setState({
            sort: sortKey,
            sort_order: sort_order,
            results_sort: results_sort
        });
    }

    export = () => {
        var currentdate = new Date();
        var csv = 'Truck,Driver,Date,Location,Idle Time, Idle Cost,Idle Start,Idle End\n';
        for (var i = 0; i < this.state.results.length; i++) {
            var row = this.state.results[i];
            csv += '"' + this.state.vehicles[row.vehicle_id].name + '","' + (row.driver == null ? "" : this.state.drivers[row.driver].first_name + " " + this.state.drivers[row.driver].last_name) + '","' + moment.unix(row.idle_start).format("MMM D") + '","' + row.location + '","' + row.idle_mins + '","$' + money.format("USD", money.floatToAmount(row.idle_mins * 10 / 60)) + '","' + moment.unix(row.idle_start).format("HH:mm") + '","' + moment.unix(row.idle_end).format("HH:mm") + '"\n';
        }
        const blob = new Blob([csv], {type: "text/csv"});
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.download = "Idle Times.csv";
        link.href = url;
        link.click();
    }

    render() {

        // date range selector variables
        let start = this.state.startDate.format('MMM. D/YY');
        let end = this.state.endDate.format('MMM. D/YY');
        let label = start + ' - ' + end;
        if (start === end) {
            label = start;
        }
        var total_mins = money.floatToAmount(0);

        return (
            <ContentWrapper>
                <div className="content-heading">
                    <Link to="../reports">Reports</Link><span className="text-muted">&nbsp;/&nbsp;</span> Idle Time
                </div>
                <div className="mb-3 form-inline" style={{zIndex: 1000}}>
                    <DateRangePicker
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        ranges={this.state.ranges}
                        maxDate={moment()}
                        onEvent={this.updateDates}
                        autoUpdateInput={true}
                    >
                        <button type="button" className="btn btn-primary selected-date-range-btn mr-1">
                            Period:* <b>{label}</b> <span className="fas fa-caret-down fa-sm"></span>
                        </button>
                    </DateRangePicker>
                    <UncontrolledButtonDropdown className="mr-1">
                        <DropdownToggle color="primary" caret style={{fontSize: '13px', width: 'auto'}}>
                            {(this.state.driver_id == null ? "All Drivers" : this.state.drivers[this.state.driver_id].first_name)} {(this.state.driver_id == null ? "" : this.state.drivers[this.state.driver_id].last_name)}
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem key={null} onClick={() => this.updateValue(false, 'driver_id', null)}>All</DropdownItem>
                            <DropdownItem divider/>
                            {Object.keys(this.state.drivers_sort).map((key, i) => {
                                return (<DropdownItem key={key}
                                                      onClick={() => this.updateValue(false, 'driver_id', this.state.drivers_sort[key].driver_id)}>{this.state.drivers_sort[key].first_name} {this.state.drivers_sort[key].last_name}</DropdownItem>)
                            })}
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                    <UncontrolledButtonDropdown className="ml-1">
                        <DropdownToggle color="primary" caret style={{fontSize: '13px', width: 'auto'}}>
                            {(this.state.vehicle_id == null ? "All Vehicles" : this.state.vehicles[this.state.vehicle_id].name)}
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem key={null} onClick={() => this.updateValue(false, 'vehicle_id', null)}>All</DropdownItem>
                            <DropdownItem divider/>
                            {this.state.vehicles_sort.map((equipment, i) => {
                                return (<DropdownItem key={equipment.equipment_id}
                                                      onClick={() => this.updateValue(false, 'vehicle_id', equipment.equipment_id)}>{equipment.name}</DropdownItem>)
                            })}
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                    <UncontrolledButtonDropdown className="ml-1">
                        <DropdownToggle color="primary" caret style={{fontSize: '13px', width: 'auto'}}>
                            Min. Idle Time: {(this.state.min_idle_mins)} mins
                        </DropdownToggle>
                        <DropdownMenu>
                            {this.state.min_idle_options.map((mins, i) => {
                                return (<DropdownItem key={mins} onClick={() => this.updateValue(false, 'min_idle_mins', mins)}>{mins} mins</DropdownItem>)
                            })}
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
                <div className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                    <div>
                        <div className="card-header">
                            <div className="btn btn-secondary btn-xs float-right mr-1" onClick={this.export}>
                                <i className="fas fa-download"></i> Export
                            </div>
                            <div className="card-title">{this.state.results.length} Occurrences</div>
                        </div>
                        <div className="card-body pb-0">
                            <DriverChart drivers_data={this.state.drivers_data}></DriverChart>
                            <div className="table-responsive">
                                <table className={(this.state.results.length > 0 ? "table table-hover table-pointer text-left" : "d-none")}>
                                    <thead>
                                    <tr>
                                        <th onClick={e => this.onSort(e, 'vehicle_id')}><i className="fas fa-sort"></i> Truck</th>
                                        <th onClick={e => this.onSort(e, 'driver_id')}><i className="fas fa-sort"></i> Driver</th>
                                        <th onClick={e => this.onSort(e, 'idle_start')}><i className="fas fa-sort"></i> Date</th>
                                        <th onClick={e => this.onSort(e, 'location')}><i className="fas fa-sort"></i> Location</th>
                                        <th className="text-right" onClick={e => this.onSort(e, 'idle_mins')}><i className="fas fa-sort"></i> Idle Time</th>
                                        <th className="text-right" onClick={e => this.onSort(e, 'idle_mins')}><i className="fas fa-sort"></i> Idle Cost</th>
                                        <th className="text-right" onClick={e => this.onSort(e, 'idle_start')}><i className="fas fa-sort"></i> Idle Start</th>
                                        <th className="text-right" onClick={e => this.onSort(e, 'idle_end')}><i className="fas fa-sort"></i> Idle End</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {Object.entries(this.state.results).map(([key, row]) => {
                                        total_mins = money.add(total_mins, money.floatToAmount(row.idle_mins));
                                        return (
                                            <tr key={key}>
                                                <td><Link to={"../vehicles/" + row.vehicle_id}>{this.state.vehicles[row.vehicle_id].name}</Link></td>
                                                <td><Link
                                                    to={"../drivers/" + row.driver}>{(row.driver == null ? "" : this.state.drivers[row.driver].first_name + " " + this.state.drivers[row.driver].last_name)}</Link>
                                                </td>
                                                <td>{moment.unix(row.idle_start).format("MMM D")}</td>
                                                <td>{row.location}</td>
                                                <td className="text-right">{row.idle_mins} mins</td>
                                                <td className="text-right">${money.format("USD", money.floatToAmount(row.idle_mins * 10 / 60))}</td>
                                                <td className="text-right">{moment.unix(row.idle_start).format("HH:mm")}</td>
                                                <td className="text-right">{moment.unix(row.idle_end).format("HH:mm")}</td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                    <tfoot>
                                    <tr>
                                        <th className="text-right" colspan="4">Total:</th>
                                        <th className="text-right">{money.div(total_mins, money.floatToAmount(60))} hrs</th>
                                        <th className="text-right">${money.format("USD", money.mul(money.div(total_mins, money.floatToAmount(60)), money.floatToAmount(10)))}</th>
                                        <th colspan="2"></th>
                                    </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export default (Idle);

class DriverChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            "data": []
        }
    }

    render() {
        return (
            <div>
                <div style={{width: "100%", height: "300px"}} className={(this.props.drivers_data.length > 0 ? "" : "d-none")}>
                    <ResponsiveContainer>
                        <BarChart
                            width={500}
                            height={300}
                            data={this.props.drivers_data}
                            margin={{
                                top: 5,
                                right: 0,
                                left: 0,
                                bottom: 89,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3"/>
                            <XAxis dataKey="name" angle={-45} textAnchor="end"/>
                            <YAxis tickFormatter={(tickValue) => (tickValue / 60).toFixed(1) + ' Hr'}/>
                            <Tooltip/>
                            <Legend layout="horizontal" verticalAlign="top" align="center"/>
                            <Bar dataKey="daycab" stackId="a" fill="#3c99d2"/>
                            <Bar dataKey="sleeper" stackId="a" fill="#00c6d3"/>
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>
        );
    }
}