import React, {Component} from 'react';
import {
    Button,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    DropdownMenu,
    Form,
    FormGroup,
    DropdownItem,
    DropdownToggle,
    UncontrolledButtonDropdown,
    Label,
    Input
} from 'reactstrap';
import getCustomers from "../Customers/CustomerFunctions";

import 'react-dropzone-uploader/dist/styles.css'

import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'

class AddEquipment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            name: "",
            equipment_type_id: 6,
            equipment_type_sub_id: 22,
            province: 'NB',
            plate: "",
            vin: "",
            acquired_odo: "",
            status: 1,
            customer_id: 0,
            customers: {},
            customers_sort: [],
            province_list: ['NB', 'NS', 'PE', 'NL', 'ON', 'QC', 'SK', 'MB', 'AB', 'BC','AL',
                'AK',
                'AZ',
                'AR',
                'CA',
                'CO',
                'CT',
                'DE',
                'FL',
                'GA',
                'HI',
                'ID',
                'IL',
                'IN',
                'IA',
                'KS',
                'KY',
                'LA',
                'ME',
                'MD',
                'MA',
                'MI',
                'MN',
                'MS',
                'MO',
                'MT',
                'NE',
                'NV',
                'NH',
                'NJ',
                'NM',
                'NY',
                'NC',
                'ND',
                'OH',
                'OK',
                'OR',
                'PA',
                'RI',
                'SC',
                'SD',
                'TN',
                'TX',
                'UT',
                'VT',
                'VA',
                'WA',
                'WV',
                'WI',
                'WY'],
            equipments_statuses: {1: "In Service", 2: "Out of Service", 0: "Retired", 3: "Rented"},
            equipment_types: {},
            equipment_types_sub: {},
            processing: false
        };
    }

    componentDidMount() {
        var self = this;
        if (typeof this.props.customers == "undefined") {
            getCustomers(function (customers) {
                var customers_sort = [];
                for (const customer_id of Object.keys(customers)) {
                    customers_sort.push(customers[customer_id]);
                }
                var customers_sort = customers_sort.sort(function (a, b) {
                    if (a.name < b.name) {
                        return -1
                    } else {
                        return 1
                    }
                });
                self.setState({
                    customers: customers,
                    customers_sort: customers_sort
                })
            });
        } else {
            var customers_sort = [];
            for (const customer_id of Object.keys(this.props.customers)) {
                customers_sort.push(this.props.customers[customer_id]);
            }
            var customers_sort = customers_sort.sort(function (a, b) {
                if (a.name < b.name) {
                    return -1
                } else {
                    return 1
                }
            });
            self.setState({
                customers: this.props.customers,
                customers_sort: customers_sort
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (typeof this.props.triggerOpen != "undefined" && prevProps.triggerOpen !== this.props.triggerOpen && this.props.triggerOpen != false) {
            this.setState({
                modal: true,
                name: (typeof this.props.search_term == "undefined" ? "" : this.props.search_term)
            });
        }
        if (typeof this.props.customer_id != "undefined" && prevProps.customer_id !== this.props.customer_id && this.props.customer_id != 0) {
            this.setState({
                customer_id: this.props.customer_id
            });
        }
        if (typeof this.props.customers != "undefined" && prevProps.customers !== this.props.customers) {
            var customers_sort = [];
            for (const customer_id of Object.keys(this.props.customers)) {
                customers_sort.push(this.props.customers[customer_id]);
            }
            var customers_sort = customers_sort.sort(function (a, b) {
                if (a.name < b.name) {
                    return -1
                } else {
                    return 1
                }
            });
            this.setState({
                customers: this.props.customers,
                customers_sort: customers_sort
            })
        }
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    };

    updateField = (name, value) => {
        if (typeof value != "string" && typeof value.target != "undefined") {
            value = value.target.value;
        }
        this.setState({
            [name]: value
        });
    };

    changeProvince = (province) => {
        this.setState({
            province: province,
        });
    }

    changeEquipmentType = (equipment_type_id) => {
        this.setState({
            equipment_type_id: equipment_type_id,
            equipment_type_sub_id: null
        });
    }

    changeEquipmentTypeSub = (equipment_type_sub_id) => {
        this.setState({
            equipment_type_sub_id: equipment_type_sub_id,
        });
    }

    changeStatus = (status) => {
        this.setState({
            status: status
        });
    }

    AddEquipment = async event => {
        event.preventDefault()
        this.setState({
            processing: true
        });
        var self = this;
        axios.defaults.withCredentials = true;
        axios.post(API_ROOT + '/equipment', this.state)
            .then(function (response) {
                self.setState({
                    modal: false
                });
                Swal("Success!", response.data.Message, "success");
                if (self.props.goToEquipment != null) {
                    if (typeof self.props.segment_num != "undefined") {
                        self.props.goToEquipment(self.props.segment_num, response.data.equipment_id);
                    } else {
                        self.props.goToEquipment(response.data.equipment_id);
                    }
                }
            })
            .catch(function (error) {
                if (typeof error.response != "undefined") {
                    if (error.response.status === 401) {
                        self.props.userSignOut()
                    } else {
                        Swal("Error", error.response.data.Message, "error");
                        self.setState({
                            processing: false
                        });
                    }
                }
            });
    };

    render() {
        return (
            <>
                <Button className={this.props.hidden ? "d-none" : "mt-2"} color="primary" size="sm" onClick={this.toggle}><i className="fas fa-plus"></i> Equipment</Button>
                <FormGroup>
                    <Modal isOpen={this.state.modal} toggle={this.toggle} bsSize="lg">
                        <ModalHeader>
                            <div className="h3 mb-0">Add Equipment</div>
                        </ModalHeader>
                        <ModalBody>
                            <Form>
                                <FormGroup row>
                                    <Label sm={4}>ID / Nickname:</Label>
                                    <Col sm={8}>
                                        <Input type="text" size="sm" name="name" value={this.state.name} onChange={(event) => this.updateField("name", event)}/>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={4}>Owner:</Label>
                                    <Col sm={8}>
                                        <UncontrolledButtonDropdown className="mr-1">
                                            <DropdownToggle color="secondary" caret style={{fontSize: '13px', width: 'auto'}}>
                                                {(this.state.customer_id == 0 || this.state.customer_id == 0 ? "Jeremie Cormier Trucking Ltd" : (typeof this.state.customers[this.state.customer_id] == "undefined" ? "" : this.state.customers[this.state.customer_id].name))}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem key="0" onClick={() => this.updateField('customer_id', 0)}>Jeremie Cormier Trucking Ltd</DropdownItem>
                                                <DropdownItem divider/>
                                                {Object.keys(this.state.customers_sort).map((key, i) => {
                                                    return (<DropdownItem key={key}
                                                                          onClick={() => this.updateField('customer_id', this.state.customers_sort[key].customer_id)}>{this.state.customers_sort[key].name}</DropdownItem>)
                                                })}
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={4}>Type:</Label>
                                    <Col sm={8}>
                                        <UncontrolledButtonDropdown>
                                            <DropdownToggle color="secondary" caret style={{fontSize: '13px', width: 'auto'}}>
                                                {(typeof this.props.equipment_types[this.state.equipment_type_id] == "undefined" ? "loading..." : this.props.equipment_types[this.state.equipment_type_id].name)}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {Object.keys(this.props.equipment_types).map((key, i) => {
                                                    if (typeof this.props.equipment_types != "undefined" && typeof this.props.equipment_types[key] != "undefined" && this.props.equipment_types[key].is_vehicle == 0) {
                                                            return (<DropdownItem key={key}
                                                                                  onClick={() => this.changeEquipmentType(key)}>{this.props.equipment_types[key].name}</DropdownItem>)
                                                        }
                                                    }
                                                )}
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                        <i className="fas fa-angle-right ml-2 mr-2"></i>
                                        <UncontrolledButtonDropdown>
                                            <DropdownToggle color="secondary" caret style={{fontSize: '13px', width: 'auto'}}>
                                                {(typeof this.props.equipment_types_sub[this.state.equipment_type_sub_id] == "undefined" ? "loading..." : this.state.equipment_type_sub_id == null ? "(select)" : this.props.equipment_types_sub[this.state.equipment_type_sub_id].name)}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {Object.keys(this.props.equipment_types_sub).map((key, i) => {
                                                        if (typeof this.props.equipment_types_sub != "undefined" && typeof this.props.equipment_types_sub[key] != "undefined" && this.state.equipment_type_id == this.props.equipment_types_sub[key].equipment_type_id) {
                                                            return (
                                                                <DropdownItem key={key}
                                                                              onClick={() => this.changeEquipmentTypeSub(key)}>{this.props.equipment_types_sub[key].name}</DropdownItem>
                                                            )
                                                        }
                                                    }
                                                )}
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={4}>Prov. of Reg.:</Label>
                                    <Col sm={8}>
                                        <UncontrolledButtonDropdown>
                                            <DropdownToggle color="secondary" caret style={{fontSize: '13px', width: 'auto'}}>
                                                {this.state.province}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {this.state.province_list.map((province, i) =>
                                                    <DropdownItem key={province} onClick={() => this.changeProvince(province)}>{province}</DropdownItem>
                                                )}
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={4}>Plate Number:</Label>
                                    <Col sm={8}>
                                        <Input type="text" size="sm" name="plate" value={this.state.plate} onChange={(event) => this.updateField("plate", event)}/>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={4}>VIN:</Label>
                                    <Col sm={8}>
                                        <Input type="text" size="sm" name="vin" value={this.state.vin} onChange={(event) => this.updateField("vin", event)}/>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={4}>Status:</Label>
                                    <Col sm={8}>
                                        <UncontrolledButtonDropdown>
                                            <DropdownToggle color="secondary" caret style={{fontSize: '13px', width: 'auto'}}>
                                                {this.state.equipments_statuses[this.state.status]}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {Object.keys(this.state.equipments_statuses).map((key, i) =>
                                                    <DropdownItem key={key} onClick={() => this.changeStatus(key)}>{this.state.equipments_statuses[key]}</DropdownItem>
                                                )}
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </Col>
                                </FormGroup>
                            </Form>
                            <div className={this.props.goToEquipment == null ? "d-none" : "alert alert-info p-2"}>
                                More information is available to enter on the next screen
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={this.AddEquipment}><i className={(this.state.processing ? "fa fa-spinner fa-spin mr-2" : "d-none")}></i>Add
                                Equipment</Button>
                        </ModalFooter>
                    </Modal>
                </FormGroup>
            </>
        );
    };
}

export default (AddEquipment);
