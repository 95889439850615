import React, {Component} from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {Link} from 'react-router-dom';
import {
    Card,
    CardBody,
    Col,
    Row,
    Button,
    Label,
    Input,
    ButtonGroup,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    FormGroup, ModalHeader, ModalBody, InputGroup, InputGroupAddon, Modal,
} from 'reactstrap';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import moment from 'moment';
import 'moment-timezone';
import {toast, ToastContainer} from "react-toastify";


moment.tz.setDefault("America/Halifax");

var money = require("money-math");

class InvoicePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            invoice: {
                po: null,
                invoice_date: moment(),
                net_days: 30,
                notes_internal: "",
                notes_external: "",
                loads: [],
                manual_items: [],
                customer_id: null
            },
            type: 'full',
            name:"",
            subject:"",
            body: "",
            to: "",
            replyto: "adricecorm@outlook.com",
            bcc: "adricecorm@outlook.com",
            email_log: [],
            modal: false,
            sending:false,
            attachments: true
        };
        this.updateValue = this.updateValue.bind(this);
    }

    componentDidMount() {
        document.title = "Invoice #" + this.props.match.params.invoice_id + " | Custom TMS";
        var self = this;
        axios.defaults.withCredentials = true;
        axios.get(API_ROOT + '/invoice/' + self.props.match.params.invoice_id)
            .then(function (response) {
                self.setState({
                        invoice: response.data,
                        loading: false,
                        to: response.data.ap_email,
                        name: response.data.name,
                        email_log: response.data.email_log,
                        subject: "Jeremie Cormier Trucking - Invoice # "+self.props.match.params.invoice_id,
                        body: "Dear "+response.data.name+",\n\nThank you for your business. Please find your invoice, # "+self.props.match.params.invoice_id+", attached to this message.\n\nIf you have any questions about this invoice, please contact us at adricecorm@outlook.com\n\nThank you for your business,\n\n- Jeremie Cormier Trucking Ltd.\n"
                    }
                );
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
            });
    }

    updateValue(event, name, value) {
        if (event.target) {
            event.preventDefault();
            event.stopPropagation();
        }
        if (typeof name == "undefined") {
            name = event.target.name
        }
        if (typeof value == "undefined") {
            value = event.target.value
        }
        this.setState({
            [name]: value
        });
    }

    toggleAttachments(){
        this.setState({
            attachments: !this.state.attachments
        });
    }

    deleteInvoice = () => {
        var self = this;
        axios.defaults.withCredentials = true;
        Swal.fire({
            title: 'Are you sure?',
            text: "This invoice will be permanently deleted and cannot be restored. Any attached loads will be set back to 'Completed' status.",
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it',
            cancelButtonText: 'Cancel',
            type: 'warning'
        }).then((result) => {
            if (result.value) {
                axios.delete(API_ROOT + '/invoice/' + self.props.match.params.invoice_id)
                    .then(function (response) {
                        self.props.history.push("/invoices");
                    })
                    .catch(function (error) {
                        Swal("Error", error.response.data.Message, "error");
                    });
            }
        });
    }

    composeEmail = function (type) {
        this.setState({modal: true, type: type});
    };

    sendEmail = function () {
        if (this.state.to==null || this.state.to=="") {
            Swal("Error", "Please enter a recipient email", "error");
        } else {
            var self = this;
            self.setState({sending:true});
            axios.defaults.withCredentials = true;
            axios.post(API_ROOT + '/invoice_sent/' + self.props.match.params.invoice_id + "/pdf/" + this.state.type + '/' + this.state.attachments, {
                to: this.state.to,
                body: this.state.body,
                bcc: this.state.bcc,
                subject: this.state.subject
            })
                .then(function (response) {
                    Swal("Sent!", "The invoice has been sent", "success");
                    var email_log = self.state.email_log;
                    email_log.push({send_date:moment().format("YYYY-MM-DD HH:mm"), to: self.state.to})
                    self.setState({modal: false, sending: false, email_log: email_log});
                })
                .catch(function (error) {
                    self.setState({sending: false});
                    if (error.response.status === 401) {
                        self.props.userSignOut()
                    } else {
                        Swal("Error", error.response.data.message.response.body.errors[0].message, "error");
                    }
                });
        }
    };

    getPDF = function (type) {
        this.setState({downloading: true});
        var self = this;
        axios.defaults.withCredentials = true;
        axios.get(API_ROOT + '/invoice/' + self.props.match.params.invoice_id + "/pdf/" + type+'/'+this.state.attachments)
            .then(function (response) {
                var element = document.createElement('a');
                element.setAttribute('href', response.data.url);
                if ("download" in document.createElement("a")) {
                    element.setAttribute('download', 'Invoice_' + self.props.match.params.invoice_id + '.pdf');
                }
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
                self.setState({downloading: false});
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
                self.setState({downloading: false});
            });
    };


    render() {
        var subtotal_loads = money.floatToAmount(0);
        var tax_loads = money.floatToAmount(0);
        var total_loads = money.floatToAmount(0);
        var subtotal_manual = money.floatToAmount(0);
        var tax_manual = money.floatToAmount(0);
        var total_manual = money.floatToAmount(0);
        var miles = 0;
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>
                        <Link to="../invoices">Invoices</Link>&nbsp;/&nbsp; Invoice # {this.props.match.params.invoice_id}
                    </div>
                </div>
                <Card className="card card-default">
                    <CardBody>
                        <form className="form-horizontal">
                            <div className="row">
                                <Col>
                                    <FormGroup row>
                                        <Col lg="4" sm="6" className="text-bold">Invoice Date:</Col>
                                        <Col lg="8" sm="6">
                                            {moment(this.state.invoice.invoice_date).format('MMM. D, YYYY')}
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup row>
                                        <Col lg="4" sm="6" className="text-bold">Customer:</Col>
                                        <Col lg="8" sm="6">
                                            {this.state.name}
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </div>
                            <div className="row">
                                <Col>
                                    <FormGroup row>
                                        <Col lg="4" sm="6" className="text-bold">Due Date:</Col>
                                        <Col lg="8" sm="6">
                                            {moment(this.state.invoice.invoice_date).add(this.state.invoice.net_days, 'days').format('MMM. D, YYYY')} (Net {this.state.invoice.net_days})
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup row>
                                        <Col lg="4" sm="6" className="text-bold">PO #:</Col>
                                        <Col lg="8" sm="6">
                                            {this.state.invoice.po}
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </div>
                            <div className="row">
                                <Col>
                                    <FormGroup row>
                                        <Col lg="4" sm="6" className="text-bold">Notes to Customer:</Col>
                                        <Col lg="8" sm="6">
                                            {this.state.invoice.notes_external}
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup row>
                                        <Col lg="4" sm="6" className="text-bold">Internal Notes:</Col>
                                        <Col lg="8" sm="6">
                                            {this.state.invoice.notes_internal}
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </div>
                            <FormGroup row className="pt-2 pb-2">
                                <Col lg="2" sm="3" className="text-bold">Loads:</Col>
                                <Col lg="10" sm="9">
                                    <div className="text-right small">* includes any reverse billing adjustment(s)</div>
                                    <table
                                        className={(this.state.invoice.customer_id == null || Object.keys(this.state.invoice.loads).length == 0 ? "d-none" : "table table-sm table-hover")}>
                                        <thead>
                                        <tr className="thead-light">
                                            <th>Load #</th>
                                            <th>Date</th>
                                            <th className="text-right">Miles</th>
                                            <th className="text-right">Subtotal*</th>
                                            <th className="text-right">Tax*</th>
                                            <th className="text-right">Total</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.invoice.loads.map((load, key) => {
                                            var load_id = load.load_id;
                                            subtotal_loads = money.add(subtotal_loads, money.add(money.floatToAmount(load.total_subtotal), money.floatToAmount(load.reverse_billing_adj)));
                                            tax_loads = money.add(tax_loads, money.floatToAmount(money.floatToAmount(load.total_tax), money.floatToAmount(load.reverse_billing_tax_adj)));
                                            total_loads = money.add(total_loads, money.floatToAmount(load.total_total));
                                            miles = (miles + load.miles);
                                            return (
                                                <tr key={key} onClick={() => this.toggleCheck(load_id)}>
                                                    <td className={(load.checked == 1 ? "bg-light" : "")}>
                                                        <Link to={"../loads/" + load_id}>
                                                            {load_id}
                                                        </Link>
                                                    </td>
                                                    <td className={(load.checked == 1 ? "bg-light" : "")}>
                                                        {moment(load.start).format('MMM. D, YYYY')}
                                                    </td>
                                                    <td className={(load.checked == 1 ? "bg-light text-right" : "text-right")}>
                                                        {money.format("USD", money.floatToAmount(load.miles))}
                                                    </td>
                                                    <td className={(load.checked == 1 ? "bg-light text-right" : "text-right")}>
                                                        ${money.format("USD", money.floatToAmount(load.total_subtotal))}
                                                    </td>
                                                    <td className={(load.checked == 1 ? "bg-light text-right" : "text-right")}>
                                                        ${money.format("USD", money.floatToAmount(load.total_tax))}
                                                    </td>
                                                    <td className={(load.checked == 1 ? "bg-light text-right" : "text-right")}>
                                                        ${money.format("USD", money.floatToAmount(load.total_total))}
                                                    </td>
                                                </tr>)
                                        }, this)}
                                        <tr>
                                            <th colSpan="2" className="text-right">
                                                Total:
                                            </th>
                                            <th className="text-right">
                                                {money.format("USD", money.floatToAmount(miles))}
                                            </th>
                                            <th className="text-right">
                                                ${money.format("USD", subtotal_loads)}
                                            </th>
                                            <th className="text-right">
                                                ${money.format("USD", tax_loads)}
                                            </th>
                                            <th className="text-right">
                                                <b>${money.format("USD", total_loads)}</b>
                                            </th>
                                        </tr>
                                        </tbody>
                                    </table>
                                </Col>
                            </FormGroup>
                            <Row className="pt-2 pb-2">
                                <Col lg="2" sm="3" className="text-bold">Manual Item(s)</Col>
                                <Col lg="10" sm="9">
                                    <table className="table table-sm">
                                        <thead>
                                        <tr className="thead-light">
                                            <th>Description</th>
                                            <th className="text-right">Subtotal</th>
                                            <th className="text-right">Tax</th>
                                            <th className="text-right">Total</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.invoice.manual_items.map((line, key) => {
                                            console.log(line)
                                            var total = money.add(money.floatToAmount(line.subtotal), money.floatToAmount(line.tax));
                                            subtotal_manual = money.add(subtotal_manual, money.floatToAmount(line.subtotal));
                                            tax_manual = money.add(tax_manual, money.floatToAmount(line.tax));
                                            total_manual = money.add(total_manual, total);
                                            return (
                                                <tr key={key}>
                                                    <td>
                                                        {line.description}
                                                    </td>
                                                    <td className="text-right">
                                                        ${money.format("USD", money.floatToAmount(line.subtotal))}
                                                    </td>
                                                    <td className="text-right">
                                                        ${money.format("USD", money.floatToAmount(line.tax))}
                                                    </td>
                                                    <td className="text-right">
                                                        ${money.format("USD", money.floatToAmount(line.total))}
                                                    </td>
                                                </tr>)
                                        }, this)}
                                        </tbody>
                                        <tfoot>
                                        <tr>
                                            <th className="text-right">
                                                Manual Total:
                                            </th>
                                            <th className="text-right">${money.format("USD", subtotal_manual)}</th>
                                            <th className="text-right">${money.format("USD", tax_manual)}</th>
                                            <th className="text-right">${money.format("USD", total_manual)}</th>
                                        </tr>
                                        </tfoot>
                                    </table>
                                </Col>
                            </Row>
                            <Row className="pt-3 pb-3">
                                <Col lg="2" sm="3" className="text-bold">Invoice Total:</Col>
                                <Col lg="10" sm="9">
                                    <Row><Col sm="4" md="3" lg="2">Subtotal:</Col><Col>${money.format("USD", money.add(subtotal_loads, subtotal_manual))}</Col></Row>
                                    <Row><Col sm="4" md="3" lg="2">Tax:</Col><Col>${money.format("USD", money.add(tax_loads, tax_manual))}</Col></Row>
                                    <Row className="text-bold text-danger"><Col sm="3" md="2">Total
                                        Due:</Col><Col>${money.format("USD", money.add(total_loads, total_manual))}</Col></Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="2" sm="3"></Col>
                                <Col lg="10" sm="9">
                                    <div>
                                        <Label className="mb-0 ml-4 mt-2 text-bold">
                                            <Input type="checkbox" name="status" value="1"
                                                   checked={(this.state.attachments)}
                                                   onChange={(event) => this.toggleAttachments()}/>
                                            Include Attachments
                                        </Label>
                                    </div>
                                    <ButtonGroup>
                                        <ButtonGroup>
                                            <UncontrolledDropdown>
                                                <DropdownToggle caret color="primary">
                                                    <i className="fas fa-file-download"></i> Download PDF
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem onClick={() => this.getPDF('full').bind(this)}>
                                                        Full Detail
                                                    </DropdownItem>
                                                    <DropdownItem onClick={() => this.getPDF('summary').bind(this)}>
                                                        Summary Only
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </ButtonGroup>
                                        <ButtonGroup className="ml-1">
                                            <UncontrolledDropdown>
                                                <DropdownToggle caret color="primary">
                                                    <i className="far fa-paper-plane"></i> Email Invoice
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem onClick={() => this.composeEmail('full').bind(this)}>
                                                        Full Detail
                                                    </DropdownItem>
                                                    <DropdownItem onClick={() => this.composeEmail('summary').bind(this)}>
                                                        Summary Only
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </ButtonGroup>
                                    </ButtonGroup>
                                    <div className={(this.state.email_log.length == 0 ? "d-none" : "mt-3 bg-light rounded p-1")}>
                                        <div className="text-bold">Emailed Invoice Log:</div>
                                        <ul className="mb-0">
                                            {this.state.email_log.map((log, key) => {
                                                return (<li>{log.send_date} sent to: {log.to}</li>)
                                            })}
                                        </ul>
                                    </div>
                                    <div className="pt-4">
                                        <Link to={this.props.match.params.invoice_id + "/edit"} className="btn btn-primary btn-xs"><i className="far fa-edit"></i> Edit
                                            Invoice</Link>
                                        <Button color="danger" size="xs" className="float-right" onClick={this.deleteInvoice}><i className="far fa-trash-alt"></i> Delete
                                            Invoice</Button>
                                    </div>
                                </Col>
                            </Row>
                        </form>
                    </CardBody>
                </Card>
                <Modal isOpen={this.state.modal} fade={false} toggle={() => this.setState({modal: !this.state.modal})} size="lg">
                    <ModalHeader>
                        <div className="h3 mborder-0">Email Invoice</div>
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup row className="mb-2">
                            <Label sm={2}>To:</Label>
                            <Col sm={10}>
                                <Input type="text" name="to" placeholder="Email Address (Comma Seperated If Multiple)" value={this.state.to} onChange={this.updateValue}/>
                            </Col>
                        </FormGroup>
                        <FormGroup row className="mb-2">
                            <Label sm={2}>BCC:</Label>
                            <Col sm={10}>
                                <Input type="text" name="bcc" placeholder="Email Address (Comma Seperated If Multiple)" value={this.state.bcc} onChange={this.updateValue}/>
                            </Col>
                        </FormGroup>
                        <FormGroup row className="mb-2">
                            <Label sm={2}>Subject:</Label>
                            <Col sm={10}>
                                <Input type="text" name="subject" value={this.state.subject} onChange={this.updateValue}/>
                            </Col>
                        </FormGroup>
                        <FormGroup row className="mb-2">
                            <Label sm={2}>Body:</Label>
                            <Col sm={10}>
                                <Input type="textarea" style={{height:"200px"}} name="body" value={this.state.body} onChange={this.updateValue}/>
                            </Col>
                        </FormGroup>
                        <FormGroup row className="mb-2">
                            <Label sm={2}></Label>
                            <Col sm={10}>
                                <button type="button" className="btn btn-primary" onClick={() => this.sendEmail()} disabled={this.state.sending}><i className={(this.state.sending?"fas fa-spinner fa-spin mr-2":"d-none")}></i>Send Email</button>
                            </Col>
                        </FormGroup>
                    </ModalBody>
                </Modal>
                <ToastContainer/>
            </ContentWrapper>
        );
    }
}

export default InvoicePage;
