import React, {Component} from 'react';
import {
    Card,
    CardBody,
    Row,
    Col,
    Input,
    UncontrolledButtonDropdown,
    DropdownMenu,
    DropdownToggle,
    DropdownItem,
    ButtonGroup,
    UncontrolledTooltip,
    Button,
    ModalBody, FormGroup, InputGroupAddon, InputGroup, ModalHeader, Modal
} from 'reactstrap';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import 'moment-timezone';
import {toast} from 'react-toastify';
import SingleDatePicker from 'react-bootstrap-daterangepicker';
import Select, {components} from 'react-select';
import {Link} from 'react-router-dom';

import getCustomers from '../Customers/CustomerFunctions';
import {getDrivers} from '../Drivers/DriverFunctions';
import AddLocation from '../Locations/AddLocation';
import getLocations from '../Locations/LocationFunctions';

import AddEquipment from '../Equipment/AddEquipment';
import AddVehicle from '../Vehicles/AddVehicle';

import {getEquipmentDetails, getEquipmentTypes, getEquipmentTypesSub} from "../Equipment/EquipmentFunctions";
import {MapLoad} from "./Map";
import AddAttachment from "./AddAttachment";


moment.tz.setDefault("America/Halifax");

var money = require("money-math");

class LoadDisplay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            load_id: false,
            loading: true,
            loading_equipment: true,
            loading_segments: true,
            page: 'details',
            tender_date: moment(),
            description: "",
            errors: [],
            warnings: [],
            linestring: {
                "type": "Feature",
                "properties": {},
                "geometry": {
                    "type": "MultiLineString",
                    "coordinates": []
                }
            },
            points: [],
            equipment: {},
            equipment_sort: [],
            vehicle_sort: [{options: []}],

            vehicle_id: null,
            vehicle_type_id: null,
            vehicle_types: [],
            vehicle_type_sub_id: null,
            vehicle_types_sub: [],

            equipment_id: null,
            equipment_type_id: null,
            equipment_types: [],
            equipment_type_sub_id: null,
            equipment_types_sub: [],
            attachments: [],

            segments: [{
                segment_num: 0,
                origin: null,
                destination: null,
                start: null,
                distance_miles: "",
                wait_hrs: 0,
                duration_hrs: "",
                distance_miles_computed: null,
                duration_hrs_computed: null,
                show_dimensions: false,
                container_num: "",
                tolls: 0,
                description: "",
                billable: 1,
                weight_load: 0,
                weight_equip: 0,
                height: 0,
                width: 8.5,
                vl: 0,
                weight: 0,
                axles: 0,
                vt: 0,
                vmg: 0,
                vmtr: 0
            }],
            last_segment_check: 0,
            temp_segment: false,
            temp_point: false,
            qty_miles: 0,
            qty_miles_billable: 0,

            driver_id: null,
            drivers: {},
            drivers_sort: [],

            customer_id: null,
            customers: {},
            customers_sort: [],
            cust_load_num: "",
            bol: "",

            location_search_modal: false,
            search_term: "",
            locations: {},
            locations_sort: [],

            avoid_borderCrossing: 1,
            avoid_ferry: 1,
            avoid_minimizeUTurn: 1,

            new_equip_segment_num: 0,

            rate_miles: 0,
            rate_fixed: 0,
            qty_fixed: 1,
            rate_fsc: 0,
            rate_fixed_fsc: 0,
            qty_fsc: 1,
            rate_other: 0,
            qty_other: 1,
            qty_drops: 0,
            rate_drops: 0,
            man_tolls: 1,
            qty_hrs_total: 0,
            qty_hrs_total_billable: 0,
            qty_hrs_wait: 0,
            qty_hrs_wait_billable: 0,
            rate_wait: 0,
            rate_hr: 0,
            rate_tolls: 0,
            rate_tolls_billable: 0,
            tax_miles: 0,
            tax_fixed: 0,
            tax_fsc: 0,
            tax_fixed_fsc: 0,
            tax_other: 0,
            tax_drops: 0,
            tax_wait: 0,
            tax_hr: 0,

            mpg: 0,
            price_per_litre: 0,
            vehicle_cost_km: 0,
            vehicle_cost_day: 0,
            total_vehicle_cost: 0,
            man_total_vehicle_cost: 0,

            total_admin_cost: 0,
            man_total_admin_cost: 0,

            labour_miles: 0,
            labour_hr: 0,
            labour_drops: 0,
            total_labour: 0,
            labour_rates: {},

            reverse_billing_adj: 0,
            reverse_billing_tax_adj: 0,

            last_deleted: 0,

            status: null,
            invoice_id: "",

            trigger_open_equipment: false,
            trigger_open_vehicle: false,
            equipment_search_term: "",

            file: null, // the uploaded file
        };
        this.updateValue = this.updateValue.bind(this);
        this.updatePayment = this.updatePayment.bind(this);
        this.location_search_toggle = this.location_search_toggle.bind(this);
        this.addSegment = this.addSegment.bind(this);
        this.timer = null;
    }

    componentDidMount() {
        var self = this;
        axios.defaults.withCredentials = true;
        getEquipmentTypes(function (vehicle_types) {
            getEquipmentTypesSub(function (vehicle_types_sub) {
                console.log('LoadDisplay')
                getCustomers(function (customers) {
                    getDrivers(function (drivers) {
                        getLocations(function (locations) {
                            var customers_sort = [];
                            for (const customer_id of Object.keys(customers)) {
                                customers_sort.push(customers[customer_id]);
                            }
                            customers_sort = customers_sort.sort(function (a, b) {
                                if (a.name < b.name) {
                                    return -1
                                } else {
                                    return 1
                                }
                            });

                            var drivers_sort = [];
                            for (const driver_id of Object.keys(drivers)) {
                                drivers_sort.push({
                                    value: drivers[driver_id].driver_id,
                                    label: drivers[driver_id].first_name + " " + drivers[driver_id].last_name
                                });
                            }
                            drivers_sort = drivers_sort.sort(function (a, b) {
                                if (a.label < b.label) {
                                    return -1
                                } else {
                                    return 1
                                }
                            });

                            self.setState({
                                locations: locations.locations,
                                locations_sort: locations.locations_sort,
                                drivers: drivers,
                                drivers_sort: drivers_sort,
                                customers: customers,
                                customers_sort: customers_sort,
                                vehicle_types: vehicle_types,
                                vehicle_types_sub: vehicle_types_sub,
                                equipment_types: vehicle_types,
                                equipment_types_sub: vehicle_types_sub,
                            }, function () {
                                self.load_the_load(self.props.load_id);
                            });
                        });
                    });
                });
            });
        });
    }

    componentWillUnmount() {
        clearInterval(this.checkSegmentsInterval);
    }

    componentDidUpdate(prevProps, prevState) {
        if (typeof this.props.load_id != "undefined" && prevProps.load_id !== this.props.load_id && this.state.load_id != false) {
            this.load_the_load(this.props.load_id);
        }
    }

    load_the_load = (load_id) => {
        var self = this;
        if (load_id != false) {
            axios.get(API_ROOT + '/loads/' + load_id)
                .then(function (response) {
                    self.setState({
                        load_id: load_id,
                        loading: false,
                        description: response.data.description,
                        driver_id: response.data.driver_id,
                        customer_id: response.data.customer_id,
                        vehicle_id: response.data.vehicle_id,
                        vehicle_type_id: response.data.vehicle_type_id,
                        vehicle_type_sub_id: response.data.vehicle_type_sub_id,
                        equipment_id: response.data.equipment_id,
                        equipment_type_id: response.data.equipment_type_id,
                        equipment_type_sub_id: response.data.equipment_type_sub_id,
                        cust_load_num: response.data.cust_load_num,
                        bol: response.data.bol,
                        tender_date: (response.data.tender_date == null ? moment() : moment(response.data.tender_date).add(4, 'hours')),
                        attachments: response.data.attachments,
                        avoid_borderCrossing: response.data.avoid_borderCrossing,
                        avoid_minimizeUTurn: response.data.avoid_minimizeUTurn,
                        avoid_ferry: response.data.avoid_ferry,
                        qty_fixed: response.data.qty_fixed,
                        rate_fixed: response.data.rate_fixed,
                        qty_miles: response.data.qty_miles,
                        qty_miles_billable: response.data.qty_miles_billable,
                        rate_miles: response.data.rate_miles,
                        rate_fsc: response.data.rate_fsc,
                        rate_fixed_fsc: response.data.rate_fixed_fsc,
                        qty_fsc: response.data.qty_fsc,
                        rate_other: response.data.rate_other,
                        qty_other: response.data.qty_other,
                        status: response.data.status,
                        invoice_id: response.data.invoice_id,
                        man_tolls: response.data.man_tolls,
                        qty_drops: response.data.qty_drops,
                        rate_drops: response.data.rate_drops,
                        rate_tolls: response.data.rate_tolls,
                        rate_tolls_billable: response.data.rate_tolls_billable,
                        qty_hrs_total_billable: response.data.qty_hrs_total_billable,
                        qty_hrs_total: response.data.qty_hrs_total,
                        rate_hr: response.data.rate_hr,
                        rate_wait: response.data.rate_wait,
                        tax_miles: response.data.tax_miles,
                        tax_fixed: response.data.tax_fixed,
                        tax_fsc: response.data.tax_fsc,
                        tax_fixed_fsc: response.data.tax_fixed_fsc,
                        tax_other: response.data.tax_other,
                        tax_drops: response.data.tax_drops,
                        tax_wait: response.data.tax_wait,
                        tax_hr: response.data.tax_hr,
                        labour_miles: response.data.labour_miles,
                        labour_hr: response.data.labour_hr,
                        labour_drops: response.data.labour_drops,
                        total_labour: response.data.total_labour,
                        labour_rates: response.data.labour_rates,
                        price_per_litre: response.data.price_per_litre,
                        mpg: response.data.mpg,
                        vehicle_cost_day: response.data.vehicle_cost_day,
                        vehicle_cost_km: response.data.vehicle_cost_km,
                        total_vehicle_cost: response.data.total_vehicle_cost,
                        man_total_vehicle_cost: response.data.man_total_vehicle_cost,
                        total_admin_cost: response.data.total_admin_cost,
                        man_total_admin_cost: response.data.man_total_admin_cost,
                        reverse_billing_adj: response.data.reverse_billing_adj,
                        reverse_billing_tax_adj: response.data.reverse_billing_tax_adj

                    }, function () {
                        self.refreshEquipment();
                        self.validate();
                        self.getSegments();
                        self.checkSegmentsInterval = setInterval(self.checkSegments, 2000);
                    });
                })
                .catch(function (error) {
                    if (error.response.status === 401) {
                        self.props.userSignOut()
                    }
                });
        } else {
            self.setState({
                loading: false,
                loading_segments: false,
                status: 0
            }, function () {
                self.checkSegmentsInterval = setInterval(self.checkSegments, 2000);
                self.refreshEquipment();
            });
        }
    }

    refreshEquipment = async event => {
        var self = this;
        self.setState({
            loading_equipment: true,
        });
        getEquipmentDetails(null, (this.state.customer_id == null ? 0 : [0, this.state.customer_id]), null, await function (equipment) {
            self.setState({
                loading_equipment: false,
                equipment: equipment
            }, function () {
                self.equipmentOptions();
                self.vehicleOptions();
            });
            if (self.state.equipment_id != null && self.state.equipment_type_id != null && equipment[self.state.equipment_id].equipment_type_id != self.state.equipment_type_id) {
                self.setState({equipment_type_id: equipment[self.state.equipment_id].equipment_type_id});
            }
            if (self.state.vehicle_id != null && self.state.vehicle_type_id != null && equipment[self.state.vehicle_id].equipment_type_id != self.state.vehicle_type_id) {
                self.setState({vehicle_type_id: equipment[self.state.vehicle_id].equipment_type_id});
            }
            if (self.state.equipment_id != null && self.state.equipment_type_sub_id != null && equipment[self.state.equipment_id].equipment_type_sub_id != self.state.equipment_type_sub_id) {
                self.setState({equipment_type_sub_id: equipment[self.state.equipment_id].equipment_type_sub_id});
            }
            if (self.state.vehicle_id != null && self.state.vehicle_type_sub_id != null && equipment[self.state.vehicle_id].equipment_type_sub_id != self.state.vehicle_type_sub_id) {
                self.setState({vehicle_type_sub_id: equipment[self.state.vehicle_id].equipment_type_sub_id});
            }
            if (self.state.status < 3 && self.state.vehicle_id > 0 && typeof equipment[self.state.vehicle_id] != "undefined") { // if not marked completed or billed, use live data
                self.setState({
                    price_per_litre: equipment[self.state.vehicle_id].ppl,
                    mpg: equipment[self.state.vehicle_id].avg_mpg,
                    vehicle_cost_day: equipment[self.state.vehicle_id].cost_day,
                    vehicle_cost_km: equipment[self.state.vehicle_id].cost_km,
                })
            }
        });
    }

    equipmentOptions = event => {
        var equipment_sort = [];
        var customers = [0];
        if (this.state.customer_id != null) {
            customers.push(this.state.customer_id)
        }
        for (const customer_id of customers) {
            if (customer_id == 0 || typeof this.state.customers[customer_id] != "undefined") {
                var equipment = this.state.equipment;
                var temp = {name: (customer_id == 0 ? 'JCT' : this.state.customers[customer_id].name), options: []};
                for (const equipment_id in equipment) {
                    if (
                        equipment[equipment_id].customer_id == customer_id &&
                        equipment[equipment_id].is_vehicle == 0 &&
                        equipment[equipment_id].status > 0
                    ) {
                        var name = equipment[equipment_id].name;
                        var dwell = (equipment[equipment_id].last_moved == null ? "" : "(" + moment().diff(equipment[equipment_id].last_moved, "days") + ")");
                        var type = " " + this.state.equipment_types[equipment[equipment_id].equipment_type_id].name + ", " + this.state.equipment_types_sub[equipment[equipment_id].equipment_type_sub_id].name;
                        temp.options.push({value: equipment_id, label: name, type: type, status: equipment[equipment_id].status, dwell: dwell})
                    }
                }
                temp.options = temp.options.sort(function (a, b) {
                    if (a.label < b.label) {
                        return -1
                    } else {
                        return 1
                    }
                });
                equipment_sort.push(temp);
            }
        }
        this.setState({
            equipment_sort: equipment_sort
        })
    }

    vehicleOptions = event => {
        var vehicle_sort = [];
        var customers = [0];
        if (this.state.customer_id != null) {
            customers.push(this.state.customer_id)
        }
        for (const customer_id of customers) {
            if (customer_id == 0 || typeof this.state.customers[customer_id] != "undefined") {
                var equipment = this.state.equipment;
                var temp = {name: (customer_id == 0 ? 'Greenhaven' : this.state.customers[customer_id].name), options: []};
                for (const equipment_id of Object.keys(equipment)) {
                    if (
                        equipment[equipment_id].customer_id == customer_id &&
                        equipment[equipment_id].is_vehicle == 1 &&
                        equipment[equipment_id].status != 0 &&
                        (equipment[equipment_id].equipment_type_id == this.state.vehicle_type_id || this.state.vehicle_type_id == null) &&
                        (equipment[equipment_id].equipment_type_sub_id == this.state.vehicle_type_sub_id || this.state.vehicle_type_sub_id == null)
                    ) {
                        var dwell = (equipment[equipment_id].last_moved == null ? "" : "(" + moment().diff(equipment[equipment_id].last_moved, "days") + ")");
                        temp.options.push({value: equipment[equipment_id].equipment_id, label: equipment[equipment_id].name, status: equipment[equipment_id].status, dwell: dwell})
                    }
                }
                temp.options = temp.options.sort(function (a, b) {
                    if (a.label < b.label) {
                        return -1
                    } else {
                        return 1
                    }
                });
                vehicle_sort.push(temp);
            }
        }
        this.setState({
            vehicle_sort: vehicle_sort
        })
    }

    checkSegments = event => {
        var trigger_check = false;
        var points = [];
        var segment_num = 0;
        var num_segments = this.state.segments.length;
        var qty_miles = money.floatToAmount(0);
        var qty_miles_billable = money.floatToAmount(0);
        var qty_hrs_wait = money.floatToAmount(0);
        var qty_hrs_wait_billable = money.floatToAmount(0);
        var qty_hrs_total = money.floatToAmount(0);
        var qty_hrs_total_billable = money.floatToAmount(0);
        var rate_tolls = money.floatToAmount(0);
        var rate_tolls_billable = money.floatToAmount(0);
        for (const segment of this.state.segments) {
            var origin = segment.origin;
            var destination = segment.destination;
            var locations = this.state.locations;
            if (segment.distance_miles_computed == null && origin != null && destination != null && segment.start != null && origin != destination) {
                trigger_check = true;
            }
            if (origin != null && typeof locations[origin] != "undefined") {
                points.push({
                    lat: locations[origin].lat,
                    lon: locations[origin].lon,
                    name: locations[origin].name,
                    address: locations[origin].address,
                    type: (segment_num == 0 ? "start" : "reg")
                })
            }
            if (destination != null && typeof locations[destination] != "undefined") {
                points.push({
                    lat: locations[destination].lat,
                    lon: locations[destination].lon,
                    name: locations[destination].name,
                    address: locations[destination].address,
                    type: (segment_num + 1 == num_segments ? "end" : "reg")
                })
            }
            if (segment.distance_miles != null) {
                if (segment.billable == 1) {
                    qty_miles_billable = money.add(qty_miles_billable, money.floatToAmount(segment.distance_miles));
                }
                qty_miles = money.add(qty_miles, money.floatToAmount(segment.distance_miles));
            }
            rate_tolls = money.add(rate_tolls, money.floatToAmount(segment.tolls));
            if (segment.billable == 1) {
                rate_tolls_billable = money.add(rate_tolls_billable, money.floatToAmount(segment.tolls));
            }
            if (segment.wait_hrs != null) {
                if (segment.billable == 1 && segment.wait_hrs > 2) {
                    var hrs_wait_billable = segment.wait_hrs - 2;
                    qty_hrs_wait_billable = money.add(qty_hrs_wait_billable, money.floatToAmount(hrs_wait_billable));
                    //qty_hrs_total_billable = money.add(qty_hrs_total_billable, money.floatToAmount(segment.wait_hrs));
                }
                qty_hrs_wait = money.add(qty_hrs_wait, money.floatToAmount(segment.wait_hrs));
                //qty_hrs_total = money.add(qty_hrs_total, money.floatToAmount(segment.wait_hrs));
            }
            if (segment.duration_hrs != null) {
                if (segment.billable == 1) {
                    qty_hrs_total_billable = money.add(qty_hrs_total_billable, money.floatToAmount(segment.duration_hrs));
                }
                qty_hrs_total = money.add(qty_hrs_total, money.floatToAmount(segment.duration_hrs));
            }
            segment_num++;
        }
        if (JSON.stringify(points) != JSON.stringify(this.state.points)) {
            this.setState({
                    points: points,
                }
            );
        }
        if (!money.isEqual(qty_miles, money.floatToAmount(this.state.qty_miles))) {
            this.setState({
                qty_miles: qty_miles
            });
        }
        if (!money.isEqual(qty_miles_billable, money.floatToAmount(this.state.qty_miles_billable))) {
            this.setState({
                qty_miles_billable: qty_miles_billable
            });
        }
        if (!money.isEqual(qty_hrs_wait, money.floatToAmount(this.state.qty_hrs_wait))) {
            this.setState({
                qty_hrs_wait: qty_hrs_wait
            });
        }
        if (!money.isEqual(qty_hrs_wait_billable, money.floatToAmount(this.state.qty_hrs_wait_billable))) {
            this.setState({
                qty_hrs_wait_billable: qty_hrs_wait_billable
            });
        }
        if (!money.isEqual(qty_hrs_total, money.floatToAmount(this.state.qty_hrs_total))) {
            this.setState({
                qty_hrs_total: qty_hrs_total
            });
        }
        if (!money.isEqual(qty_hrs_total_billable, money.floatToAmount(this.state.qty_hrs_total_billable))) {
            this.setState({
                qty_hrs_total_billable: qty_hrs_total_billable
            });
        }

        if (this.state.man_tolls != 1) {
            if (rate_tolls_billable != this.state.rate_tolls_billable) {
                this.setState({
                    rate_tolls_billable: rate_tolls_billable
                });
            }
        }
        if (this.state.man_total_vehicle_cost != 1) {
            var total_vehicle_cost = money.mul(money.floatToAmount(this.state.vehicle_cost_km), money.floatToAmount(this.state.qty_miles * 1.609344));
            if (parseFloat(this.state.qty_hrs_total) + parseFloat(this.state.qty_hrs_wait) > 12) {
                total_vehicle_cost = money.add(total_vehicle_cost, money.floatToAmount(this.state.vehicle_cost_day));
            } else {
                total_vehicle_cost = money.add(total_vehicle_cost, money.floatToAmount(this.state.vehicle_cost_day / 2));
            }
            if (total_vehicle_cost != this.state.total_vehicle_cost) {
                console.log('updating total_vehicle_cost')
                this.setState({
                    total_vehicle_cost: total_vehicle_cost
                });
            }
        }
        if (this.state.man_total_admin_cost != 1 && this.state.status != 4) {
            if (parseFloat(this.state.qty_hrs_total) + parseFloat(this.state.qty_hrs_wait) > 12) {
                var total_admin_cost = money.floatToAmount(50);
            } else {
                var total_admin_cost = money.floatToAmount(50 / 2);
            }
            if (total_admin_cost != this.state.total_admin_cost) {
                console.log('updating total_admin_cost')
                this.setState({
                    total_admin_cost: total_admin_cost
                });
            }
        }
        if (rate_tolls != this.state.rate_tolls) {
            this.setState({
                rate_tolls: rate_tolls
            });
        }
        if (trigger_check) {
            this.getSegments();
        }
    }
    getSegments = () => {
        console.log('getSegments')
        var current_timestamp = moment().unix();
        if (current_timestamp - this.state.last_segment_check > 2) {
            this.setState({last_segment_check: current_timestamp});
            if (this.state.load_id > 0) {
                var self = this;
                var current_segments = this.state.segments;
                axios.defaults.withCredentials = true;
                axios.get(API_ROOT + '/loads/' + self.state.load_id + "/segments")
                    .then(function (response) {
                        var linestring = {
                            "type": "Feature",
                            "properties": {},
                            "geometry": {
                                "type": "MultiLineString",
                                "coordinates": []
                            }
                        };
                        for (const segment of response.data) {
                            if (segment.route != null) {
                                var route = JSON.parse(segment.route)
                                linestring.geometry.coordinates.push(route.coordinates);
                            }
                            if (typeof current_segments[segment.segment_num] != "undefined") {
                                response.data[segment.segment_num].show_dimensions = current_segments[segment.segment_num].show_dimensions;
                            }
                        }
                        self.setState({
                                segments: response.data,
                                loading_segments: false,
                                linestring: linestring
                            }
                        );
                    })
                    .catch(function (error) {
                        if (error.response.status === 401) {
                            self.props.userSignOut()
                        }
                    });
            }
        }
    }

    updateField = (name, value) => {
        if (value === true) {
            value = 1
        } else if (value === false) {
            value = 0
        } else if (typeof value != "string") {
            value = value.target.value;
        }
        var self = this;
        this.setState({
            [name]: value
        }, function () {
            self.autoSave(name)
        });
    };

    updateFieldSegment = (segment_num, name, value) => {
        if (typeof value != "undefined") {
            var segments = this.state.segments;
            if (name == "billable") {
                value = (segments[segment_num].billable == 1 ? 0 : 1);
            }
            if (value === true) {
                value = 1
            } else if (value === false) {
                value = 0
            } else if (typeof value != "string" && typeof value.target != "undefined") {
                value = value.target.value;
            }
            if (!isNaN(value)) {
                value = parseFloat(value);
            }
            segments[segment_num][name] = value
            var self = this;
            this.setState({
                segments: segments
            }, function () {
                if (name != "show_dimensions") {
                    self.autoSave(name)
                }
                if (name == "description" || name == "container_num") {
                    self.promptApplyAll(name, value, segment_num);
                }
            });
        }
    };

    updateTenderDate = (event, picker) => {
        if (event.type == "apply") {
            this.setState({
                tender_date: moment(picker.startDate),
            }, function () {
                this.sendSave('tender_date')
            });
        }
    }

    changeCustomer = (selectedOption) => {
        if (selectedOption == null || typeof selectedOption.value == "undefined") {
            var customer_id = selectedOption; // used in callback function
        } else {
            var customer_id = selectedOption.value;
        }
        var self = this;
        this.setState({
            customer_id: customer_id,
            rate_fixed: self.state.customers[customer_id].rate_fixed,
            rate_miles: self.state.customers[customer_id].rate_miles,
            rate_hr: self.state.customers[customer_id].rate_hr,
            rate_wait: self.state.customers[customer_id].rate_wait,
            //rate_fsc: self.state.customers[customer_id].rate_fsc,
            rate_fixed_fsc: self.state.customers[customer_id].rate_fixed_fsc,
            rate_other: self.state.customers[customer_id].rate_other,
            rate_drops: self.state.customers[customer_id].rate_drops,
            tax_fixed: self.state.customers[customer_id].tax_fixed,
            tax_miles: self.state.customers[customer_id].tax_miles,
            tax_fixed_fsc: self.state.customers[customer_id].tax_fixed_fsc,
            tax_fsc: self.state.customers[customer_id].tax_fsc,
            tax_other: self.state.customers[customer_id].tax_other,
            tax_drops: self.state.customers[customer_id].tax_drops,
            tax_wait: self.state.customers[customer_id].tax_wait,
            tax_hr: self.state.customers[customer_id].tax_hr
        }, function () {
            self.refreshEquipment();
            self.equipmentOptions();
            self.vehicleOptions();
            self.sendSave('customer_id')
        });
    };


    changeDriver = (segment_num, selectedOption) => {
        if (selectedOption == null || typeof selectedOption.value == "undefined") {
            var driver_id = selectedOption; // used in callback function
        } else {
            var driver_id = selectedOption.value;
        }
        var segments = this.state.segments;
        segments[segment_num].driver_id = driver_id;
        var labour_rates = this.state.labour_rates;
        if (typeof labour_rates[driver_id] == "undefined") {
            labour_rates[driver_id] = {
                rate_miles: this.state.drivers[driver_id].rate_miles,
                rate_hr: this.state.drivers[driver_id].rate_hr,
                rate_drops: 0
            }
        }
        this.setState({
            segments: segments,
            labour_rates: labour_rates
        }, function () {
            this.populateDimensions();
            this.sendSave('driver_id');
            this.promptApplyAll('driver_id', driver_id, segment_num);
        });
    };

    changeVehicleType = (vehicle_type_id) => {
        this.setState({
            vehicle_type_id: vehicle_type_id,
            vehicle_type_sub_id: null,
            vehicle_id: null
        }, function () {
            this.vehicleOptions();
            this.populateDimensions();
            this.sendSave('vehicle_type_id');
        });
    };

    changeVehicleTypeSub = (vehicle_type_sub_id) => {
        this.setState({
            vehicle_type_sub_id: vehicle_type_sub_id,
            vehicle_id: null
        }, function () {
            this.vehicleOptions();
            this.populateDimensions();
            this.sendSave('vehicle_type_sub_id');
        });
    }

    changeVehicle = (selectedOption) => {
        if (selectedOption == null || typeof selectedOption.value == "undefined") {
            var vehicle_id = selectedOption; // used in callback function
        } else {
            var vehicle_id = selectedOption.value;
        }
        this.setState({
            mpg: (typeof this.state.equipment[vehicle_id] == "undefined" ? 0 : this.state.equipment[vehicle_id].avg_mpg),
            price_per_litre: (typeof this.state.equipment[vehicle_id] == "undefined" ? 0 : this.state.equipment[vehicle_id].ppl),
            vehicle_cost_km: (typeof this.state.equipment[vehicle_id] == "undefined" ? 0 : this.state.equipment[vehicle_id].cost_km),
            vehicle_cost_day: (typeof this.state.equipment[vehicle_id] == "undefined" ? 0 : this.state.equipment[vehicle_id].cost_day),
            vehicle_id: vehicle_id,
            vehicle_type_id: (vehicle_id == null ? this.state.vehicle_type_id : this.state.equipment[vehicle_id].equipment_type_id),
            vehicle_type_sub_id: (vehicle_id == null ? this.state.vehicle_type_sub_id : this.state.equipment[vehicle_id].equipment_type_sub_id)
        }, function () {
            this.populateDimensions();
            this.sendSave('vehicle_id');
        });
    };

    newVehicleCallback = async (equipment_id) => {
        this.refreshEquipment();
        var self = this;
        setInterval(function () {
            self.changeVehicle(equipment_id)
        }, 1500);
    }

    promptApplyAll = (field, value, segment_num) => {
        var self = this;
        Swal.fire({
            title: 'Apply to Other Trips?',
            showCancelButton: true,
            confirmButtonText: '<i class="fas fa-arrows-alt-v"></i> All Trips',
            cancelButtonText: '<i class="fas fa-long-arrow-alt-down"></i> Trips Below',
            cancelButtonColor: '#005bbb',
            type: 'question',
            animation: false,
            toast: true,
            timer: 3000
        }).then((result) => {
            if (result.dismiss != 'timer') {
                var segments = self.state.segments;
                for (var key in segments) {
                    if (result.value || key >= segment_num) {
                        segments[key][field] = value;
                    }
                }
                self.setState({
                    segments: segments
                }, function () {
                    self.sendSave(field)
                });
            }
        });
    }

    changeEquipment = (segment_num, selectedOption) => {
        if (selectedOption == null || typeof selectedOption.value == "undefined") {
            var equipment_id = selectedOption; // used in callback function
        } else {
            var equipment_id = selectedOption.value;
        }
        var segments = this.state.segments;
        segments[segment_num].equipment_id = equipment_id
        this.setState({
            segments: segments,
        }, function () {
            this.populateDimensions();
            this.sendSave('equipment_id');
            this.promptApplyAll('equipment_id', equipment_id, segment_num);
        });
    };

    newEquipmentCallback = async (segment_num, equipment_id) => {
        await this.refreshEquipment();
        var self = this;
        setTimeout(function () {
            self.changeEquipment(segment_num, equipment_id)
        }, 1500);
    }

    populateDimensions = () => {
        var segments = this.state.segments;
        for (const segment of segments) {
            var width = 0;
            var vl = 0;
            var height = 0;
            var weight_equip = 0;
            var axles = 0;
            var vt = 0;
            var vmg = 20;
            var vmtr = 41;
            if (this.state.vehicle_type_sub_id == 1) { // day cab
                vl = 10.5;
                height = 10;
                width = 9;
                weight_equip = 7250;
                axles = 3;
            } else if (this.state.vehicle_type_sub_id == 2 || this.state.vehicle_type_sub_id == 1) { // sleeper cab or tractor unit
                vl = 15.5;
                height = 13.5;
                width = 9;
                weight_equip = 9000;
                axles = 3;
            }
            if (this.state.vehicle_id != null && typeof this.state.equipment[this.state.vehicle_id] != "undefined") {
                if (this.state.equipment[this.state.vehicle_id].length_inches != null) {
                    vl = Math.round(this.state.equipment[this.state.vehicle_id].length_inches / 12 * 10) / 10;
                }
                if (this.state.equipment[this.state.vehicle_id].height_inches != null) {
                    height = Math.round(this.state.equipment[this.state.vehicle_id].height_inches / 12 * 10) / 10;
                }
                if (this.state.equipment[this.state.vehicle_id].width_inches != null) {
                    width = Math.round(this.state.equipment[this.state.vehicle_id].width_inches / 12 * 10) / 10;
                }
                if (this.state.equipment[this.state.vehicle_id].weight_empty_kg != null) {
                    weight_equip = this.state.equipment[this.state.vehicle_id].weight_empty_kg;
                }
                if (this.state.equipment[this.state.vehicle_id].axles != null) {
                    axles = this.state.equipment[this.state.vehicle_id].axles;
                }
            }
            if (segment.equipment_id != null && typeof this.state.equipment[segment.equipment_id] != "undefined") {
                if (this.state.equipment[segment.equipment_id].length_inches != null) {
                    vl = vl + Math.round(this.state.equipment[segment.equipment_id].length_inches / 12 * 10) / 10;
                }
                if (this.state.equipment[segment.equipment_id].height_inches != null) {
                    var temp = Math.round(this.state.equipment[segment.equipment_id].height_inches / 12 * 10) / 10;
                    if (temp > height) {
                        height = temp;
                    }
                }
                if (this.state.equipment[segment.equipment_id].width_inches != null) {
                    var temp = Math.round(this.state.equipment[segment.equipment_id].width_inches / 12 * 10) / 10;
                    if (temp > width) {
                        width = temp;
                    }
                }
                if (this.state.equipment[segment.equipment_id].weight_empty_kg != null) {
                    weight_equip = weight_equip + this.state.equipment[segment.equipment_id].weight_empty_kg;
                }
                if (this.state.equipment[segment.equipment_id].axles != null) {
                    axles = axles + this.state.equipment[segment.equipment_id].axles;
                }
            }
            if (typeof this.state.equipment[segment.equipment_id] != "undefined" && segment.equipment_id != null && (this.state.equipment[segment.equipment_id].equipment_type_id == 5 || this.state.equipment[segment.equipment_id].equipment_type_id == 6)) { // if chassis or container, add 3' for spacing
                vl = vl + 3;
            }
            if (segment.equipment_id != null) {
                vt = 1
            }
            segments[segment.segment_num]['weight_equip'] = weight_equip;
            segments[segment.segment_num]['height'] = height;
            segments[segment.segment_num]['width'] = width;
            segments[segment.segment_num]['vl'] = vl;
            segments[segment.segment_num]['axles'] = axles;
            segments[segment.segment_num]['vt'] = vt;
            segments[segment.segment_num]['vmg'] = vmg;
            segments[segment.segment_num]['vmtr'] = vmtr;
        }
        this.setState({
            segments: segments
        });
    }

    updateValue(event, name, value) {
        if (event.target) {
            event.preventDefault();
            event.stopPropagation();
        }
        if (typeof name == "undefined") {
            name = event.target.name
        }
        if (typeof value == "undefined") {
            value = event.target.value
        }
        if (name == "total_vehicle_cost") {
            this.setState({man_total_vehicle_cost: 1})
        }
        if (name == "total_admin_cost") {
            this.setState({man_total_admin_cost: 1})
        }
        if (name == "rate_tolls" || name == "rate_tolls_billable") {
            this.setState({man_tolls: 1})
        }
        this.setState({
            [name]: value
        }, function () {
            if (name != "page" && name != "search_term") {
                this.autoSave(name)
            }
        });
    }

    updatePayment(driver_id, name, event) {
        if (event.target) {
            event.preventDefault();
            event.stopPropagation();
        }
        var value = event.target.value;
        var labour_rates = this.state.labour_rates;
        labour_rates[driver_id][name] = value;
        this.setState({
            labour_rates: labour_rates
        }, function () {
            this.autoSave(name)
        });
    }

    location_search_toggle = (segment_num, point) => {
        this.setState({
            temp_segment: segment_num,
            temp_point: point,
            search_term: "",
            location_search_modal: !this.state.location_search_modal
        }, function () {
            // set focus TODO
        });
    };

    updateLocation(segment, point, location_id) {
        var segments = this.state.segments;
        var self = this;
        getLocations(function (locations) {
            if (typeof segments[segment] != "undefined") {
                segments[segment][point] = location_id;
                segments[segment].distance_miles = "";
                segments[segment].duration_hrs = "";
                segments[segment].wait_hrs = 0;
                segments[segment].distance_miles_computed = null;
                segments[segment].duration_hrs_computed = null;
                self.setState({
                    locations: locations.locations,
                    locations_sort: locations.locations_sort,
                    segments: segments,
                    location_search_modal: false
                }, function () {
                    self.sendSave('location_id')
                });
            }
        });
    }

    changeStatus = (status) => {
        var self = this;
        axios.defaults.withCredentials = true;
        if (status == -1) {
            Swal.fire({
                title: 'Are you sure?',
                text: "This load will be permanently deleted and cannot be restored",
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then((result) => {
                if (result.value) {
                    axios.delete(API_ROOT + '/loads/' + this.state.load_id + "/" + moment().unix())
                        .then(function (response) {
                            if (typeof self.props.history != "undefined") {
                                self.props.history.push("/loads");
                            } else {
                                window.location.reload(true);
                            }
                        })
                }
            });
        } else if (status == 0 || status == 2 || status == 3 || this.state.errors.length == 0) {
            self.setState({
                status: status
            }, function () {
                this.sendSave('status');
            });
        } else {
            Swal.fire({
                title: 'Warning',
                text: "There are errors identified with this load. Are you sure you want to change the status?",
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                type: 'warning'
            }).then((result) => {
                if (result.value) {
                    self.setState({
                        status: status
                    }, function () {
                        this.sendSave('status');
                    });
                }
            });
        }
    }

    location_saved = (location_id, name, address, city) => {
        var locations = this.state.locations;
        var self = this;
        locations[location_id] = {name: name, address: address, city: city}
        this.setState({
            location_search_modal: false,
            locations: locations
        }, function () {
            self.updateLocation(self.state.temp_segment, self.state.temp_point, location_id);
        });
    }

    startSaved = (event, picker) => {
        if (event.type == "apply") {
            var rx = /id="(.*?)"/g;
            var arr = rx.exec(event.target.innerHTML);
            var id = arr[1];
            var segments = this.state.segments;
            segments[id].start = picker.startDate
            this.setState({
                segments: segments,
            }, function () {
                this.sendSave('start')
            });
        }
    }

    endSaved = (event, picker) => {
        if (event.type == "apply") {
            var rx = /id="(.*?)"/g;
            var arr = rx.exec(event.target.innerHTML);
            var id = arr[1];
            var segments = this.state.segments;
            segments[id].start = picker.startDate.subtract(segments[id].wait_hrs, 'hours').subtract(segments[id].duration_hrs, 'hours');
            this.setState({
                segments: segments,
            }, function () {
                this.sendSave('start')
            });
        }
    }

    addSegment = (is_reverse) => {
        var segments = this.state.segments;
        var num_segments = segments.length;
        var start = null;
        if (num_segments > 0 && this.state.segments[num_segments - 1].start != null && this.state.segments[num_segments - 1].duration_hrs_computed != null) {
            start = moment(this.state.segments[num_segments - 1].start).add((Math.round((this.state.segments[num_segments - 1].duration_hrs_computed + this.state.segments[num_segments - 1].wait_hrs) * 60 / 10) * 10) + 10, 'minutes').format('MMM. D/YY HH:mm:00');
        }
        segments.push({
            segment_num: num_segments,
            origin: (num_segments > 0 ? this.state.segments[num_segments - 1].destination : null),
            destination: (is_reverse ? this.state.segments[num_segments - 1].origin : null),
            start: start,
            equipment_id: (num_segments > 0 ? this.state.segments[num_segments - 1].equipment_id : null),
            distance_miles: "",
            duration_hrs: "",
            wait_hrs: 0,
            description: (is_reverse ? "Return trip" : ""),
            billable: 1,
            tolls: 0,
            show_dimensions: (num_segments > 0 ? this.state.segments[num_segments - 1].show_dimensions : null),
            distance_miles_computed: null,
            duration_hrs_computed: null,
            weight_load: 0,
            weight_equip: 0,
            height: 0,
            width: 8.5,
            vl: 0,
            weight: (num_segments > 0 ? this.state.segments[num_segments - 1].axles : 0),
            axles: 0,
            vt: 0,
            vmg: 0,
            vmtr: 0
        });
        this.setState({
            segments: segments
        }, function () {
            this.populateDimensions()
            this.sendSave('segments')
        });
    }

    deleteSegment = (segment_id) => {
        var self = this;
        axios.defaults.withCredentials = true;
        if (moment().unix() - this.state.last_deleted < 10) {
            this.setState({
                last_deleted: moment().unix()
            });
            axios.delete(API_ROOT + '/loads/' + this.state.load_id + "/segment/" + segment_id)
                .then(function (response) {
                    self.getSegments();
                })
                .catch(function (error) {
                    Swal("Error4", error.response.data.Message, "error");
                });
        } else {
            Swal.fire({
                title: 'Are you sure?',
                text: "This segment will be permanently deleted and cannot be restored",
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then((result) => {
                if (result.value) {
                    self.setState({
                        last_deleted: moment().unix()
                    });
                    axios.delete(API_ROOT + '/loads/' + this.state.load_id + "/segment/" + segment_id)
                        .then(function (response) {
                            self.getSegments();
                        })
                        .catch(function (error) {
                            Swal("Error4", error.response.data.Message, "error");
                        });
                }
            });
        }
    }

    autoSave = (change_type) => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => this.sendSave(change_type), 1500);
    }

    sendSave = (change_type) => {
        var segments = JSON.parse(JSON.stringify(this.state.segments));
        if (this.state.load_id > 0 || this.state.segments[0].start != null) {
            var self = this;
            var req_id = (Math.random() + 1).toString(36).substring(7);
            axios.defaults.withCredentials = true;
            axios.post(API_ROOT + '/loads/' + this.state.load_id, {
                errors: this.state.errors,
                warnings: this.state.warnings,
                customer_id: this.state.customer_id,
                segments: segments,
                driver_id: this.state.driver_id,
                description: this.state.description,
                vehicle_type_id: this.state.vehicle_type_id,
                vehicle_type_sub_id: this.state.vehicle_type_sub_id,
                vehicle_id: this.state.vehicle_id,
                equipment_type_id: this.state.equipment_type_id,
                equipment_type_sub_id: this.state.equipment_type_sub_id,
                equipment_id: this.state.equipment_id,
                cust_load_num: this.state.cust_load_num,
                bol: this.state.bol,
                avoid_borderCrossing: this.state.avoid_borderCrossing,
                avoid_minimizeUTurn: this.state.avoid_minimizeUTurn,
                avoid_ferry: this.state.avoid_ferry,
                rate_miles: parseFloat(this.state.rate_miles),
                rate_fixed: parseFloat(this.state.rate_fixed),
                qty_fixed: parseFloat(this.state.qty_fixed),
                rate_fsc: parseFloat(this.state.rate_fsc),
                rate_fixed_fsc: parseFloat(this.state.rate_fixed_fsc),
                qty_fsc: parseFloat(this.state.qty_fsc),
                rate_other: parseFloat(this.state.rate_other),
                rate_tolls_billable: parseFloat(this.state.rate_tolls_billable),
                rate_hr: parseFloat(this.state.rate_hr),
                rate_wait: parseFloat(this.state.rate_wait),
                rate_drops: parseFloat(this.state.rate_drops),
                qty_drops: parseFloat(this.state.qty_drops),
                qty_other: parseFloat(this.state.qty_other),
                status: this.state.status,
                tax_miles: parseFloat(this.state.tax_miles),
                tax_fixed: parseFloat(this.state.tax_fixed),
                tax_fsc: parseFloat(this.state.tax_fsc),
                tax_fixed_fsc: parseFloat(this.state.tax_fixed_fsc),
                tax_other: parseFloat(this.state.tax_other),
                tax_drops: parseFloat(this.state.tax_drops),
                tax_wait: parseFloat(this.state.tax_wait),
                tax_hr: parseFloat(this.state.tax_hr),
                qty_hrs_total: parseFloat(this.state.qty_hrs_total),
                qty_hrs_total_billable: parseFloat(this.state.qty_hrs_total_billable),
                qty_hrs_wait: parseFloat(this.state.qty_hrs_wait),
                qty_hrs_wait_billable: parseFloat(this.state.qty_hrs_wait_billable),
                labour_rates: this.state.labour_rates,
                //labour_rates: parseFloat(this.state.labour_rate_miles == null ? 0 : this.state.labour_rate_miles),
                //labour_rate_hr: parseFloat(this.state.labour_rate_hr == null ? 0 : this.state.labour_rate_hr),
                //labour_rate_drops: parseFloat(this.state.labour_rate_drops == null ? 0 : this.state.labour_rate_drops),
                req_id: req_id,
                price_per_litre: parseFloat(this.state.price_per_litre),
                mpg: parseFloat(this.state.mpg),
                vehicle_cost_day: parseFloat(this.state.vehicle_cost_day),
                vehicle_cost_km: parseFloat(this.state.vehicle_cost_km),
                total_vehicle_cost: parseFloat(this.state.total_vehicle_cost),
                man_total_vehicle_cost: this.state.man_total_vehicle_cost,
                total_admin_cost: parseFloat(this.state.total_admin_cost),
                man_total_admin_cost: this.state.man_total_admin_cost,
                man_tolls: this.state.man_tolls,
                change_type: change_type
            })
                .then(function (response) {
                    var old_state = self.state;
                    self.validate()
                    self.setState({
                        load_id: response.data.load_id,
                        qty_miles: response.data.totals.qty_miles,
                        qty_drops: response.data.totals.qty_drops,
                        total_fixed_rate: response.data.totals.total_fixed_rate,
                        total_variable_rate: response.data.totals.total_variable_rate,
                        total_variable_fsc: response.data.totals.total_variable_fsc,
                        total_fixed_fsc: response.data.totals.total_fixed_fsc,
                        total_other: response.data.totals.total_other,
                        total_subtotal: response.data.totals.total_subtotal,
                        total_tax: response.data.totals.total_tax,
                        total_total: response.data.totals.total_total,
                        labour_miles: response.data.totals.labour_miles,
                        labour_hr: response.data.totals.labour_hr,
                        labour_drops: response.data.totals.labour_drops,
                        total_labour: response.data.totals.total_labour,
                        total_margin: response.data.totals.total_margin,
                        total_vehicle_cost: response.data.totals.total_vehicle_cost,
                        total_admin_cost: response.data.totals.total_admin_cost,
                        qty_miles_billable: response.data.totals.qty_miles_billable,
                        rate_tolls_billable: response.data.totals.rate_tolls_billable,
                        qty_hrs_total: response.data.totals.qty_hrs_total,
                        qty_hrs_total_billable: response.data.totals.qty_hrs_total_billable,
                        rate_tolls: response.data.totals.rate_tolls,
                        labour_rates: response.data.totals.labour_rates,
                        rate_fsc: response.data.totals.rate_fsc,
                    });
                    self.getSegments();
                    window.history.pushState({}, null, "/loads/" + response.data.load_id);
                    if (response.data.req_id == req_id) {
                        toast.success("Changes Saved", {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                    }
                    if (old_state.load_id != response.data.load_id) {
                        console.log('triggering callback')
                        self.props.new_load_callback(response.data.load_id)
                    }
                })
                .catch(function (error) {
                    if (typeof error.response != "undefined") {
                        if (error.response.status === 401) {
                            self.props.userSignOut()
                        } else {
                            Swal("Error5", error.response.data.Message, "error");
                        }
                    }
                });
        }
    }

    validate = () => {
        if (this.state.load_id > 0) {
            var self = this;
            axios.get(API_ROOT + '/loads/' + this.state.load_id + "/validate")
                .then(function (response) {
                    self.setState({
                        errors: response.data.errors,
                        warnings: response.data.warnings
                    });
                })
                .catch(function (error) {
                    if (error.response.status === 401) {
                        self.props.userSignOut()
                    } else if (typeof error.response.data.Message != "undefined") {
                        Swal("Error", error.response.data.Message, "error");
                    }
                });
        }
    }

    attachmentCallback = (details) => {
        this.setState({
            file: this.state.attachments.push(details)
        });
    };
    getAttachment = (name, hash) => {
        var element = document.createElement('a');
        element.setAttribute('href', "attachments/" + hash);
        if ("download" in document.createElement("a")) {
            element.setAttribute('download', name);
        }
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    };
    removeAttachment = (name, attachment_id) => {
        var self = this;
        axios.defaults.withCredentials = true;
        Swal.fire({
            title: 'Are you sure?',
            text: name+" will be removed from this load",
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            axios.delete(API_ROOT + '/loads/' + this.state.load_id + '/attachment/'+attachment_id)
                .then(function (response) {
                    self.load_the_load(self.props.load_id);
                })
        });
    };

    // Start Select specific
    formatGroupLabel = (data) => (
        <div className="justify-content border-bottom">
            <span className="text-muted">{data.name}</span>
            <div className="small float-right"><span className="badge badge-secondary" style={{padding: "2px"}}>{data.options.length}</span></div>
        </div>
    );

    render() {
        var total_fixed_rate = money.mul(money.floatToAmount(this.state.rate_fixed), money.floatToAmount(this.state.qty_fixed));
        var total_variable_rate = money.mul(money.floatToAmount(this.state.rate_miles), money.floatToAmount(this.state.qty_miles_billable));
        var total_hourly = (
            this.state.rate_hr == 0 ? money.floatToAmount(0) :
                money.mul(
                    money.floatToAmount(this.state.rate_hr),
                    money.floatToAmount(this.state.qty_hrs_total_billable)
                )
        );
        var total_wait = (this.state.rate_wait == 0 ? money.floatToAmount(0) : money.mul(money.floatToAmount(this.state.rate_wait), money.floatToAmount(this.state.qty_hrs_wait_billable)));
        var total_base_charge = money.add(total_wait, money.add(total_hourly, money.add(total_fixed_rate, total_variable_rate)));
        var total_variable_fsc = (this.state.rate_fsc == 0 ? money.floatToAmount(0) : money.mul(money.floatToAmount(this.state.rate_fsc), (money.div(total_base_charge, money.floatToAmount(100)))));
        var total_fixed_fsc = (this.state.rate_fixed_fsc == 0 ? money.floatToAmount(0) : money.mul(money.floatToAmount(this.state.rate_fixed_fsc), money.floatToAmount(this.state.qty_fsc)));
        var total_other = (this.state.rate_other == 0 ? money.floatToAmount(0) : money.mul(money.floatToAmount(this.state.rate_other), money.floatToAmount(this.state.qty_other)));
        var total_drops = (this.state.rate_drops == 0 ? money.floatToAmount(0) : money.mul(money.floatToAmount(this.state.rate_drops), money.floatToAmount(this.state.qty_drops)));

        var total_tax = money.floatToAmount(0);
        total_tax = money.add(total_tax, money.mul(total_fixed_rate, money.floatToAmount(this.state.tax_fixed / 100)));
        total_tax = money.add(total_tax, money.mul(total_variable_rate, money.floatToAmount(this.state.tax_miles / 100)));
        total_tax = money.add(total_tax, money.mul(total_variable_fsc, money.floatToAmount(this.state.tax_fsc / 100)));
        total_tax = money.add(total_tax, money.mul(total_fixed_fsc, money.floatToAmount(this.state.tax_fixed_fsc / 100)));
        total_tax = money.add(total_tax, money.mul(total_other, money.floatToAmount(this.state.tax_other / 100)));
        total_tax = money.add(total_tax, money.mul(total_drops, money.floatToAmount(this.state.tax_drops / 100)));
        total_tax = money.add(total_tax, money.mul(total_wait, money.floatToAmount(this.state.tax_wait / 100)));
        total_tax = money.add(total_tax, money.mul(total_hourly, money.floatToAmount(this.state.tax_hr / 100)));
        total_tax = money.add(total_tax, money.floatToAmount(this.state.reverse_billing_tax_adj));

        var total_subtotal = money.add(total_fixed_rate, total_variable_rate);
        total_subtotal = money.add(total_subtotal, total_variable_fsc);
        total_subtotal = money.add(total_subtotal, total_fixed_fsc);
        total_subtotal = money.add(total_subtotal, total_other);
        total_subtotal = money.add(total_subtotal, total_drops);
        total_subtotal = money.add(total_subtotal, total_wait);
        total_subtotal = money.add(total_subtotal, total_hourly);
        total_subtotal = money.add(total_subtotal, money.floatToAmount(this.state.rate_tolls_billable));
        total_subtotal = money.add(total_subtotal, money.floatToAmount(this.state.reverse_billing_adj));
        var total_total = money.add(total_subtotal, total_tax);

        var total_fuel = money.mul(money.floatToAmount(this.state.price_per_litre / 0.26417205), money.div(money.floatToAmount(this.state.qty_miles), money.floatToAmount(this.state.mpg)));

        var total_margin = money.subtract(total_subtotal, money.floatToAmount(this.state.total_labour));
        total_margin = money.subtract(total_margin, money.floatToAmount(this.state.rate_tolls));
        total_margin = money.subtract(total_margin, total_fuel);
        total_margin = money.subtract(total_margin, money.floatToAmount(this.state.total_vehicle_cost));
        total_margin = money.subtract(total_margin, money.floatToAmount(this.state.total_admin_cost));

        var selectTargetHeight = 34;
        var selectStyles = {
            control: (base) => ({
                ...base,
                minHeight: 'initial',
            }),
            valueContainer: (base) => ({
                ...base,
                height: `${selectTargetHeight - 1 - 1}px`,
                padding: '0 8px',
                fontSize: '13px',
            }),
            clearIndicator: (base) => ({
                ...base,
                padding: `${(selectTargetHeight - 20 - 1 - 1) / 2}px`,
            }),
            dropdownIndicator: (base) => ({
                ...base,
                padding: `${(selectTargetHeight - 20 - 1 - 1) / 2}px`,
            }),
            groupHeading: (base) => ({
                ...base,
                flex: '1 1',
                color: 'white',
                margin: 0,
            }),
        };
        var selectStylesEquip = {
            control: (base) => ({
                ...base,
                backgroundColor: `#f8f9fa`,
                borderColor: '#f8f9fa',
                minHeight: 'initial',
                minWidth: "110px"
            }),
            valueContainer: (base) => ({
                ...base,
                height: `${selectTargetHeight - 1 - 1}px`,
                padding: '0 8px',
                fontSize: '13px',
            }),
            clearIndicator: (base) => ({
                ...base,
                padding: `${(selectTargetHeight - 20 - 1 - 1) / 2}px`,
            }),
            dropdownIndicator: (base) => ({
                display: `none !important`,
            }),
            indicatorSeparator: (base) => ({
                display: `none !important`,
            }),
            groupHeading: (base) => ({
                ...base,
                flex: '1 1',
                color: 'white',
                margin: 0,
            }),
            menu: (base) => ({
                ...base,
                width: '300px',
            }),
        };
        var MenuListEquipment = (props) => {
            return (
                <components.MenuList {...props}>
                    <div className="bg-info text-light rounded ml-1 mr-1 p-1 pl-2 small" style={{margin: "0px", cursor: "pointer"}} onClick={() => {
                        this.setState({
                            trigger_open_equipment: moment().unix(),
                            equipment_search_term: props.selectProps.inputValue,
                            new_equip_segment_num: props.selectProps.new_equip_segment_num
                        })
                    }}>
                        Add New
                    </div>
                    {props.children}
                </components.MenuList>
            )
        }
        var MenuListVehicle = (props) => {
            return (
                <components.MenuList {...props}>
                    <div className="bg-info text-light rounded ml-1 mr-1 p-1 pl-2 small" style={{margin: "0px", cursor: "pointer"}} onClick={() => {
                        this.setState({trigger_open_vehicle: moment().unix()})
                    }}>
                        Add New Vehicle
                    </div>
                    {props.children}
                </components.MenuList>
            )
        }

        const filterOptions = (candidate, search_term) => {
            if (search_term) {
                return candidate.data.label.includes(search_term)
            }
            return true
        }

        return (
            <div>
                <div className={(this.state.status === null ? "d-none" : "text-center mborder-0")}
                     style={{top: 0, position: "absolute", paddingTop: "7px", left: "50%", marginLeft: "-40px"}}>
                    <span className={(this.state.status != 0 ? "d-none" : "badge bg-light text-dark")}>DRAFT</span>
                    <span className={(this.state.status != 1 ? "d-none" : "badge bg-info")}>SCHEDULED</span>
                    <span className={(this.state.status != 2 ? "d-none" : "badge bg-warning")}>STARTED</span>
                    <span className={(this.state.status != 3 ? "d-none" : "badge bg-success")}>COMPLETED</span>
                    <span className={(this.state.status != 4 ? "d-none" : "")}>
                        <span className={(this.state.status != 4 ? "d-none" : "badge bg-dark mr-2")} style={{marginLeft:"-20px"}}>BILLED</span>
                        <Link to={'../invoices/' + this.state.invoice_id}>Invoice #{this.state.invoice_id}</Link>
                    </span>
                    <span className={(this.state.status != 5 ? "d-none" : "")} style={{marginLeft:"-40px"}}>
                        <span className={(this.state.status != 5 ? "d-none" : "badge bg-pink mr-2")}>BILLED & SENT</span>
                        <Link to={'../invoices/' + this.state.invoice_id}>Invoice #{this.state.invoice_id}</Link>
                    </span>
                </div>
                <div className={(this.state.load_id == false && this.state.loading == false ? "alert alert-info m-1" : "d-none")}>
                    <i className="fas fa-info-circle fa-lg mr-2"></i> Select a segment start time to save this as a new load. Otherwise changes will not be saved.
                </div>
                <ul className="nav nav-tabs nav-justified">
                    <li className="nav-item">
                        <span style={{cursor: "pointer"}} className={(this.state.page == 'details' ? "nav-link active border-0" : "nav-link border-bottom-0")}
                              onClick={() => this.updateValue(false, 'page', 'details')}>
                            DETAILS
                            <div className={(this.state.errors.length > 0 ? "badge rounded-pill bg-danger ml-1" : "d-none")}>
                                <i className="fas fa-exclamation-triangle fa-lg mr-1"></i>x{this.state.errors.length}
                            </div>
                            <div className={(this.state.warnings.length > 0 ? "badge rounded-pill bg-warning ml-1" : "d-none")}>
                                <i className="fas fa-exclamation-circle fa-lg mr-1"></i>x{this.state.warnings.length}
                            </div>
                        </span>
                    </li>
                    <li className="nav-item">
                        <span style={{cursor: "pointer"}} className={(this.state.page == 'billing' ? "nav-link active border-0" : "nav-link border-bottom-0")}
                              onClick={() => this.updateValue(false, 'page', 'billing')}>BILLING <small>(${money.format('JPY', total_total)})</small></span>
                    </li>
                    <li className="nav-item">
                        <span style={{cursor: "pointer"}} className={(this.state.page == 'margin' ? "nav-link active border-0" : "nav-link border-bottom-0")}
                              onClick={() => this.updateValue(false, 'page', 'margin')}>MARGIN <small>(${money.format('JPY', total_margin)})</small></span>
                    </li>
                </ul>
                <Card className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                    <CardBody>
                        <form className={(this.state.page == "details" ? "form-horizontal" : "d-none")}>
                            <div className="row">
                                <Col>
                                    <FormGroup row>
                                        <Col xl="2" lg="4" sm="3" className="small text-uppercase pt-2">Tender Date:</Col>
                                        <Col xl="10" lg="8" sm="9">
                                            <SingleDatePicker
                                                SingleDatePicker={(this.state.tender_date == null ? moment() : this.state.tender_date)}
                                                onEvent={this.updateTenderDate}
                                                disabled={this.state.status >= 4}
                                                singleDatePicker={true}
                                                startDate={(this.state.tender_date == null ? moment() : this.state.tender_date)}
                                                endDate={(this.state.tender_date == null ? moment() : this.state.tender_date)}
                                            >
                                                <div className="btn btn-light btn-sm">
                                                    {(this.state.tender_date == null ? '(select)' : this.state.tender_date.format('MMM. D, YYYY'))}
                                                </div>
                                            </SingleDatePicker>
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup row>
                                        <Col xl="2" lg="3" md="4" sm="6" className="small text-uppercase pt-2">Customer:</Col>
                                        <Col xl="10" lg="9" md="8" sm="6">
                                            <UncontrolledButtonDropdown>
                                                <DropdownToggle disabled={this.state.status >= 4} size="sm" color="secondary" caret style={{fontSize: '13px', width: 'auto'}}>
                                                    {(this.state.customer_id == null ? "(select)" : this.state.customers[this.state.customer_id].name)}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem key="null" onClick={() => this.changeCustomer(null)}>(select)</DropdownItem>
                                                    <DropdownItem divider/>
                                                    {Object.keys(this.state.customers_sort).map((key, i) => {
                                                        return (<DropdownItem key={key}
                                                                              onClick={() => this.changeCustomer(this.state.customers_sort[key].customer_id)}>{this.state.customers_sort[key].name}</DropdownItem>)
                                                    })}
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </div>
                            <div className="row">
                                <Col>
                                    <FormGroup row>
                                        <Col xl="2" lg="4" sm="3" className="small text-uppercase pt-2">BOL #:</Col>
                                        <Col xl="10" lg="8" sm="9">
                                            <Input type="text" name="bol" value={this.state.bol} disabled={this.state.status >= 4} onChange={this.updateValue}/>
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup row>
                                        <Col xl="2" lg="3" md="4" sm="6" className="small text-uppercase pt-2">Cust. Load #:</Col>
                                        <Col xl="10" lg="9" md="8" sm="6">
                                            <Input type="text" name="cust_load_num" value={this.state.cust_load_num} disabled={this.state.status >= 4} onChange={this.updateValue}/>
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </div>

                            <div className="row">
                                <Col>
                                    <FormGroup row>
                                        <Col xl="2" lg="4" sm="3"
                                             className="small text-uppercase pt-2">Description:</Col>
                                        <Col xl="10" lg="8" sm="9">
                                            <Input type="textarea" name="description" style={{height: '60px'}}
                                                   disabled={this.state.status >= 4}
                                                   onChange={(event) => this.updateField("description", event)}
                                                   value={this.state.description}></Input>
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup row>
                                        <Col xl="2" lg="3" md="4" sm="6"
                                             className="small text-uppercase pt-2">Attachment(s):</Col>
                                        <Col xl="10" lg="9" md="8" sm="6">
                                            <Row>
                                                <Col className={(this.state.attachments.length == 0 ? "d-none" : "")}>
                                                    {this.state.attachments.map(function (attachment, attachment_num) {
                                                        return (
                                                            <div className="clearfix border-bottom border-light">
                                                                <span className="btn btn-sm btn-link p-0" onClick={(event) => this.getAttachment(attachment.name, attachment.hash)}>
                                                                    {attachment.name}
                                                                </span>
                                                                <span className="btn btn-xs btn-light float-left" onClick={(event) => this.removeAttachment(attachment.name, attachment.attachment_id)} title="Remove attachment">
                                                                    <i className="fas fa-trash-alt"></i>
                                                                </span>
                                                            </div>
                                                        )
                                                    }, this)}
                                                </Col>
                                                <Col>
                                                    <AddAttachment {...this.state} attachmentCallback={this.attachmentCallback}/>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </div>
                            <FormGroup row>
                                <Col xl="1" lg="2" sm="3" className="small text-uppercase pt-2">Driver / Carrier Pay:</Col>
                                <Col xl="11" lg="10" sm="9">
                                    <Row className="no-gutters">
                                        {Object.keys(this.state.labour_rates).map((driver_id, i) => {
                                            return (
                                                <>
                                                    <Col className="bg-light pb-1 pl-2 pr-2 mr-1 rounded" style={{minWidth: "350px"}}>
                                                        <small>{this.state.drivers[driver_id].first_name} {this.state.drivers[driver_id].last_name}</small>
                                                        <Row>
                                                            <Col className="pr-2">
                                                                <InputGroup size="sm">
                                                                    <div className="input-group-append"><span className="input-group-text pl-2 pr-2 small">$</span></div>
                                                                    <Input type="text" className="text-right" name="rate_miles" disabled={this.state.status >= 4}
                                                                           value={this.state.labour_rates[driver_id].rate_miles}
                                                                           onChange={(e) => this.updatePayment(driver_id, 'rate_miles', e)}/>
                                                                    <div className="input-group-append"><span className="input-group-text p-1 small">/mi</span></div>
                                                                </InputGroup>
                                                            </Col>
                                                            <Col className="p-0">
                                                                <InputGroup size="sm">
                                                                    <div className="input-group-append"><span className="input-group-text pl-2 pr-2 small">$</span></div>
                                                                    <Input type="text" className="text-right" name="rate_hr" disabled={this.state.status >= 4}
                                                                           value={this.state.labour_rates[driver_id].rate_hr}
                                                                           onChange={(e) => this.updatePayment(driver_id, 'rate_hr', e)}/>
                                                                    <div className="input-group-append"><span className="input-group-text p-1 small">/hr</span></div>
                                                                </InputGroup>
                                                            </Col>
                                                            <Col className="pl-2">
                                                                <InputGroup size="sm">
                                                                    <div className="input-group-append"><span className="input-group-text pl-2 pr-2 small">$</span></div>
                                                                    <Input type="text" className="text-right" name="rate_drops" disabled={this.state.status >= 4}
                                                                           value={this.state.labour_rates[driver_id].rate_drops}
                                                                           onChange={(e) => this.updatePayment(driver_id, 'rate_drops', e)}/>
                                                                    <div className="input-group-append"><span className="input-group-text p-1 small">/drop</span></div>
                                                                </InputGroup>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <UncontrolledTooltip
                                                        placement="top"
                                                        target={"driver" + driver_id}
                                                    >
                                                        {this.state.drivers[driver_id].first_name} {this.state.drivers[driver_id].last_name}
                                                    </UncontrolledTooltip>
                                                </>
                                            )
                                        }, this)}
                                    </Row>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col xl="1" lg="2" sm="3" className="small text-uppercase pt-2">Vehicle:</Col>
                                <Col xl="11" lg="10" sm="9">
                                    <table className="table table-sm">
                                        <tbody>
                                        <tr>
                                            <td>
                                                <UncontrolledButtonDropdown>
                                                    <DropdownToggle disabled={this.state.status >= 4} color="secondary" caret style={{fontSize: '13px', width: 'auto'}}>
                                                        {(this.state.vehicle_type_id == null ? "(select)" : this.state.equipment_types[this.state.vehicle_type_id].name)}
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem onClick={() => this.changeVehicleType(null)}>(select)</DropdownItem>
                                                        <DropdownItem divider/>
                                                        {Object.keys(this.state.vehicle_types).map((key, i) => {
                                                                if (typeof this.state.vehicle_types != "undefined" && typeof this.state.vehicle_types[key] != "undefined" && this.state.vehicle_types[key].is_vehicle == 1) {
                                                                    return (<DropdownItem key={key}
                                                                                          onClick={() => this.changeVehicleType(key)}>{this.state.vehicle_types[key].name}</DropdownItem>)
                                                                }
                                                            }
                                                        )}
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                                <i className="fas fa-angle-right ml-2 mr-2"></i>
                                                <UncontrolledButtonDropdown>
                                                    <DropdownToggle disabled={this.state.status >= 4} color="secondary" caret style={{fontSize: '13px', width: 'auto'}}>
                                                        {(this.state.vehicle_type_sub_id == null ? "(select)" : this.state.equipment_types_sub[this.state.vehicle_type_sub_id].name)}
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem onClick={() => this.changeVehicleTypeSub(null)}>(select)</DropdownItem>
                                                        <DropdownItem divider/>
                                                        {Object.keys(this.state.vehicle_types_sub).map((key, i) => {
                                                                if (typeof this.state.vehicle_types_sub != "undefined" && typeof this.state.vehicle_types_sub[key] != "undefined" && this.state.vehicle_type_id == this.state.vehicle_types_sub[key].equipment_type_id) {
                                                                    return (
                                                                        <DropdownItem key={key}
                                                                                      onClick={() => this.changeVehicleTypeSub(key)}>{this.state.vehicle_types_sub[key].name}</DropdownItem>
                                                                    )
                                                                }
                                                            }
                                                        )}
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                            </td>
                                            <td>
                                                <div className={(this.state.loading_equipment ? "" : "d-none")}><i className="fa fa-spin fa-spinner"></i></div>
                                                <div className={(this.state.loading_equipment ? "d-none" : "")}>
                                                    <Select
                                                        value={{
                                                            value: this.state.vehicle_id,
                                                            label: (typeof this.state.equipment[this.state.vehicle_id] == "undefined" ? "(select)" : this.state.equipment[this.state.vehicle_id].name),
                                                            type: "",
                                                            status: 1,
                                                            dwell: ""
                                                        }}
                                                        options={this.state.vehicle_sort}
                                                        onChange={this.changeVehicle}
                                                        formatGroupLabel={this.formatGroupLabel}
                                                        closeMenuOnSelect={true}
                                                        styles={selectStyles}
                                                        components={{MenuList: MenuListVehicle}}
                                                        getOptionLabel={(option) => <span><span
                                                            className={(option.status >= 2 ? "text-decoration-line-through" : "")}>{`${option.label}`}</span> <small
                                                            className={(option.status == 2 ? "text-danger" : "d-none")}>OOS</small><small
                                                            className={(option.status == 3 ? "text-danger" : "d-none")}>Rented</small><small
                                                            className={(option.status >= 2 ? "d-none" : "text-muted")}>{`${option.dwell}`}</small></span>}
                                                        isClearable={true}
                                                        isDisabled={this.state.status >= 4}
                                                        filterOption={filterOptions}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </Col>
                            </FormGroup>
                            <FormGroup row className="pt-2 d-none">
                                <Col xl="1" lg="2" sm="3" className="small text-uppercase pt-2">Preferences:</Col>
                                <Col xl="11" lg="10" sm="9" className="pl-5">
                                    <Row>
                                        <div className="col">
                                            <Input type="checkbox" name="avoid_borderCrossing" value={this.state.avoid_borderCrossing * -1}
                                                   checked={(this.state.avoid_borderCrossing == 1)}
                                                   disabled={this.state.status >= 4}
                                                   onChange={this.updateValue}/>
                                            Avoid Border Crossing
                                        </div>
                                        <div className="col">
                                            <Input type="checkbox" name="avoid_ferry" value={this.state.avoid_ferry * -1}
                                                   checked={(this.state.avoid_ferry == 1)}
                                                   disabled={this.state.status >= 4}
                                                   onChange={this.updateValue}/>
                                            Avoid Ferries
                                        </div>
                                        <div className="col">
                                            <Input type="checkbox" name="avoid_minimizeUTurn" value={this.state.avoid_minimizeUTurn}
                                                   checked={(this.state.avoid_minimizeUTurn == 1)}
                                                   disabled={this.state.status >= 4}
                                                   onChange={this.updateValue}/>
                                            Avoid U-Turns
                                        </div>
                                    </Row>
                                </Col>
                            </FormGroup>
                            <FormGroup row className="pt-2">
                                <Col xl="1" lg="2" sm="3" className="small text-uppercase pt-2">Trips:</Col>
                                <Col xl="11" lg="10" sm="9">
                                    <table className={(this.state.loading_segments ? "table table-sm table-borderless whirl traditional" : "table table-sm")}>
                                        <thead>
                                        <tr className="thead-light">
                                            <th>Origin</th>
                                            <th>Destination</th>
                                            <th>Equipment</th>
                                            <th>Start</th>
                                            <th>Miles</th>
                                            <th>Driving (hrs)</th>
                                            <th>Wait (hrs)</th>
                                            <th>Arrival</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.segments.map(function (segment, segment_num) {
                                            var end = moment();
                                            if (segment.start != null && segment.duration_hrs !== "" && segment.wait_hrs !== "") {
                                                end = moment(segment.start).add(segment.duration_hrs + segment.wait_hrs, 'hours');
                                            }
                                            return (
                                                <>
                                                    <tr>
                                                        <td className="border-0 pborder-0 pt-2">
                                                            <div style={{position: "absolute", left: "-5px", marginTop: "5px"}}
                                                                 className="badge badge-secondary">{segment.segment_num + 1}</div>
                                                            <Button color="light" size="sm" disabled={this.state.status >= 4} onClick={() => {
                                                                this.location_search_toggle(segment_num, 'origin')
                                                            }}>{(segment.origin == null ? "(select)" : this.state.locations[segment.origin].name)}</Button></td>
                                                        <td className="border-0 pborder-0 pt-2"><Button color="light" size="sm" disabled={this.state.status >= 4} onClick={() => {
                                                            this.location_search_toggle(segment_num, 'destination')
                                                        }}>{(segment.destination == null ? "(select)" : this.state.locations[segment.destination].name)}</Button></td>
                                                        <td className="border-0 pborder-0 pt-2">
                                                            <div className={(this.state.loading_equipment ? "" : "d-none")}><i className="fa fa-spin fa-spinner"></i></div>
                                                            <div className={(this.state.loading_equipment ? "d-none" : "")}>
                                                                <Select
                                                                    value={{
                                                                        value: this.state.equipment_id,
                                                                        label: (typeof this.state.equipment[segment.equipment_id] == "undefined" ? "(select)" : this.state.equipment[segment.equipment_id].name),
                                                                        type: "",
                                                                        status: 1,
                                                                        dwell: ""
                                                                    }}
                                                                    options={this.state.equipment_sort}
                                                                    onChange={(event) => this.changeEquipment(segment_num, event)}
                                                                    formatGroupLabel={this.formatGroupLabel}
                                                                    closeMenuOnSelect={true}
                                                                    styles={selectStylesEquip}
                                                                    components={{MenuList: MenuListEquipment}}
                                                                    onClick={(event) => this.setState({new_equip_segment_num: segment.segment_num})}
                                                                    getOptionLabel={(option) => <span><span
                                                                        className={(option.status >= 2 ? "text-decoration-line-through" : "")}>{`${option.label}`}</span> <small
                                                                        className={(option.status == 2 ? "text-danger" : "d-none")}>OOS</small><small
                                                                        className={(option.status == 3 ? "text-danger" : "d-none")}>Rented</small><small
                                                                        className={(option.status >= 2 ? "d-none" : "text-muted")}>{`${option.dwell}`}</small><small
                                                                        className="float-right">{`${option.type}`}</small></span>}
                                                                    isClearable={true}
                                                                    isDisabled={this.state.status >= 4}
                                                                    filterOption={filterOptions}
                                                                    new_equip_segment_num={segment.segment_num}
                                                                />
                                                            </div>
                                                        </td>
                                                        <td className="border-0 pborder-0 pt-2">
                                                            <SingleDatePicker
                                                                timePicker={true}
                                                                onEvent={this.startSaved}
                                                                autoUpdateInput={true}
                                                                singleDatePicker={true}
                                                                timePickerIncrement={5}
                                                                timePicker24Hour={true}
                                                                selected={(segment.start == null ? moment() : moment(segment.start))}
                                                                date={(segment.start == null ? moment() : moment(segment.start))}
                                                                startDate={(segment.start == null ? moment() : moment(segment.start))}
                                                                endDate={(segment.start == null ? moment() : moment(segment.start))}
                                                                drops='up'
                                                                disabled={this.state.status >= 4}
                                                            >
                                                                <div disabled={this.state.status >= 4} className="btn btn-light btn-sm" id={segment_num}>
                                                                    {(segment.start == null ? '(select)' : moment(segment.start).format('MMM. D/YY HH:mm'))}
                                                                </div>
                                                            </SingleDatePicker>
                                                        </td>
                                                        <td className="border-0 pborder-0 pt-2">
                                                            <div className={(segment.origin != null && segment.destination != null && segment.start != null ? "" : "d-none")}>
                                                                <input type="number" className="form-control form-control-sm" value={segment.distance_miles}
                                                                       style={{minWidth: "65px"}}
                                                                       disabled={this.state.status >= 4}
                                                                       onChange={(event) => this.updateFieldSegment(segment_num, "distance_miles", event)}/>
                                                                <div className="small text-muted">Calc: <i
                                                                    className={segment.distance_miles_computed == null ? "fas fa-sync fa-pulse" : "d-none"}></i>{segment.distance_miles_computed} mi
                                                                </div>
                                                            </div>
                                                            <div className={(segment.origin != null && segment.destination != null && segment.start != null ? "d-none" : "")}>
                                                                ---
                                                            </div>
                                                        </td>
                                                        <td className="border-0 pborder-0 pt-2">
                                                            <div className={(segment.origin != null && segment.destination != null && segment.start != null ? "" : "d-none")}>
                                                                <input type="number" className="form-control form-control-sm" value={segment.duration_hrs}
                                                                       style={{minWidth: "65px"}}
                                                                       disabled={this.state.status >= 4}
                                                                       onChange={(event) => this.updateFieldSegment(segment_num, "duration_hrs", event)}/>
                                                                <div className="small text-muted">Calc: <i
                                                                    className={segment.distance_miles_computed == null ? "fas fa-sync fa-pulse" : "d-none"}></i>{segment.duration_hrs_computed} hrs
                                                                </div>
                                                            </div>
                                                            <div className={(segment.origin != null && segment.destination != null && segment.start != null ? "d-none" : "")}>
                                                                ---
                                                            </div>
                                                        </td>
                                                        <td className="border-0 pborder-0 pt-2">
                                                            <input type="number" className="form-control form-control-sm" value={segment.wait_hrs} disabled={this.state.status >= 4}
                                                                   style={{minWidth: "65px"}}
                                                                   onChange={(event) => this.updateFieldSegment(segment_num, "wait_hrs", event)}/>
                                                        </td>
                                                        <td className="border-0 pborder-0 pt-2">
                                                            <SingleDatePicker
                                                                timePicker={true}
                                                                onEvent={this.endSaved}
                                                                autoUpdateInput={true}
                                                                singleDatePicker={true}
                                                                timePickerIncrement={5}
                                                                timePicker24Hour={true}
                                                                selected={end}
                                                                date={end}
                                                                startDate={end}
                                                                endDate={end}
                                                                drops='up'
                                                                disabled={this.state.status >= 4}
                                                            >
                                                                <div disabled={this.state.status >= 4} className="btn btn-light btn-sm" id={segment_num}>
                                                                    {(segment.start == null ? '(select)' : end.format('HH:mm'))}
                                                                </div>
                                                            </SingleDatePicker>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="8" className="pt-0 pborder-0 border-0">
                                                            <Row className="bg-light m-0 pt-1 pborder-0 pl-0 pr-0">
                                                                <Col className="pl-1">
                                                                    <Select
                                                                        value={{
                                                                            value: segment.driver_id,
                                                                            label: (segment.driver_id == null ? "(select)" : this.state.drivers[segment.driver_id].first_name + " " + this.state.drivers[segment.driver_id].last_name),
                                                                        }}
                                                                        options={this.state.drivers_sort}
                                                                        onChange={(event) => this.changeDriver(segment_num, event)}
                                                                        closeMenuOnSelect={true}
                                                                        styles={selectStyles}
                                                                        isClearable={true}
                                                                        isDisabled={this.state.status >= 4}
                                                                    />
                                                                </Col>
                                                                <span
                                                                    className={(typeof this.state.equipment[segment.equipment_id] != "undefined" && this.state.equipment[segment.equipment_id].equipment_type_id == 5 ? "col-sm-2 col-lg-2" : "d-none")}>
                                                                        <Input type="text" placeholder="Container #" size="sm" disabled={this.state.status >= 4}
                                                                               name="container_num" value={segment.container_num}
                                                                               onChange={(event) => this.updateFieldSegment(segment_num, "container_num", event)}
                                                                        />
                                                                </span>
                                                                <Col>
                                                                    <input type="text" placeholder="Trip Description" className="form-control form-control-sm"
                                                                           value={segment.description}
                                                                           disabled={this.state.status >= 4}
                                                                           onChange={(event) => this.updateFieldSegment(segment_num, "description", event)}/>
                                                                </Col>
                                                                <Col sm={3} md={3} lg={2} className="valign-bottom pt-1">
                                                                    <label className={(segment.billable == 1 ? "" : "text-danger")}>
                                                                        <Input type="checkbox" name="avoid_borderCrossing" value="1"
                                                                               checked={(segment.billable == 1)}
                                                                               disabled={this.state.status >= 4}
                                                                               onChange={(event) => this.updateFieldSegment(segment_num, "billable", event)}/>
                                                                        Billable Trip <i className={(segment.billable == 1 ? "d-none" : "fas fa-exclamation-circle")}></i>
                                                                    </label>
                                                                </Col>
                                                                <Col sm={1} md={1} lg={1} className="text-right">
                                                                    <div className={(this.state.status >= 4 ? "d-none" : "btn btn-danger btn-xs")}
                                                                         onClick={() => this.deleteSegment(segment.segment_id)}><i
                                                                        className="far fa-trash-alt"></i>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={8} className="small border-0">
                                                            <div className={(!segment.show_dimensions ? "text-center" : "d-none")}>
                                                            <span className="btn btn-xs btn-link text-decoration-none"
                                                                  onClick={(event) => this.updateFieldSegment(segment_num, "show_dimensions", true)}><i
                                                                className="fas fa-sort-down"></i> show dimensions</span>
                                                            </div>
                                                            <Row className={(segment.show_dimensions ? "mb-1" : "d-none")}>
                                                                <Col sm="4" lg="3" xl="2">
                                                                    Equipment Weight:
                                                                    <InputGroup size="sm">
                                                                        <Input size="xs" type="text" name="weight_equip" className="text-right" disabled={this.state.status >= 4}
                                                                               value={segment.weight_equip}
                                                                               onChange={(event) => this.updateFieldSegment(segment_num, "weight_equip", event)}/>
                                                                        <InputGroupAddon addonType="append">kg.</InputGroupAddon>
                                                                    </InputGroup>
                                                                </Col>
                                                                <Col sm="4" lg="3" xl="2">
                                                                    Load Weight:
                                                                    <InputGroup size="sm">
                                                                        <Input type="text" name="weight_load" className="text-right" disabled={this.state.status >= 4}
                                                                               value={segment.weight_load}
                                                                               onChange={(event) => this.updateFieldSegment(segment_num, "weight_load", event)}/>
                                                                        <InputGroupAddon addonType="append">kg.</InputGroupAddon>
                                                                    </InputGroup>
                                                                </Col>
                                                                <Col sm="4" lg="3" xl="2">
                                                                    Max Height:
                                                                    <InputGroup size="sm">
                                                                        <Input type="text" name="height" className="text-right" disabled={this.state.status >= 4}
                                                                               value={segment.height}
                                                                               onChange={(event) => this.updateFieldSegment(segment_num, "height", event)}/>
                                                                        <InputGroupAddon addonType="append">ft.</InputGroupAddon>
                                                                    </InputGroup>
                                                                </Col>
                                                                <Col sm="4" lg="3" xl="2">
                                                                    Max Width:
                                                                    <InputGroup size="sm">
                                                                        <Input type="text" name="width" className="text-right" disabled={this.state.status >= 4}
                                                                               value={segment.width}
                                                                               onChange={(event) => this.updateFieldSegment(segment_num, "width", event)}/>
                                                                        <InputGroupAddon addonType="append">ft.</InputGroupAddon>
                                                                    </InputGroup>
                                                                </Col>
                                                                <Col sm="4" lg="3" xl="2">
                                                                    Total Length:
                                                                    <InputGroup size="sm">
                                                                        <Input type="text" name="vl" className="text-right" disabled={this.state.status >= 4} value={segment.vl}
                                                                               onChange={(event) => this.updateFieldSegment(segment_num, "vl", event)}/>
                                                                        <InputGroupAddon addonType="append">ft.</InputGroupAddon>
                                                                    </InputGroup>
                                                                </Col>
                                                                <Col sm="4" lg="3" xl="2">
                                                                    Total Axles:
                                                                    <Input type="text" name="axles" className="text-right" disabled={this.state.status >= 4} value={segment.axles}
                                                                           onChange={(event) => this.updateFieldSegment(segment_num, "axles", event)}/>
                                                                </Col>
                                                                <Col sm="4" lg="3" xl="2">
                                                                    Trailers:
                                                                    <Input type="text" name="vt" className="text-right" disabled={this.state.status >= 4} value={segment.vt}
                                                                           onChange={(event) => this.updateFieldSegment(segment_num, "vt", event)}/>
                                                                </Col>
                                                                <Col sm="4" lg="3" xl="2">
                                                                    Max Gradient:
                                                                    <InputGroup size="sm">
                                                                        <Input type="text" name="vmg" className="text-right" disabled={this.state.status >= 4} value={segment.vmg}
                                                                               onChange={(event) => this.updateFieldSegment(segment_num, "vmg", event)}/>
                                                                        <InputGroupAddon addonType="append">deg.</InputGroupAddon>
                                                                    </InputGroup>
                                                                </Col>
                                                                <Col sm="4" lg="3" xl="2">
                                                                    Min. Turn Radius:
                                                                    <InputGroup size="sm">
                                                                        <Input type="text" name="vmtr" className="text-right" disabled={this.state.status >= 4} value={segment.vmtr}
                                                                               onChange={(event) => this.updateFieldSegment(segment_num, "vmtr", event)}/>
                                                                        <InputGroupAddon addonType="append">ft.</InputGroupAddon>
                                                                    </InputGroup>
                                                                </Col>
                                                            </Row>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={8} className="border-0 pt-2 pb-2">
                                                            <hr className="m-0 pt-1 bg-dark border-0"/>
                                                        </td>
                                                    </tr>
                                                </>
                                            )
                                        }, this)}
                                        <tr>
                                            <td colSpan="8" className="border-0 border-top-0">
                                                <div className={(this.state.status >= 4 ? "d-none" : "btn btn-xs btn-info")} onClick={(event) => this.addSegment(false)}>+ Add
                                                    Trip
                                                </div>
                                                <div className={(this.state.status >= 4 ? "d-none" : "btn btn-xs btn-info ml-2")} onClick={(event) => this.addSegment(true)}>+ <i
                                                    className="fa-solid fa-rotate-left"></i> Return Trip
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </Col>
                            </FormGroup>
                            <FormGroup row className="pt-2">
                                <Col xl="1" lg="2" sm="3" className="small text-uppercase pt-2">Route:</Col>
                                <Col xl="11" lg="10" sm="9" style={{minHeight: "400px"}}>
                                    <MapLoad points={this.state.points} travel_line={this.state.linestring}
                                             reference_line={this.state.linestring}/>
                                </Col>
                            </FormGroup>
                            <FormGroup row className="pt-2">
                                <Col xl="1" lg="2" sm="3" className="small text-uppercase pt-2">Warnings:</Col>
                                <Col xl="11" lg="10" sm="9">
                                    <div className={(this.state.errors.length == 0 && this.state.warnings.length == 0 ? "alert alert-success" : "d-none")}>
                                        <i className="fas fa-check-circle fa-lg mr-2"></i> No problems detected
                                    </div>
                                    <div className={(this.state.errors.length > 0 ? "alert alert-danger" : "d-none")}>
                                        {this.state.errors.map(function (error, num) {
                                            return (<div>- {error.text}</div>)
                                        })}
                                    </div>
                                    <div className={(this.state.warnings.length > 0 ? "alert alert-warning" : "d-none")}>
                                        {this.state.warnings.map(function (error, num) {
                                            return (<div>- {error.text}</div>)
                                        })}
                                    </div>
                                </Col>
                            </FormGroup>
                        </form>
                        <form className={(this.state.page == "billing" ? "form-horizontal pb-3" : "d-none")}>
                            <table class="table table-sm">
                                <thead>
                                <tr className="thead-light">
                                    <th>Rate Type</th>
                                    <th className="text-right pr-3">Rate</th>
                                    <th></th>
                                    <th className="text-right pr-3">Qty</th>
                                    <th></th>
                                    <th className="text-right pr-3">Tax</th>
                                    <th></th>
                                    <th className="text-right">Subtotal</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        Fixed Rate
                                    </td>
                                    <td>
                                        <InputGroup size="sm">
                                            <InputGroupAddon addonType="append">$</InputGroupAddon>
                                            <Input type="text" className="text-right" name="rate_fixed" disabled={this.state.status >= 4} value={this.state.rate_fixed}
                                                   onChange={this.updateValue}/>
                                        </InputGroup>
                                    </td>
                                    <td>x</td>
                                    <td>
                                        <Input type="text" className="text-right" name="qty_fixed" disabled={this.state.status >= 4} value={this.state.qty_fixed}
                                               onChange={this.updateValue}/>
                                    </td>
                                    <td>x</td>
                                    <td>
                                        <InputGroup size="sm">
                                            <Input type="text" className="text-right" name="tax_fixed" disabled={this.state.status >= 4} value={this.state.tax_fixed}
                                                   onChange={this.updateValue}/>
                                            <InputGroupAddon addonType="append">%</InputGroupAddon>
                                        </InputGroup>
                                    </td>
                                    <td>=</td>
                                    <td className="text-right">
                                        ${money.format('USD', total_fixed_rate)}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Rate per Mile
                                    </td>
                                    <td>
                                        <InputGroup size="sm">
                                            <InputGroupAddon addonType="append">$</InputGroupAddon>
                                            <Input type="text" name="rate_miles" className="text-right" disabled={this.state.status >= 4} value={this.state.rate_miles}
                                                   onChange={this.updateValue}/>
                                        </InputGroup>
                                    </td>
                                    <td>x</td>
                                    <td className="text-right pr-3">
                                        {money.format('USD', money.floatToAmount(this.state.qty_miles))} mi
                                    </td>
                                    <td>x</td>
                                    <td>
                                        <InputGroup size="sm">
                                            <Input type="text" className="text-right" name="tax_miles" disabled={this.state.status >= 4} value={this.state.tax_miles}
                                                   onChange={this.updateValue}/>
                                            <InputGroupAddon addonType="append">%</InputGroupAddon>
                                        </InputGroup>
                                    </td>
                                    <td>=</td>
                                    <td className="text-right">
                                        ${money.format('USD', total_variable_rate)}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Driving Rate/Hour
                                    </td>
                                    <td>
                                        <InputGroup size="sm">
                                            <InputGroupAddon addonType="append">$</InputGroupAddon>
                                            <Input type="text" name="rate_hr" className="text-right" disabled={this.state.status >= 4} value={this.state.rate_hr}
                                                   onChange={this.updateValue}/>
                                            <InputGroupAddon addonType="append">/hr</InputGroupAddon>
                                        </InputGroup>
                                    </td>
                                    <td>x</td>
                                    <td className="text-right pr-3">
                                        {this.state.qty_hrs_total} hrs
                                    </td>
                                    <td>x</td>
                                    <td>
                                        <InputGroup size="sm">
                                            <Input type="text" className="text-right" name="tax_hr" disabled={this.state.status >= 4} value={this.state.tax_hr}
                                                   onChange={this.updateValue}/>
                                            <InputGroupAddon addonType="append">%</InputGroupAddon>
                                        </InputGroup>
                                    </td>
                                    <td>=</td>
                                    <td className="text-right">
                                        ${money.format('USD', total_hourly)}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Wait Rate/Hour
                                    </td>
                                    <td>
                                        <InputGroup size="sm">
                                            <InputGroupAddon addonType="append">$</InputGroupAddon>
                                            <Input type="text" name="rate_wait" className="text-right" disabled={this.state.status >= 4} value={this.state.rate_wait}
                                                   onChange={this.updateValue}/>
                                            <InputGroupAddon addonType="append">/hr</InputGroupAddon>
                                        </InputGroup>
                                    </td>
                                    <td>x</td>
                                    <td className="text-right pr-3">
                                        {this.state.qty_hrs_wait_billable} hrs
                                    </td>
                                    <td>x</td>
                                    <td>
                                        <InputGroup size="sm">
                                            <Input type="text" className="text-right" name="tax_wait" disabled={this.state.status >= 4} value={this.state.tax_wait}
                                                   onChange={this.updateValue}/>
                                            <InputGroupAddon addonType="append">%</InputGroupAddon>
                                        </InputGroup>
                                    </td>
                                    <td>=</td>
                                    <td className="text-right">
                                        ${money.format('USD', total_wait)}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Variable Fuel Surcharge (FSC)
                                    </td>
                                    <td>
                                        <InputGroup size="sm">
                                            <Input type="text" name="rate_fsc" className="text-right" disabled={this.state.status >= 4} value={this.state.rate_fsc}
                                                   onChange={this.updateValue}/>
                                            <InputGroupAddon addonType="append">%</InputGroupAddon>
                                        </InputGroup>
                                    </td>
                                    <td>x</td>
                                    <td className="text-right pr-3">
                                        ${money.format('USD', total_base_charge)}
                                    </td>
                                    <td>x</td>
                                    <td>
                                        <InputGroup size="sm">
                                            <Input type="text" className="text-right" name="tax_fsc" disabled={this.state.status >= 4} value={this.state.tax_fsc}
                                                   onChange={this.updateValue}/>
                                            <InputGroupAddon addonType="append">%</InputGroupAddon>
                                        </InputGroup>
                                    </td>
                                    <td>=</td>
                                    <td className="text-right">
                                        ${money.format('USD', total_variable_fsc)}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Fixed Fuel Surcharge
                                    </td>
                                    <td>
                                        <InputGroup size="sm">
                                            <InputGroupAddon addonType="append">$</InputGroupAddon>
                                            <Input type="text" name="rate_fixed_fsc" className="text-right" disabled={this.state.status >= 4} value={this.state.rate_fixed_fsc}
                                                   onChange={this.updateValue}/>
                                        </InputGroup>
                                    </td>
                                    <td>x</td>
                                    <td>
                                        <Input type="text" name="qty_fsc" className="text-right" disabled={this.state.status >= 4} value={this.state.qty_fsc}
                                               onChange={this.updateValue}/>
                                    </td>
                                    <td>x</td>
                                    <td>
                                        <InputGroup size="sm">
                                            <Input type="text" className="text-right" name="tax_fixed_fsc" disabled={this.state.status >= 4} value={this.state.tax_fixed_fsc}
                                                   onChange={this.updateValue}/>
                                            <InputGroupAddon addonType="append">%</InputGroupAddon>
                                        </InputGroup>
                                    </td>
                                    <td>=</td>
                                    <td className="text-right">
                                        ${money.format('USD', total_fixed_fsc)}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Tolls
                                    </td>
                                    <td>
                                        <InputGroup size="sm">
                                            <InputGroupAddon addonType="append">$</InputGroupAddon>
                                            <Input type="text" name="rate_tolls_billable" className="text-right" disabled={this.state.status >= 4}
                                                   value={this.state.rate_tolls_billable}
                                                   onChange={this.updateValue}/>
                                        </InputGroup>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>=</td>
                                    <td className="text-right">
                                        ${money.format('USD', money.floatToAmount(this.state.rate_tolls_billable))}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Drop Fee
                                    </td>
                                    <td>
                                        <InputGroup size="sm">
                                            <InputGroupAddon addonType="append">$</InputGroupAddon>
                                            <Input type="text" name="rate_drops" className="text-right" disabled={this.state.status >= 4} value={this.state.rate_drops}
                                                   onChange={this.updateValue}/>
                                        </InputGroup>
                                    </td>
                                    <td>x</td>
                                    <td>
                                        <Input type="text" name="qty_drops" className="text-right" disabled={this.state.status >= 4} value={this.state.qty_drops}
                                               onChange={this.updateValue}/>
                                    </td>
                                    <td>x</td>
                                    <td>
                                        <InputGroup size="sm">
                                            <Input type="text" className="text-right" name="tax_drops" disabled={this.state.status >= 4} value={this.state.tax_drops}
                                                   onChange={this.updateValue}/>
                                            <InputGroupAddon addonType="append">%</InputGroupAddon>
                                        </InputGroup>
                                    </td>
                                    <td>=</td>
                                    <td className="text-right">
                                        ${money.format('USD', total_drops)}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Other
                                    </td>
                                    <td>
                                        <InputGroup size="sm">
                                            <InputGroupAddon addonType="append">$</InputGroupAddon>
                                            <Input type="text" name="rate_other" className="text-right" disabled={this.state.status >= 4} value={this.state.rate_other}
                                                   onChange={this.updateValue}/>
                                        </InputGroup>
                                    </td>
                                    <td>x</td>
                                    <td>
                                        <Input type="text" name="qty_other" className="text-right" disabled={this.state.status >= 4} value={this.state.qty_other}
                                               onChange={this.updateValue}/>
                                    </td>
                                    <td>x</td>
                                    <td>
                                        <InputGroup size="sm">
                                            <Input type="text" className="text-right" name="tax_other" disabled={this.state.status >= 4} value={this.state.tax_other}
                                                   onChange={this.updateValue}/>
                                            <InputGroupAddon addonType="append">%</InputGroupAddon>
                                        </InputGroup>
                                    </td>
                                    <td>=</td>
                                    <td className="text-right">
                                        ${money.format('USD', total_other)}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Reverse Billing Adjustment</td>
                                    <td className="text-right">${money.format('USD', money.floatToAmount(this.state.reverse_billing_adj))}</td>
                                    <td colspan="3">x</td>
                                    <td className="text-right">${money.format('USD', money.floatToAmount(this.state.reverse_billing_tax_adj))}</td>
                                    <td>=</td>
                                    <td className="text-right">${money.format('USD', money.floatToAmount(parseFloat(this.state.reverse_billing_tax_adj) + parseFloat(this.state.reverse_billing_adj)))}</td>
                                </tr>
                                </tbody>
                                <tfoot>
                                <tr>
                                    <th colSpan="7" className="text-right">
                                        Subtotal:
                                    </th>
                                    <th className="text-right">
                                        ${money.format('USD', total_subtotal)}
                                    </th>
                                </tr>
                                <tr>
                                    <th colSpan="7" className="text-right">
                                        HST:
                                    </th>
                                    <th className="text-right">
                                        ${money.format('USD', total_tax)}
                                    </th>
                                </tr>
                                <tr>
                                    <th colSpan="7" className="text-right">
                                        Total Charge:
                                    </th>
                                    <th className="text-right">
                                        ${money.format('USD', total_total)}
                                    </th>
                                </tr>
                                </tfoot>
                            </table>
                        </form>
                        <form className={(this.state.page == "margin" ? "form-horizontal pb-3" : "d-none")}>
                            <table className="table table-sm">
                                <thead>
                                <tr className="thead-light">
                                    <th>Type</th>
                                    <th></th>
                                    <th className="text-right pr-3">Cost</th>
                                    <th></th>
                                    <th className="text-right pr-3">Qty</th>
                                    <th></th>
                                    <th className="text-right">Subtotal</th>
                                    <th className="text-right">Total</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <th colSpan="7" className="text-success">Total Billed (pre-tax)</th>
                                    <th className="text-right text-success">${money.format('USD', total_subtotal)}</th>
                                </tr>
                                <tr>
                                    <th className="border-0"></th>
                                    <td className="border-0">Labour, per Mile</td>
                                    <td className="border-0 text-right">
                                        {Object.keys(this.state.labour_rates).map((driver_id, i) => {
                                            return (
                                                <>
                                                    <span id={"driver" + driver_id}
                                                          style={{borderBottom: "1px dashed #999"}}>${money.format('USD', money.floatToAmount(this.state.labour_rates[driver_id].rate_miles))}</span>
                                                    <span className={(i == Object.keys(this.state.labour_rates).length - 1 ? "d-none" : "")}> / </span>
                                                </>
                                            )
                                        }, this)}
                                    </td>
                                    <td className="border-0 text-center">x</td>
                                    <td className="border-0 text-right">{this.state.qty_miles}</td>
                                    <td className="border-0 text-center">=</td>
                                    <td className="border-0 text-right">${money.format('USD', money.floatToAmount(this.state.labour_miles))}</td>
                                    <td className="border-0"></td>
                                </tr>
                                <tr>
                                    <th className="border-0"></th>
                                    <td className="border-0">Labour, per Hour</td>
                                    <td className="border-0 text-right">
                                        {Object.keys(this.state.labour_rates).map((driver_id, i) => {
                                            return (<>
                                                <span id={"driver" + driver_id}
                                                      style={{borderBottom: "1px dashed #999"}}>${money.format('USD', money.floatToAmount(this.state.labour_rates[driver_id].rate_hr))}</span>
                                                <span className={(i == Object.keys(this.state.labour_rates).length - 1 ? "d-none" : "")}> / </span>
                                            </>)
                                        }, this)}
                                    </td>
                                    <td className="border-0 text-center">x</td>
                                    <td className="border-0 text-right">{money.format('USD', money.floatToAmount(parseFloat(this.state.qty_hrs_total) + parseFloat(this.state.qty_hrs_wait)))}</td>
                                    <td className="border-0 text-center">=</td>
                                    <td className="border-0 text-right">${money.format('USD', money.floatToAmount(this.state.labour_hr))}</td>
                                    <td className="border-0"></td>
                                </tr>
                                <tr>
                                    <th className="border-0"></th>
                                    <td className="border-0">Labour, Drop Fee</td>
                                    <td className="border-0 text-right">
                                        {Object.keys(this.state.labour_rates).map((driver_id, i) => {
                                            return (<>
                                                <span id={"driver" + driver_id}
                                                      style={{borderBottom: "1px dashed #999"}}>${money.format('USD', money.floatToAmount(this.state.labour_rates[driver_id].rate_drops))}</span>
                                                <span className={(i == Object.keys(this.state.labour_rates).length - 1 ? "d-none" : "")}> / </span>
                                            </>)
                                        }, this)}
                                    </td>
                                    <td className="border-0 text-center">x</td>
                                    <td className="border-0 text-right">{this.state.qty_drops}</td>
                                    <td className="border-0 text-center">=</td>
                                    <td className="border-0 text-right">${money.format('USD', money.floatToAmount(this.state.labour_drops))}</td>
                                    <td className="border-0"></td>
                                </tr>
                                <tr>
                                    <th colSpan="7" className="border-top-0">
                                        Less: Total Labour
                                    </th>
                                    <th className="border-top-0 text-right">
                                        (${money.format('USD', money.floatToAmount(this.state.total_labour))})
                                    </th>
                                </tr>
                                <tr>
                                    <th colSpan="7">
                                        Less: Tolls
                                    </th>
                                    <th className="text-right">
                                        (${money.format('USD', money.floatToAmount(this.state.rate_tolls))})
                                    </th>
                                </tr>
                                <tr>
                                    <th colspan="2">Less: Fuel</th>
                                    <td className="text-right">${money.format('USD', money.floatToAmount(this.state.price_per_litre))}/L</td>
                                    <td className="text-center">x</td>
                                    <td className="text-right">{this.state.qty_miles} mi. @ {this.state.mpg} mpg</td>
                                    <td className="text-center">=</td>
                                    <td></td>
                                    <th className="text-right">(${money.format('USD', money.floatToAmount(total_fuel))})</th>
                                </tr>
                                <tr>
                                    <th className="border-0"></th>
                                    <td className="border-0">Vehicle Cost, per KM</td>
                                    <td className="border-0 text-right">${money.format('USD', money.floatToAmount(this.state.vehicle_cost_km))}</td>
                                    <td className="border-0 text-center">x</td>
                                    <td className="border-0 text-right">{Math.round(this.state.qty_miles * 1.609344)}</td>
                                    <td className="border-0 text-center">=</td>
                                    <td className="border-0 text-right">${money.format('USD', money.floatToAmount(this.state.qty_miles * 1.609344 * this.state.vehicle_cost_km))}</td>
                                    <td className="border-0"></td>
                                </tr>
                                <tr>
                                    <th className="border-0"></th>
                                    <td className="border-0">Vehicle Cost, per Day</td>
                                    <td className="border-0 text-right">${money.format('USD', money.floatToAmount(this.state.vehicle_cost_day))}</td>
                                    <td className="border-0 text-center">x</td>
                                    <td className="border-0 text-right">{(parseFloat(this.state.qty_hrs_total) + parseFloat(this.state.qty_hrs_wait) > 12 ? 1 : .5)}</td>
                                    <td className="border-0 text-center">=</td>
                                    <td className="border-0 text-right">${money.format('USD', money.floatToAmount(this.state.vehicle_cost_day * (parseFloat(this.state.qty_hrs_total) + parseFloat(this.state.qty_hrs_wait) > 12 ? 1 : .5)))}</td>
                                    <td className="border-0"></td>
                                </tr>
                                <tr>
                                    <th colSpan="7" className="border-top-0">Less: Vehicle Cost</th>
                                    <td className="text-right border-top-0">
                                        <InputGroup size="sm">
                                            <InputGroupAddon addonType="append">$</InputGroupAddon>
                                            <Input type="text" name="total_vehicle_cost" className="text-right" disabled={this.state.status >= 4}
                                                   value={this.state.total_vehicle_cost}
                                                   onChange={this.updateValue}/>
                                        </InputGroup>
                                    </td>
                                </tr>
                                <tr>
                                    <th colSpan="7" className="border-top-0">Less: Admin Cost</th>
                                    <td className="text-right border-top-0">
                                        <InputGroup size="sm">
                                            <InputGroupAddon addonType="append">$</InputGroupAddon>
                                            <Input type="text" name="total_admin_cost" className="text-right" disabled={this.state.status >= 4}
                                                   value={this.state.total_admin_cost}
                                                   onChange={this.updateValue}/>
                                        </InputGroup>
                                    </td>
                                </tr>
                                </tbody>
                                <tfoot>
                                <tr>
                                    <th colSpan="7" className="text-right">
                                        TOTAL MARGIN:
                                    </th>
                                    <th className="text-right">
                                        <u>${money.format('USD', total_margin)}</u>
                                    </th>
                                </tr>
                                </tfoot>
                            </table>
                        </form>
                        <div className={(this.state.status >= 4 || this.state.load_id == false ? "d-none" : "row pb-2")}>
                            <Col lg="2" sm="3" className="small text-uppercase pt-2">Status:</Col>
                            <Col lg="10" sm="9" className="p-2 text-light bg-dark">
                                <div>
                                    <ButtonGroup>
                                        <Button color="light" onClick={() => this.changeStatus(0)}
                                                active={this.state.status === 0}>{this.state.status != 0 ? "Mark as " : ""}DRAFT</Button>
                                        <Button color="light" onClick={() => this.changeStatus(1)}
                                                active={this.state.status === 1}>{this.state.status != 1 ? "Mark as " : ""}SCHEDULED</Button>
                                        <Button color="light" onClick={() => this.changeStatus(2)}
                                                active={this.state.status === 2}>{this.state.status != 2 ? "Mark as " : ""}STARTED</Button>
                                        <Button color="light" onClick={() => this.changeStatus(3)}
                                                active={this.state.status === 3}>{this.state.status != 3 ? "Mark as " : ""}COMPLETED</Button>
                                    </ButtonGroup>
                                    <Button color="danger" size="sm" onClick={() => this.changeStatus(-1)} active={this.state.status === -1} className="float-right"><i
                                        className="far fa-trash-alt"></i> DELETE</Button>
                                </div>
                                <div className={(this.state.status != 4 ? "d-none" : "")}>
                                    Load has been <b>Billed</b>, see invoice # {this.state.invoice_id}
                                </div>
                            </Col>
                        </div>
                    </CardBody>
                </Card>
                <Modal isOpen={this.state.location_search_modal} fade={false} toggle={() => {
                    this.location_search_toggle(false, false)
                }} size="sm">
                    <ModalHeader>
                        <div className="h3 mborder-0">Search Saved Location</div>
                    </ModalHeader>
                    <ModalBody>
                        <input type="text" className="form-control form-control-lg mb-1" id="location_search" name="search_term" value={this.state.search_term}
                               placeholder="Search by Location Name or Address" onChange={this.updateValue}/>
                        <ul className="list-group" style={{maxHeight: 'calc(100vh - 120px)', overflowY: "auto"}}>
                            <AddLocation location_saved={this.location_saved} button={false} extraClass={"list-group-item bg-info btn btn-info"}/>
                            {this.state.locations_sort.map(function (item, key) {
                                if (this.state.search_term == "" || item.name.toLowerCase().includes(this.state.search_term.toLowerCase()) || item.address.toLowerCase().includes(this.state.search_term.toLowerCase()) || item.city.toLowerCase().includes(this.state.search_term.toLowerCase())) {
                                    return (
                                        <div key={key} className="list-group-item btn btn-light text-left" onClick={() => {
                                            this.updateLocation(this.state.temp_segment, this.state.temp_point, item.location_id)
                                        }}>
                                            {item.name}
                                            <div className="small">{item.address}, {item.city}</div>
                                        </div>
                                    )
                                }
                            }, this)}
                        </ul>
                    </ModalBody>
                </Modal>

                <AddEquipment hidden={true} customers={this.state.customers} customer_id={this.state.customer_id} equipment_types={this.state.equipment_types}
                              equipment_types_sub={this.state.equipment_types_sub} search_term={this.state.equipment_search_term}
                              goToEquipment={this.newEquipmentCallback} segment_num={this.state.new_equip_segment_num} triggerOpen={this.state.trigger_open_equipment}/>
                <AddVehicle hidden={true} customers={this.state.customers} customer_id={this.state.customer_id} vehicle_types={this.state.equipment_types}
                            vehicle_types_sub={this.state.equipment_types_sub}
                            goToVehicle={this.newVehicleCallback} triggerOpen={this.state.trigger_open_vehicle}/>
            </div>
        );
    }
}

export default LoadDisplay;
